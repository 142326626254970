import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import {
  TextField,
  Button,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Typography,
  Alert,
  Snackbar,
} from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import { useAuth } from '../../contexts/AuthContext'
import { personalDetailsRequired } from '../../utils/requiredFields'
import { LoadingButton } from '@mui/lab'

const Personal = ({ application }) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext()
  const { currentUser } = useAuth()

  const [passportPhoto, setPassportPhoto] = useState('')
  const [proofOfAddress, setProofOfAddress] = useState('')
  const [identityDoc, setIdentityDoc] = useState('')
  const [personalDetails, setPersonalDetails] = useState(application)
  const [startedForm, setStartedForm] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [percent, setPercent] = useState({
    passport: 0,
    address: 0,
    identity: 0,
  })
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    setPersonalDetails(application)
    const s = personalDetailsRequired.every(
      (x) =>
        application && (application[x.field] === '' || !application[x.field]),
    )

    const fields = []
    personalDetailsRequired.forEach((input) => {
      if (
        application &&
        input.field &&
        (application[input.field] === '' || !application[input.field])
      ) {
        fields.push(input.label)
      }
    })
    setMissingFields(fields)
    setStartedForm(!s)
  }, [application])

  const storage = getStorage()

  const uploadToFirebase = (file, fileName) => {
    const storageRef = ref(storage, `/files/${currentUser.uid}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )

        // update progress
        setPercent({
          ...percent,
          [fileName]: currentPercent,
        })
      },
      (err) => console.error(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          switch (fileName) {
            case 'passport':
              setPassportPhoto(url)
              setValue('passportPhoto', url)
              break
            case 'address':
              setProofOfAddress(url)
              setValue('proofOfAddress', url)
              break
            case 'identity':
              setIdentityDoc(url)
              setValue('identityDoc', url)
              break
            default:
          }
        })
      },
    )
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const downloadFile = (fileName, uid) => {
    setDownloading(true)
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${uid}/${fileName}`,
    )

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = `${fileName}-${uid}`
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  return (
    <>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Personal Details
      </Typography>
      <Typography
        variant="body1"
        mb={!!startedForm && missingFields.length > 0 ? 2 : 5}
      >
        <strong>Please let us know about yourself</strong>
      </Typography>
      {!!startedForm && missingFields.length > 0 && (
        <Alert severity="warning" sx={{ marginBottom: 4 }}>
          The following fields have yet to be completed:
          {missingFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Alert>
      )}
      <FormGroup>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.firstName}
              id="firstName"
              label="First Name"
            />
          )}
          control={control}
          name="firstName"
          defaultValue={currentUser.firstName || personalDetails?.firstName}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.lastName}
              id="lastName"
              label="Last Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="lastName"
          defaultValue={currentUser.lastName || personalDetails?.lastName}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.email}
              id="email"
              label="Email"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="email"
          defaultValue={currentUser.email || personalDetails?.email}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.phone}
              id="phone"
              label="Phone"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="phone"
          defaultValue={personalDetails?.phone || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.address}
              id="address"
              label="Address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="address"
          defaultValue={personalDetails?.address || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.address2}
              id="address2"
              label="Address 2"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="address2"
          defaultValue={personalDetails?.address2 || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.city}
              id="city"
              label="City"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="city"
          defaultValue={personalDetails?.city || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.county}
              id="county"
              label="County"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="county"
          defaultValue={personalDetails?.county || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.postcode}
              id="postcode"
              label="Post code"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="postcode"
          defaultValue={personalDetails?.postcode || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="citizen">
          Are you a UK Citizen or have indefinite leave to remain in the UK?
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby="citizen" name="citizen">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          )}
          control={control}
          name="citizen"
          defaultValue={personalDetails?.citizen || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.nino}
              id="nino"
              label="National Insurance Number"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="nino"
          defaultValue={personalDetails?.nino || ''}
        />

        <FormLabel sx={{ marginTop: 3 }} id="passportPhoto">
          Photo (passport style)
        </FormLabel>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            textTransform: 'none',
            width: '150px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'passport')}
          />
          <input
            {...register('passportPhoto')}
            type="text"
            hidden
            defaultValue={personalDetails?.passportPhoto || ''}
          />
        </Button>
        {percent.passport > 0 && percent.passport < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent.passport}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!passportPhoto || !!personalDetails?.passportPhoto) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '200px',
              margin: 0,
              marginTop: '5px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('passport', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}

        <FormLabel sx={{ marginTop: 3 }} id="proofOfAddress">
          Proof of address
        </FormLabel>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            textTransform: 'none',
            width: '150px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*,file/*"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'address')}
          />
          <input
            {...register('proofOfAddress')}
            type="text"
            hidden
            defaultValue={personalDetails?.proofOfAddress || ''}
          />
        </Button>
        {percent.address > 0 && percent.address < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent.address}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!proofOfAddress || !!personalDetails?.proofOfAddress) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '200px',
              margin: 0,
              marginTop: '5px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('address', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}

        <FormLabel sx={{ marginTop: 3 }} id="identityDoc">
          Passport, driving licence or identity card
          <br />
          <small>Please upload the photo page of one of the above</small>
        </FormLabel>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            textTransform: 'none',
            width: '150px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*,file/*"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'identity')}
          />
          <input
            {...register('identityDoc')}
            type="text"
            hidden
            defaultValue={personalDetails?.identityDoc || ''}
          />
        </Button>
        {percent.identity > 0 && percent.identity < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent.identity}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!identityDoc || !!personalDetails?.identityDoc) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '200px',
              margin: 0,
              marginTop: '5px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('identity', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}
      </FormGroup>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Save and continue
      </Button>
      <span>Next: Medical Details</span>
    </>
  )
}

Personal.propTypes = {
  application: PropTypes.object,
}

export default Personal

/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm } from 'react-hook-form'
import { updateUser } from '../data/user'
import { useAuth } from '../contexts/AuthContext'

const EditUser = ({ callback }) => {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm()
  const { currentUser, updateAuthEmail, updateAuthPassword, db, fetchUser } =
    useAuth()

  const [errorMessage, setErrorMessage] = useState('')
  const [userDetails, setUserDetails] = useState(currentUser)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setUserDetails(currentUser)
  }, [currentUser])

  const onSubmit = async (data) => {
    const dataToUpdate = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== ''),
    )

    const databaseUser = { ...dataToUpdate }
    delete databaseUser.password
    delete databaseUser.confirmPassword

    setLoading(true)
    setErrorMessage('')

    try {
      if (Object.keys(databaseUser).length > 0) {
        await updateUser(databaseUser, currentUser.uid, db)
      }

      if (dataToUpdate.email && currentUser.email !== dataToUpdate.email) {
        await updateAuthEmail(dataToUpdate.email)
      }

      if (dataToUpdate.password) {
        await updateAuthPassword(dataToUpdate.password)
      }

      fetchUser()
      if (callback) callback()
    } catch (error) {
      setLoading(false)
      const errorMessage = error.message
      setErrorMessage(errorMessage)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} marginTop="20px">
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            defaultValue={userDetails.firstName}
            id="firstName"
            label="First Name"
            autoFocus
            {...register('firstName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            defaultValue={userDetails.lastName}
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            {...register('lastName')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email"
            defaultValue={userDetails.email}
            label="Email Address"
            name="email"
            autoComplete="email"
            {...register('email', {
              pattern:
                // eslint-disable-next-line max-len
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors?.email?.type === 'pattern' && (
            <Typography variant="caption" color="error">
              Please enter a valid email address
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            {...register('password', {
              pattern:
                // eslint-disable-next-line max-len
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^])[A-Za-z\d@$!%*?&^]{16,}$/,
            })}
          />
          {errors?.password?.type === 'pattern' && (
            <Typography variant="caption" color="error">
              The password must be at least 16 characters and contain at least
              one lowercase character, one uppercase character, one number, and
              one special character
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="confirm-password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            autoComplete="new-password"
            {...register('confirmPassword', {
              required:
                watch('password') !== '' && watch('password') !== undefined,
              validate: (val) => watch('password') === val,
            })}
          />
          {watch('confirmPassword') !== watch('password') &&
            getValues('confirmPassword') && (
              <Typography variant="caption" color="error">
                Your passwords do not match
              </Typography>
            )}
          {errors?.confirmPassword?.type === 'required' && (
            <Typography variant="caption" color="error">
              Please confirm your password
            </Typography>
          )}
        </Grid>
      </Grid>
      {!!errorMessage && (
        <Alert sx={{ marginTop: 2 }} severity="error">
          {errorMessage}
        </Alert>
      )}
      <Grid justifyContent="end" container>
        {!!callback && (
          <LoadingButton
            loading={loading}
            type="button"
            onClick={() => callback()}
            variant="contained"
            color="info"
            sx={{ mt: 3, mb: 2, mr: 1 }}
          >
            Cancel
          </LoadingButton>
        )}
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save
        </LoadingButton>
      </Grid>
    </form>
  )
}

EditUser.propTypes = {
  callback: PropTypes.func,
}

EditUser.defaultProps = {
  callback: () => {},
}

export default EditUser

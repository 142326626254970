import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import {
  TextField,
  Button,
  FormGroup,
  FormLabel,
  LinearProgress,
  Typography,
  Box,
  Alert,
  Snackbar,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Grid from '@mui/material/Unstable_Grid2'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { dogDetailsRequired } from '../../utils/requiredFields'
import { LoadingButton } from '@mui/lab'

import { useAuth } from '../../contexts/AuthContext'

const Dog = ({ application, goBack }) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext()
  const { currentUser } = useAuth()

  const [dogPhoto, setDogPhoto] = useState('')
  const [dogDetails, setDogDetails] = useState(application)
  const [percent, setPercent] = useState(0)
  const [startedForm, setStartedForm] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    setDogDetails(application)
    const s = dogDetailsRequired.every(
      (x) =>
        application && (application[x.field] === '' || !application[x.field]),
    )

    const fields = []
    dogDetailsRequired.forEach((input) => {
      if (
        application &&
        input.field &&
        (application[input.field] === '' || !application[input.field])
      ) {
        fields.push(input.label)
      }
    })
    setMissingFields(fields)
    setStartedForm(!s)
  }, [application])

  const storage = getStorage()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const downloadFile = (fileName, uid) => {
    setDownloading(true)
    const storage = getStorage()
    const fileRef = ref(
      storage,
      `gs://pads-foundation.appspot.com/files/${uid}/${fileName}`,
    )

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = `${fileName}-${uid}`
          a.dispatchEvent(new MouseEvent('click'))
          setDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
        setDownloading(false)
      })
  }

  const uploadToFirebase = (file, fileName) => {
    const storageRef = ref(storage, `/files/${currentUser.uid}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setPercent(currentPercent)
      },
      (err) => console.error(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDogPhoto(url)
          setValue('dogPhoto', url)
        })
      },
    )
  }

  return (
    <>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Dog details
      </Typography>
      <Typography
        variant="body1"
        mb={!!startedForm && missingFields.length > 0 ? 2 : 5}
      >
        <strong>Please let us know about your dog</strong>
      </Typography>
      {!!startedForm && missingFields.length > 0 && (
        <Alert severity="warning" sx={{ marginBottom: 4 }}>
          The following fields have yet to be completed:
          {missingFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Alert>
      )}
      <FormGroup>
        <Typography variant="body1">
          <strong>Dog details</strong>
        </Typography>
        <FormLabel sx={{ marginTop: 3 }} id="dogPhoto">
          Dog photo
        </FormLabel>
        <Button
          component="label"
          variant="contained"
          color="info"
          startIcon={<UploadFileIcon />}
          sx={{
            marginRight: '1rem',
            marginTop: '10px',
            textTransform: 'none',
            width: '150px',
          }}
        >
          Choose file{' '}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => uploadToFirebase(e.target.files[0], 'dogPhoto')}
          />
          <input
            {...register('dogPhoto')}
            type="text"
            hidden
            defaultValue={dogDetails?.dogPhoto || ''}
          />
        </Button>
        {percent > 0 && percent < 100 && (
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              width: '150px',
            }}
          />
        )}
        {(!!dogPhoto || !!dogDetails?.dogPhoto) && (
          <LoadingButton
            loading={downloading}
            type="button"
            sx={{
              width: '200px',
              margin: 0,
              marginTop: '5px',
              padding: 0,
              textTransform: 'none',
            }}
            onClick={() => downloadFile('dogPhoto', currentUser.uid)}
          >
            Click here to view selected file
          </LoadingButton>
        )}

        <Grid container>
          <Grid xs={8} padding={0} paddingRight={1}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.dogName}
                  id="dogName"
                  label="Name"
                  sx={{ marginTop: 3, width: '100%' }}
                />
              )}
              control={control}
              name="dogName"
              defaultValue={dogDetails?.dogName || ''}
            />
          </Grid>
          <Grid xs={4} padding={0}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.dogAge}
                  id="dogAge"
                  label="Age"
                  sx={{ marginTop: 3 }}
                />
              )}
              control={control}
              name="dogAge"
              defaultValue={dogDetails?.dogAge || ''}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={8} padding={0} paddingRight={1}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.dogBreed}
                  id="dogBreed"
                  label="Breed"
                  sx={{ marginTop: 3, width: '100%' }}
                />
              )}
              control={control}
              name="dogBreed"
              defaultValue={dogDetails?.dogBreed || ''}
            />
          </Grid>
          <Grid xs={4} padding={0}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.dogWeight}
                  id="dogWeight"
                  label="Weight (kg)"
                  sx={{ marginTop: 3 }}
                />
              )}
              control={control}
              name="dogWeight"
              defaultValue={dogDetails?.dogWeight || ''}
            />
          </Grid>
        </Grid>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.microchipNumber}
              id="microchipNumber"
              label="15 digit Microchip number"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="microchipNumber"
          defaultValue={dogDetails?.microchipNumber || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.insuranceCompany}
              id="insuranceCompany"
              label="Insurance company"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="insuranceCompany"
          defaultValue={dogDetails?.insuranceCompany || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.insurancePolicyNumber}
              id="insurancePolicyNumber"
              label="Insurance policy number"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="insurancePolicyNumber"
          defaultValue={dogDetails?.insurancePolicyNumber || ''}
        />

        <Box mt={3}>
          <FormLabel id="insuranceStartDate">Insurance start date</FormLabel>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register('insuranceStartDate')}
              format="DD-MM-YYYY"
              defaultValue={
                dogDetails?.insuranceStartDate
                  ? dayjs(dogDetails?.insuranceStartDate)
                  : ''
              }
              onChange={(value) =>
                setValue(
                  'insuranceStartDate',
                  dayjs(value).format('DD-MM-YYYY'),
                )
              }
            />
          </LocalizationProvider>
        </Box>
      </FormGroup>

      <Button
        variant="contained"
        color="info"
        type="button"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
        onClick={() => goBack()}
      >
        Back
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Save and continue
      </Button>
      <span>Next: Veterinary Information</span>
    </>
  )
}

Dog.propTypes = {
  application: PropTypes.object,
  goBack: PropTypes.func,
}

export default Dog

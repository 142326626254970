/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const PublicAccessGuidelines = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="puppy-development" style={styles.title}>
      6. Public Access Guidelines
    </Text>
    <Text style={styles.text}>
      This document offers guidance concerning appropriate assistance dog
      behaviour in public. These behaviours are expected whether or not your dog
      is “on duty”/working and is separate to any behaviours your dog is
      expected to perform in relation to your disability when working.
    </Text>
    <Text style={styles.text}>
      Please ensure you are familiar with the PADs training process and what is
      expected of you and your dog as a team.
    </Text>
    <Text style={styles.text}>
      The PADs public access assessment will provide service providers with
      peace of mind that:
    </Text>
    <ListItem>
      The team is genuine and work together ethically and responsibly.
    </ListItem>
    <ListItem>The dogs’ welfare needs are being met.</ListItem>
    <ListItem>The handler’s disability has been verified</ListItem>
    <ListItem>The dog is regularly vet checked.</ListItem>
    <ListItem>The dog is insured (minimum 3rd party).</ListItem>
    <Text style={styles.underlineHeading}>PUBLIC ACCESS TEST ITEMS</Text>
    <Text style={styles.subtitleBold} id="training-aids">
      1. Training Aids
    </Text>
    <Text style={styles.text}>
      PADS encourages reward-based training throughout the dog&apos;s lifetime.
      However as training evolves and progresses, teams should keep the use of
      treats, rewards and/or training aids to a minimum when in working mode. It
      is understandable that during the initial stages of training rewards will
      be used, however the general expectation is that eventually an assistance
      dog will be trained to do its job without heavy reliance on treats or
      toys. They will be used occasionally to maintain behaviour criteria.
    </Text>
    <Text style={styles.text}>
      That said, your dog is not a machine, reward via verbal praise or petting
      to reward good behaviour in public can be fundamental to sustaining your
      bond and encouraging your dog to enjoy working. Transitioning away from
      using training aids as your default so that your dogs training is reliable
      should the training aid run out or become unavailable.{' '}
    </Text>
    <Text style={styles.text}>
      Aversive training, including but not limited to, pain, fear, harsh lead or
      physical corrections are unacceptable. Different, reward based methods are
      available. These are more effective and have a greater probability of
      preserving and encouraging a positive partnership and “team”
      relationship—this can be essential to your dog’s health and happiness.
    </Text>
    <Text style={styles.text}>
      Furthermore, training aids (including but not limited to prong collars,
      choke collars, shock collars, and studded/prong harnesses) or any type of
      device designed to train or control by causing pain or anxiety is
      unacceptable. An appropriately used gentle head collar or non-prong,
      no-pull harness is acceptable long-term only if the handler needs such an
      item due to their disability. This kind of device should only be used as
      an emergency failsafe (e.g., safety for balance issues), and cannot be
      relied on to control the dog. In most instances a harness is preferable.
    </Text>
    <Image
      style={{
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(80).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="lead-requirement">
      2. On - Lead Requirement
    </Text>
    <Text style={styles.text}>
      Upon walking on lead (whether using harness or collar) there should be no
      pulling, straining or tautness The lead should be in a J shape (in other
      words loose) with no tension.
    </Text>
    <Text style={styles.text}>
      Tension on lead is acceptable if it is required for mitigating behaviours
      related to your disability/impairment. For example, dogs may need to pull
      in response to a cue or stimulus to provide assistance. Retractable leads
      are acceptable only if needed for a specific task related to your
      disability. In any case, you should always maintain control without the
      dog pulling or wandering.
    </Text>
    <Text style={styles.text}>
      As part of the on-lead requirement your dog will need to stop walking when
      you do, including stopping at roadsides, cross the road on command as well
      as change positions/sides/move out of the way when asked to by handler.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(81).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="inappropriate-behaviour">
      3. Inappropriate Assistance Dog Behaviour
    </Text>
    <Text style={styles.text}>
      Please ensure your dog does not display (including but not limited to) any
      of the behaviours below:
    </Text>
    <ListItem>
      growling or inappropriate excessive barking (where alert barking is not
      required as an assistance task)
    </ListItem>
    <ListItem>
      nipping, snapping or biting (however, please see puppy development section
      for further information dependent upon your dogs age)
    </ListItem>
    <ListItem>showing, baring teeth or snarling (outside of play)</ListItem>
    <ListItem>
      lunging at other people, dogs or items in the environment
    </ListItem>
    <ListItem>being out of handler’s control</ListItem>
    <ListItem> inappropriate elimination(urinating or defecating)</ListItem>
    <Text style={styles.text}>
      Please always be aware of different aspects and the context of your dog’s
      behaviour and body language in order to assist you with potential issues
      that may arise. For example different types of tail wagging or raised
      hackles may or may not be indicative of a potential issue, but there is a
      possibility it may be and merits close attention. Knowledge of stress
      signs in dogs is also important. (Please see, Communication and Body
      language chapter for further info).
    </Text>
    <Text style={styles.text}>
      Should any potential behaviour issues arise, these need to be resolved
      through further training such as behaviour modification – with the help of
      a qualified professional dog trainer/behaviourist. This is for the safety
      and well being of you, your dog and everyone else in the surrounding and
      immediate environments.
    </Text>
    <Text style={styles.text}>
      It is vital that the public have the experience and belief that assistance
      dogs are safe. It is impossible for you to control every aspect of the
      environment; unavoidable and unpredictable interactions may occur; if
      inappropriate behaviours manifest and persist despite professional
      assistance, you will need to strongly contemplate and consider finding a
      different dog with a suitable temperament in line with assistance dog
      work. Please remember not all dogs are suited to being assistance dogs.
    </Text>
    <Text style={styles.text}>
      There are many ways to measure your dog’s ability to be a reliable
      assistance dog. For instance, one milestone is to identify when your dog
      can move through any situation or circumstance in an “even” manner (e.g.
      little or no altering in his body language or excitement level). Or, if
      your dog becomes alarmed, composure should be regained swiftly and focus
      on handler should return with minimal or no reminders. The handler should
      never have to drag, pull or force the dog to keep him working. A dog
      should be confident in working in public and not be excessively or
      constantly fearful or anxious. Please consult your trainer regarding
      training progression (measuring aims and ability).
    </Text>
    <Text style={styles.subtitleBold} id="assist-role">
      4. Working/Assistance Role
    </Text>
    <Text style={styles.text}>
      A dog should be at ease and poised/confident in its assistance role. Dogs
      should not hide out of fear/anxiety or forge ahead out of uninhibited
      disconnection.
    </Text>
    <Text style={styles.text}>
      You and your dog form a team and your work together will be unique to your
      requirements and the tasks your dog needs to perform to assist you. Each
      handler’s requirements will be different; one handler may need their dog
      to work slightly ahead or in front, whilst another will need their dog to
      work slightly behind. A handler in a wheelchair may require their dog to
      go behind the chair to navigate a narrow passage.
    </Text>
    <Text style={styles.text}>
      Dogs of differing sizes may also assist in different ways. A small dog may
      work entirely from the handler’s lap when seated. This may be to provide
      reassurance or for proximity to food to detect allergens. Larger dogs may
      provide reassurance by lying on the floor but leaning against the handler
      in a certain position.
    </Text>
    <Text style={styles.text}>
      All assistance dogs, regardless of size, should be trained to be reliably
      controlled on the ground, to be prepared for situations where carrying is
      not possible (e.g. airport security checks,). This includes your dog being
      able to respond to a command that instructs them to move position (back,
      change sides or similar).
    </Text>
    <Text style={styles.text}>
      <Text style={{ ...styles.textBold, color: 'red' }}>
        Dogs should never be placed in circumstances that are unsafe to
        themselves or others.
      </Text>{' '}
      For example, a small dog should not be on the ground in a boisterous,
      shoulder-to-shoulder environment. Equally, handlers should be mindful not
      to place their dogs in positions that may be hazardous to others, such as
      lying in a narrow aisle with foot traffic.
    </Text>
    <Text style={styles.subtitleBold} id="assist-role">
      5. Vehicles and Public Transportation
    </Text>
    <Text style={styles.text}>
      Be prepared to safely use any form of transportation (personal car, taxi,
      bus, metro/subway/light rail, cable car, train, aeroplane, or other form
      of public transport). For those in suburban or rural environments, a car
      or van may be the only type of vehicle used for travel, this will be the
      only type of vehicle you will train to use. That said, as we cannot always
      anticipate what may happen in life, we advise gaining experience and
      exposure to as many types of transport as possible. Training under
      controlled settings and sessions gradually and slowly is better than
      hoping these skills will not be necessary and it going horribly wrong in
      the event it does happen. Again, please liaise with your trainer.
    </Text>
    <Text style={styles.italics}>
      * Please note: It is recommended that before any assistance dog is
      required to fly In Cabin with their handler that the dog has gone through
      flight preparation training.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '400px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(82).jpg?alt=media"
    />
    <Text style={styles.text}>
      Your dog should be able to wait until given the command to enter or exit
      the mode of transport you are using. Handlers should be able to disembark
      the dog and unload any other required equipment (bags, wheelchair, walker,
      crutches, cane, etc.) with the dog following any instructions/commands.
      The dog should be on a harness or lead when leaving the vehicle and remain
      under control and close to the handler.
    </Text>
    <Text style={styles.text}>
      Safely using transportation includes not only entering and exiting, but
      also as a passenger or driver. Many handlers use a safety-rated seatbelt
      harness in automobiles to minimise the risk of their dogs from becoming
      injured in a crash. Be sure the airbag is disarmed for your dog’s seat,
      these are not designed or safe for canines. Safety choices are up to the
      individual handlers, but remember accidents happen and are generally out
      of our control. Safety first.
    </Text>
    <Text style={styles.text}>
      Be sure to have a system ready when boarding or exiting any transportation
      with automatic doors. Doors which automatically close do not ensure you
      are on or off together. Although some may have sensors these are not
      always 100% reliable. It may be preferable to go through doors in a
      parallel fashion to ensure you are not separated. Consider taking off any
      over the shoulder or attached leads and holding them (if possible) to
      prevent and avoid harm should you be separated.
    </Text>
    <Text style={styles.text}>
      Your dog should be trained to ride in vehicles calmly with no significant
      anxiety. The dog should remain under control at all times without the need
      for force.
    </Text>
    <Text style={styles.subtitleBold} id="car-park">
      6. Car Park Behaviour
    </Text>
    <Text style={styles.text}>
      Teams should be able to navigate the car park safely. The dog should be
      able to remain in the desired position unless cued to do otherwise. The
      dog should be able to navigate a car park environment with confidence and
      remain focused on its handler. Remember that car parks have different
      layouts and some are busier and bigger than others, it is important to
      practise this in various locations.
    </Text>
    <Text style={styles.subtitleBold} id="building">
      7. Entering a Building
    </Text>
    <Text style={styles.text}>
      Teams should be able to enter a building in line with the assistance work
      required of them for your specific need and of course in a safe manner.
      Your dog should be confident when encountering varied types of flooring,
      noises, automatic doors, and other sensations associated with places of
      business. If required, the dog should be able to safely assist (for
      example to operate doors for the handler).
    </Text>
    <Text style={styles.subtitleBold} id="shops">
      8. Navigating Shops
    </Text>
    <Text style={styles.text}>
      The team should be able to move through shops with the dog in a working
      position. The dog should not attempt to solicit attention from others
      (animals or humans), knock into shelves, or interact with merchandise, for
      example by sniffing or licking. The dog should be able to walk past and
      through highly stimulating sections such as the bakery, meat, deli, and
      pet food aisle. They should be able to keep sufficient focus on the
      handler and disregard any distractions. However, if the dog has been
      trained specifically to sniff food to assist the handler (food allergies)
      then the dog can work at recognising the trigger by sniffing the food from
      a reasonable distance.
    </Text>
    <Text style={styles.text}>
      The dog should remain working and under control if and when the handler is
      interacting with the public. He should not solicit attention from others
      unless it is agreed beforehand by the handler and the other party (given a
      specific command). If it transpires that the dog behaves inappropriately,
      the handler should regain control rapidly and respectfully.
    </Text>
    <Text style={styles.text}>
      If the handler is able to use a trolley, the dog should be able to
      maintain a working position beside the trolley while the person uses it.
      Largely, it is NOT a sensible, or good practice for an Assistance Dog to
      be placed in a shopping trolley. However, this again would be dependent
      upon the mitigating behaviours required and the size of the dog.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '400px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(83).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="distractions">
      9. Working with Distractions
    </Text>
    <Text style={styles.text}>
      Your assistance dog should have the ability to perform its tasks and
      remain focused on you. If he becomes startled or distracted at any time,
      he should be able to quickly refocus on you with minimal redirection. The
      dog should not be aggressive, anxious or fearful; or cause the handler to
      fall or stumble. In addition to the basic public access behaviours and
      requirements covered in this document teams should work towards training
      in environments that prepare them for both common and challenging
      environments they will experience.{' '}
    </Text>
    <Text style={styles.text}>
      For many people, work and or school environments are commonplace; but a
      doctors surgery or airport may be particularly challenging. There’s a
      first time for everything you will encounter together, but it’s wise to
      build up ability through gradual and controlled exposure – so that your
      dog is able to work. If a dog is exposed to a certain highly stimulating
      noise in an environment they are accustomed to, they may be less likely to
      be fearful of a similar sound later in a different environment. Equally,
      riding on buses and trains (etc.) is advisable prior to travelling on a
      plane.
    </Text>
    <Text style={styles.text}>
      The world is unpredictable, therefore an assistance dog must have
      developed psychological shock absorbers through extensive training
      enabling them to gain experience and manage new situations they will
      encounter.
    </Text>
    <Text style={styles.subtitleBold} id="obedience">
      10. Basic Obedience
    </Text>
    <Text style={styles.text}>
      PADS require assistance dogs to follow the bulleted behaviours reliably in
      all circumstances regardless of normal working position. Many of these
      behaviours will be completed during the Kennel Club Bronze & Silver awards
      (or equivalent). Please see your training worksheets.
    </Text>
    <ListItem>Sit</ListItem>
    <ListItem>Down</ListItem>
    <ListItem>Stand</ListItem>
    <ListItem>Stay (Sit, Down, Stand)</ListItem>
    <ListItem>Settle (Goes to blanket, bed)</ListItem>
    <ListItem>Recall</ListItem>
    <ListItem>Leave-it</ListItem>
    <ListItem>Drop-it</ListItem>
    <ListItem>Heel - walking nicely and closely</ListItem>
    <ListItem>Look/focus on handler</ListItem>
    <Text style={styles.text}>
      Dogs should be able to perform all the above behaviours and maintain these
      behaviours until signalled to end behaviour (bridged/marked) minimum of 30
      seconds. Handling an assistance dog in places where pets/dogs are not
      allowed requires more advanced dog handling skills. For example, if the
      dog’s lead is dropped, the dog should still continue working and focused.
      The dog may temporarily leave a working position to recover or repossess
      the lead if trained to do this.
    </Text>
    <Text style={styles.text}>
      As some members of the general public may approach and interact with your
      assistance dog without you noticing in time to stop it, your dog should be
      able to tolerate this. Some trained/on cue behaviours associated with this
      may be:
    </Text>
    <ListItem>Greeting nicely and calmly</ListItem>
    <ListItem>Ignore them</ListItem>
    <ListItem>Move out of the way</ListItem>
    <Text style={styles.subtitleBold} id="cafes">
      11. Restaurants/Cafes
    </Text>
    <Text style={styles.text}>
      Teams should be able to enter and pass through a restaurant, café, food
      court or eating area in a controlled manner. The dog should not attempt to
      eat, lick, or closely sniff any food or items on the floor, on tables or
      anywhere in the environment. Again however, if one of the dog’s mitigating
      behaviours is to sniff food to assist with the handler’s need, then the
      dog can work at Trigger identification by sniffing food/item from a
      reasonable distance.
    </Text>
    <Text style={styles.text}>
      Begging from anyone or attempting to interact with other clients or
      members of staff is not permitted. Handlers should place the dog in a
      space that enables the dog to perform its duties, not interfering with the
      coming and going of others. In most situations, this is lying under or if
      there is enough space beside the table Dogs should not be permitted on
      tables, chairs or benches at any time. It is acceptable for a small dog to
      be on the handler’s lap for disability mitigation, however this is only if
      the dog does not attempt to interact with the food or table in any way.
    </Text>
    <Text style={styles.text}>
      Handlers should never feed their dog any food from the table. If outside
      in the park, your dog should not scavenge picnics or food on the ground
      (same protocol as restaurants/cafes).
    </Text>
    <Text style={styles.text}>
      If you are in any eating area and your dog needs water, it is suitable to
      water your dog from its own container, but please be sure not to leave
      spilled water on any possibly slippery surfaces, in or outdoors.
    </Text>
    <Text style={styles.subtitleBold} id="lifts">
      12. Lifts
    </Text>
    <Text style={styles.text}>
      When entering a lift, teams should be able to enter and exit with the dog
      in a controlled manner remaining confident and calm in a sit, down or
      standing position. The dog should not closely sniff, scavenge or lick the
      environment nor should he interact with other patrons without prior
      consent. Always be mindful not to be separated from your dog by the
      closing of the elevator doors. It is good practice to walk into a lift
      side by side.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(84).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="stairs">
      13. Stairs and Steps
    </Text>
    <Text style={styles.text}>
      If using steps or stairs, your dog should preserve a position safe for all
      parties; this may differ from its usual working position. Your dog should
      not cause you or others to stagger, stumble or fall. If it is not
      necessary to navigate steps/stairways, your dog should be able to navigate
      wheelchair access ramps in a similarly safe manner. Handlers are
      encouraged to ensure their dog can handle stairs as a situation may arise
      where someone else needs to handle the dog.
    </Text>
    <Text style={styles.subtitleBold} id="other-dogs">
      14. Working around Other Dogs and animals
    </Text>
    <Text style={styles.text}>
      Working mode must be maintained in the presence of other dogs and animals.
      Casual notice of other dogs is permissible, but they should not approach
      or become overly distracted. It is unusual for most teams to come across
      other service dog teams, but on occasion it happens. Encounters with pets
      are more common and these pets often have much less training. It is
      important that teams are always prepared for surprises, and this type is
      imperative. If your dog is approached it should remain calm and
      undistracted.
    </Text>
    <Text style={styles.subtitleBold} id="toilets">
      15. Use of Public Restrooms
    </Text>
    <Text style={styles.text}>
      You can use accessible stalls with a service dog when such stalls are
      available. If you are unable to use accessible stalls or you do not need
      to use accessible stalls, a large dog can be placed in a stay command just
      outside the stall, should it be too small for both to occupy. Handlers can
      take the lead under the stall door and hold it, maintaining a connection
      with the dog.
    </Text>
    <Text style={styles.text}>
      Do not permit your dog to walk under partitions into other stalls, place
      its head into them, or peer into them disturbing other patrons.
    </Text>
    <Text style={styles.subtitleBold} id="vets">
      16. Vet Visits & Husbandry
    </Text>
    <Text style={styles.text}>
      It is important for an Assistance Dog to be comfortable visiting
      Veterinary Clinics and having body checks from Vets and vet staff. As part
      of your young dogs training it will be required that you work with your
      trainer and Vet Clinic to ensure that your dog is comfortable with
      necessary check ups and treatments. Please refer to the Canine Care
      chapter for further information. Husbandry Behaviour training sheet will
      be located with all other training sheets on the platform for you to work
      through with your trainer.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(85).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="mitigating-behaviours">
      17. Mitigating Behaviours
    </Text>
    <Text style={styles.text}>
      Mitigating behaviours are specifically trained behaviours that your dog
      performs to help keep you safe. These behaviours vary from person to
      person. Your dog will need to be able to perform a minimum of 3 task
      related behaviours on command in order to qualify as an Assistance Dog.
      Task related behaviours include, but are not limited to:
    </Text>
    <ListItem>Deep pressure Therapy </ListItem>
    <ListItem>Waking Handler from Night Terrors</ListItem>
    <ListItem>Fetching Med bag for handler</ListItem>
    <ListItem>Road Safety (for handlers that dissociate)</ListItem>
    <ListItem>Interruptive Behaviours (for self harm or OCD)</ListItem>
    <ListItem>
      Body Blocking (Creating space for handler in busy environment)
    </ListItem>
    <Text style={styles.text}>
      *Please note that a dog walking with you to the shops does not count as a
      Mitigating behaviour.{' '}
    </Text>
    <Text style={styles.text}>
      We understand that most dog trainers will not have experience training
      novel behaviours for assistance work. Here at PADs we will support your
      trainer by providing Zoom meetings to develop a training plan that they
      can utilise. This training plan will then be added to the platform for
      other In Training members to use. We want to hear from your trainer so
      <Text style={styles.textBold}>
        please ensure they contact us for support.
      </Text>
    </Text>
    <Text style={styles.subtitleBold} id="assessment">
      18. Assessment
    </Text>
    <Text style={styles.text}>
      The PADs Training Team will be developing a downloadable PDF Assessment
      Chapter that will be added to the platform in the coming months. This will
      contain information on our assessment, the criteria and information for
      you and your Trainer. The Assessment Chapter will not be required until
      all the training sheets have been worked through and signed off by your
      Trainer.
    </Text>

    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

PublicAccessGuidelines.propTypes = {
  styles: PropTypes.object,
}

export default PublicAccessGuidelines

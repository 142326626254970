import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { updateActivity } from '../../data/training-log'
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  Slider,
  Typography,
} from '@mui/material'

const EditActivity = (props) => {
  const { currentUser, db } = useAuth()

  const { activity, setEditing, setOpenSnackbar, setSnackbarMsg } = props

  const uid = currentUser.uid

  // Set default activity object
  const defaultActivity = {
    name: activity.name,
    duration: activity.duration,
    date: activity.date,
    trainer: activity.trainer,
    id: activity.id,
  }

  const [newActivity, setNewActivity] = useState(defaultActivity)

  const handleChange = (e, checkbox) => {
    const { name, value, checked } = e.target
    const updatedValue = checkbox ? checked : value
    setNewActivity({
      ...newActivity,
      [name]: updatedValue,
    })
  }

  const handleSlider = (e) => {
    const duration = e.target.getAttribute('aria-valuenow')
    setNewActivity({ ...newActivity, duration: duration })
  }

  const isValid = newActivity.name === ''

  // Add the activity to firebase via the API made in this app
  const handleSubmit = async () => {
    if (currentUser) {
      await updateActivity(newActivity, uid, db)
      setEditing(false)

      // Show alert and hide after 3sec
      setOpenSnackbar(true)
      setSnackbarMsg('Updated activity')
    }
  }

  return (
    <form noValidate onSubmit={(e) => e.preventDefault()}>
      <FormControl>
        <TextField
          style={{ marginTop: '5px' }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={newActivity.name}
          label="Activity name"
          name="name"
          onChange={handleChange}
        />
        <Typography id="discrete-slider" gutterBottom>
          Duration
        </Typography>
        <Slider
          defaultValue={parseInt(newActivity.duration)}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={10}
          marks
          min={10}
          max={120}
          name="duration"
          onChange={handleSlider}
          style={{ marginBottom: '20px' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activity.trainer}
              name="trainer"
              onChange={(e) => {
                handleChange(e, true)
              }}
            />
          }
          label="Completed with trainer"
        />
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => handleSubmit('add')}
        disabled={isValid}
      >
        Save activity
      </Button>
    </form>
  )
}

export default EditActivity

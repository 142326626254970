import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from '../src/theme'
import { AuthProvider } from './contexts/AuthContext'
import ApplicationProvider from './contexts/application/ApplicationContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApplicationProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ApplicationProvider>
    </AuthProvider>
  </React.StrictMode>,
)
reportWebVitals()

/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const BehaviourAndBodyLanguage = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="body-language" style={styles.title}>
      5. Communication & Body Language
    </Text>
    <Text style={styles.underlineHeading}>
      Understanding the Signs of Stress in Dogs:
    </Text>
    <Text style={styles.text}>
      Dogs have lived in close contact with humans for around 30,000 years.
      Their behavioural repertoire with humans employ similar signals as used in
      their dog to dog communications. Their entire body is used for
      communication, from nose to tail. Some of this communication may be
      intentional, some will be unintentional and simply an expression of an
      emotional state that is not under the dogs’ voluntary control.
    </Text>
    <Text style={styles.text}>
      When working with assistance dogs in training it is imperative that the
      handler/trainer know what to look for in terms of changes in body language
      and signs of stress.
    </Text>
    <Text style={styles.text}>
      In any form of animal training the animal must be willing and engaged in
      the training in order to learn effectively. It is especially important for
      assistance dog training, as the skills they are developing are needed to
      support their handler for the rest of the dog&apos;s working life. Knowing
      the signs of stress in a dog and knowing how to read body language ensures
      we have a greater understanding of the dogs&apos; motivation and demeanour
      during their training sessions.
    </Text>
    <Text style={styles.text}>
      Further, being an assistance dog requires a high level of stability and
      confidence. It is imperative we can observe what is worrying a dog and
      putting them under stress, so it can be flagged up as a
      training/behavioural need.
    </Text>
    <Image
      style={{ ...styles.image, width: '250px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(15).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="classifying-stress">
      Classifying the Signs of Stress in Dogs
    </Text>
    <Text style={styles.textBold}>What is Stress;</Text>
    <Text style={styles.text}>
      Stress is a non-specific physiological and neurological response of the
      body when any demand is placed on it. These demands do not necessarily
      have to be negative to cause stress.
    </Text>
    <Text style={styles.text}>
      Animals are constantly being bombarded with new demands both psychological
      and biological. Responding to these demands required effort and
      preparation for energy expenditure. This preparation and activation of
      internal resources constitute the experience of stress.
    </Text>
    <Text style={styles.text}>
      Although small amounts of stress are manageable, when stress becomes
      intense or long term it can become detrimental and is referred to as
      distress.
    </Text>
    <Text style={styles.text}>
      Dogs are unique in terms of what causes them negative stress. Some dogs
      can cope with certain stressful situations while others cannot.
      Furthermore, some dogs actively seek out situations others do not.{' '}
    </Text>
    <Text style={styles.textBold}>Stress triggers:</Text>
    <Text style={styles.text}>
      As much as we can generalise, it is thought that there are a number of
      things that tend to cause a dog stress:
    </Text>
    <ListItem>A person bending over them</ListItem>
    <ListItem>Prolonged hard eye contact </ListItem>
    <ListItem>A persons face being in theirs</ListItem>
    <ListItem>A person&apos;s angry vocalisations and behaviours</ListItem>
    <ListItem>
      Directly walking towards a dog (dogs will walk in curves around each other
      rather than direct){' '}
    </ListItem>
    <ListItem>
      Emotional conflict (e.g. being asked to ‘sit’ for a treat on a cold hard
      floor){' '}
    </ListItem>
    <ListItem>Long training sessions</ListItem>
    <ListItem>Mixed messages in training </ListItem>
    <ListItem>Too close contact e.g. hugging </ListItem>
    <ListItem>Feeling restrained or restricted</ListItem>
    <Text style={styles.text}>
      Calming signals or stress signals can be easily spotted in dogs under
      these circumstances.
    </Text>
    <Text style={styles.text}>
      Turid Rugaas is a Norwegian dog behaviourist who pioneered “calming
      signals” as methods of communicating to others with the goal of avoiding
      conflict or threats and to calm both themselves and others down. She
      posits that if a dog finds themselves in an uncomfortable situation s/he
      will communicate this discomfort through calming signals.{' '}
      <Text style={{ color: 'red' }}>
        Reference Turid Rugaas Calming Signals The Art of Survival.
      </Text>
    </Text>
    <Text style={styles.textBold}>Displacement behaviours:</Text>
    <Text style={styles.text}>
      Often dogs who are feeling stressed can first start to show displacement
      behaviours such as sniffing the ground, urinating or scratching
      themselves. The appearance of these behaviours are generally sudden or out
      of context. For example, a handler calling a dog to them with a harness in
      their hands. The dog may look and move to sniff a spot on the floor
      instead of immediately coming. This dog is showing a displacement
      behaviour, perhaps as a result of feeling stress around a harness.
    </Text>
    <Text style={styles.text}>
      The sniffing of the ground could be a quick drop of the head, sniff and
      head up again. However, it could also be a vigorous sniffing for several
      minutes. Especially if the handler holding the harness starts to sound
      frustrated or angry.
    </Text>
    <Text style={styles.textBold}>Cut-off signals:</Text>
    <Text style={styles.text}>
      Cut-off signals are behaviours similar to displacement and distance
      increasing behaviours but are usually seen in combinations of multiple
      simultaneous signals. Dogs use cut- offs to interrupt behaviour coming at
      them from another animal.
    </Text>
    <Text style={styles.text}>
      Cut-off displays signal that further interaction is not desired. Cut-off
      signals are triggered by conflict. If cut-off signals are not respected,
      the conflict will escalate.
    </Text>
    <Text style={styles.textBold}>Licking/tongue flicks:</Text>
    <Text style={styles.text}>
      A protrusion of the tongue can be easily missed as it is often a quick
      lick showing discomfort from lower ends of the stress escalation ladder.
      Often dogs are better at spotting and responding to lip licks than humans.
      (see Figure 2)
    </Text>
    <Image
      style={{ ...styles.image, margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(16).jpg?alt=media"
    />
    <Text style={styles.subheading}>Figure 2.</Text>
    <Text style={styles.textBold}>Head turning:</Text>
    <Text style={styles.text}>
      Dogs may turn their head slightly away, fully away, or indeed turn their
      whole bodies away from a dog/person/situation that is causing them stress.
    </Text>
    <Text style={styles.textBold}>Play bow:</Text>
    <Text style={styles.text}>
      A play bow is not just an invitation to play or an invite to recommence
      play after a break. A play bow can also be a calming signal, says Turid
      Rugaas. A still play bow in particular is suggested to be used by dogs who
      are trying to calm a situation down. Whilst play bows acting as invites to
      play tend to be bouncy and involve eye contact, moderating play bows tend
      to be still, with the dog averting his eyes or turning his head. (see
      Figure 3)
    </Text>
    <Text style={styles.textBold}>Walking slowly: </Text>
    <Text style={styles.text}>
      Another way a dog communicates stress and a desire for a situation to calm
      down is through purposefully walking slowly. This can be either away from
      the stressful situation, or towards. When walking towards something of
      concern dogs can also often offer paw lifts. Lifting one paw in the air
      and holding it is another sign a dog is unsure.
    </Text>
    <Text style={styles.textBold}>Yawning:</Text>
    <Text style={styles.text}>
      A yawn is another sign that a dog is feeling under stress. Especially
      incomplete yawns (e.g. simply opening the mouth with eyes kept open), or
      significant yawns that sometimes end with a “yip” vocalisation. (see
      Figure 2)
    </Text>
    <Text style={styles.textBold}>Sitting or lying down:</Text>
    <Text style={styles.text}>
      When a dog chooses to sit or lie down it can be communicative. Dogs may
      sit down when they are wanting to calm a situation down; especially if
      they are sitting with their backs turned to the stimuli. Dogs may also lie
      down when being approached or threatened by another dog they perceive to
      be a possible danger.
    </Text>
    <Text style={styles.text}>
      A dog under stress can escalate up the stress escalation ladder if the
      lower levels (displacement behaviours or calming signals) do not work.
    </Text>
    <Text style={styles.textBold}>Loss of calming signals</Text>
    <Text style={styles.text}>
      Particularly stiff stances and freezing is another way we can tell a dog
      is likely feeling stressed. However, an absence of behaviour is not
      necessarily communicating stress. Dogs may freeze when assessing a
      situation.
    </Text>
    <Text style={styles.text}>Other signs of stress in dogs include:</Text>
    <ListItem>Leaving sweaty paw prints (not easily spotted)</ListItem>
    <ListItem>
      “Shake off”’s when not wet (sometimes occurs after play)
    </ListItem>
    <ListItem>Hypervigilance in an environment</ListItem>
    <ListItem>
      Not eating when hungry (if prolonged can also be a sign of illness)
    </ListItem>
    <ListItem>Pacing</ListItem>
    <ListItem>Moving away</ListItem>
    <ListItem>Lowered body</ListItem>
    <ListItem>Dilated pupils</ListItem>
    <ListItem>Closed mouth</ListItem>
    <Text style={styles.text}>
      In general there are different degrees of these stress signals and how
      many a dog gives will depend on the individual dog.
    </Text>
    <Text style={styles.text}>
      Oftentimes many of the signals we might see begin as excitement or
      frustration, especially in adolescent dogs. The signals can be confusing
      as some parts of the body present as excitement such as a wagging tail,
      but there may be other signals that suggest stress or anxiety.
    </Text>
    <Text style={styles.text}>
      Excitement is a heightened emotion that does not necessarily feel good to
      a dog, even though as humans we consider it to be positive. Young or
      adolescent dogs can easily tip over into uncontrollable emotions which is
      where the unwanted behaviours can begin. When working with younger dogs
      who are learning to manage their emotions and behaviours it is important
      to give them distance from the triggering stimuli, removing them from the
      triggering stimuli, or ending a training session altogether to give them
      some calm down time.
    </Text>
    <Text style={styles.text}>
      Excitement is stress and in this state dogs cannot “think” or process
      learning.
    </Text>
    <Text style={styles.text}>
      If you notice conflicted signs particularly born out of excitement, move
      the dog away from what you perceive is making them feel uncomfortable.
    </Text>
    <Image
      style={{ ...styles.image, width: '370px', margin: '0 auto' }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(17).jpg?alt=media"
    />
    <Text style={styles.subheading}>Figure 3.</Text>
    <Text style={styles.subtitleBold} id="four-cs">
      The Four Cs in Dog Welfare & Training
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Calm</Text> – calm dogs can concentrate and
      feel in the right mindset to learn and feel good. We can help create calm
      within a dog&apos;s life by reducing exercise and stimulation and adding
      in enrichment such as brain games, kongs, snuffle mats and sprinkles.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Connection</Text> – dogs who are connected
      to their handler are able to trust them to guide and protect them from
      difficult situations. Connection is also the basis of many training needs
      such as walking nicely on a lead.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Choices</Text> – dogs who can choose
      behaviours, where to sleep, where to walk, when to sniff and so on often
      become more confident.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Confidence</Text> – leads to a positive bias
      and resilience.
    </Text>
    <Text style={styles.subtitleBold} id="signs-of-stress">
      Low level signs of stress
    </Text>
    <Text style={styles.textBold}>Head and face: </Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>Furrowed brow (see Figure 4)</ListItem>
        <ListItem>
          Ears pulled back, tension in ears, held close to the dogs head
        </ListItem>
        <ListItem>Avoiding eye contact</ListItem>
        <ListItem>Yawning </ListItem>
        <ListItem>
          Excessive lip licking – especially when no food around
        </ListItem>
        <ListItem>Whiskers pulled forward and tense</ListItem>
        <ListItem>Rapid blinking </ListItem>
        <ListItem>Dilated pupils </ListItem>
        <ListItem>Tension around the mouth (see Figure 4)</ListItem>
        <ListItem>Red membranes</ListItem>
      </div>
      <div>
        <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 4</Text>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(51).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.textBold}>Tail: </Text>
    <ListItem>Stiff tail, held close to body (see Figure 4)</ListItem>
    <ListItem>Tail between legs, even if it is wagging</ListItem>
    <Text style={styles.textBold}>Body: </Text>
    <ListItem>Stretching after contact or interaction</ListItem>
    <ListItem>Shaking off after contact or interaction</ListItem>
    <ListItem>Shaking off after doing something</ListItem>
    <Text>&nbsp;</Text>
    <Text>&nbsp;</Text>
    <Text style={styles.textBold}>Behaviours: </Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>Approaching and then turning back on trigger</ListItem>
        <ListItem>Sitting down away from trigger</ListItem>
        <ListItem>Putting body on other side of a safe person</ListItem>
        <ListItem>Penis crowning </ListItem>
        <ListItem>Drooling</ListItem>
        <ListItem>
          Sucking on toys or bedding – self-soothing behaviours
        </ListItem>
        <ListItem>Panting – when not in a hot environment</ListItem>
        <ListItem>A tense tongue held high in the mouth</ListItem>
        <ListItem>Spatulate tongue</ListItem>
        <ListItem>Over excitement</ListItem>
        <ListItem>Inability to concentrate</ListItem>
      </div>
      <div>
        <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 5</Text>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(52).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.subtitleBold}>
      Medium level signs of stress/arousal
    </Text>
    <Text style={styles.textBold}>Head and face: </Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>Signs from the above list </ListItem>
        <ListItem>Fast panting with tongue held high in mouth </ListItem>
        <ListItem>
          Rounded eyes, ears set back and whiskers pushed forward
        </ListItem>
        <ListItem>Labial commissure tense and forward (see Figure 4)</ListItem>
        <Text style={styles.textBold}>Tail: </Text>
        <ListItem>Fast frantic wagging, tail held high and stiff</ListItem>
        <ListItem>Hindquarters stiff </ListItem>
      </div>
      <div>
        <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 6</Text>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(53).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.textBold}>Body: </Text>
    <ListItem>Walking stiffly and on high alert</ListItem>
    <ListItem>Tip toe walking </ListItem>
    <ListItem> Fast movement of head and ears held high and forward</ListItem>
    <ListItem>
      Lack of movement – the slower the dog moves the more tension you see, the
      more uncomfortable they feel.
    </ListItem>
    <Text style={styles.textBold}>Behaviours: </Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>Approaching and then avoiding trigger </ListItem>
        <ListItem>Fixing on a safe person and ignoring the trigger</ListItem>
        <ListItem>Sitting or lying down with back turned to trigger</ListItem>
        <ListItem>
          Approaching very quickly, sniffing persons hand, face or crotch
        </ListItem>
        <ListItem>
          {' '}
          Approaching very quickly and jumping up to investigate, then quickly
          moving away
        </ListItem>
        <ListItem>Jumping up and mouthing </ListItem>
        <ListItem>Jumping up and clasping with forelegs </ListItem>
        <ListItem>Mounting</ListItem>
        <ListItem>Rubbing bottom along the floor </ListItem>
        <ListItem>Grabbing the lead</ListItem>
        <ListItem>Penis crowning</ListItem>
        <ListItem>Pacing</ListItem>
        <ListItem>
          Licking any part of the body after contact with someone{' '}
        </ListItem>
        <ListItem>
          Scratching any part of the body after contact with someone
        </ListItem>
        <ListItem>Body curved away from handler</ListItem>
        <ListItem>Dog approaching with ducked head and ears back</ListItem>
        <ListItem>Lunging</ListItem>
        <ListItem>Chewing hard on a toy after contact</ListItem>
        <ListItem>
          {' '}
          Sucking toys or bedding after contact (see Figure 7)
        </ListItem>
        <ListItem>Licking himself or handler</ListItem>
        <ListItem>Drooling</ListItem>
        <ListItem>Whining </ListItem>
        <ListItem>Over excitable</ListItem>
        <ListItem>Inability to concentrate</ListItem>
        <ListItem>Clingy</ListItem>
        <ListItem>Attention seeking </ListItem>
      </div>
      <div>
        <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 7</Text>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(54).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.subtitleBold}>High level signs of stress</Text>
    <Text style={styles.textBold}>Head and face:</Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>Ears pinned back on head</ListItem>
        <ListItem>Avoiding eye contact</ListItem>
        <ListItem>Overly intense staring at trigger</ListItem>
        <ListItem>Excessive lip licking</ListItem>
        <ListItem>Whiskers pushed forward and tense</ListItem>
        <ListItem>Dilated pupils</ListItem>
        <ListItem>Whites of eyes showing </ListItem>
        <ListItem>Staring (hard staring especially) </ListItem>
        <ListItem>Labial commissure moved forward and tense </ListItem>
        <ListItem>Front teeth exposed</ListItem>
        <ListItem>Muzzle wrinkled</ListItem>
        <ListItem>Snarling </ListItem>
      </div>
      <div>
        <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 8</Text>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(55).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.textBold}>Tail:</Text>
    <ListItem>Fast frantic wagging with tail high and stiff</ListItem>
    <ListItem>Hindquarters stiff (see Figure 8)</ListItem>
    <ListItem>Tail between legs </ListItem>
    <div style={{ textAlign: 'center' }}>
      <Image
        style={{ ...styles.image }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(56).jpg?alt=media"
      />
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 9</Text>
    </div>
    <Text style={styles.textBold}>Body:</Text>
    <ListItem>Walking stiffly and on high alert</ListItem>
    <ListItem>Body curved and tense </ListItem>
    <ListItem>
      Rigid back (if whale eye is also seen and commissure held tight – give
      space to avoid bite){' '}
    </ListItem>
    <ListItem>
      Lack of movement – freezing – often a prelude to a bite{' '}
    </ListItem>
    <Text style={styles.italics}>
      * note: whale eye is a term used to describe dogs eyes when the whites of
      eye can be seen (please see Figure 5)
    </Text>
    <Text style={styles.textBold}>Behaviour:</Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>Approaching and then avoiding </ListItem>
        <ListItem>Hiding away</ListItem>
        <ListItem>Approaching very fast with intent sniffing</ListItem>
        <ListItem>Approaching quickly, jumping up then lurching away</ListItem>
        <ListItem>Mounting frequently, especially during play</ListItem>
        <ListItem>Mounting toys</ListItem>
        <ListItem>Frequent bottom rubbing</ListItem>
        <ListItem>Freezing </ListItem>
        <ListItem>Penis crowning</ListItem>
        <ListItem>Grabbing the lead</ListItem>
        <ListItem>Pacing</ListItem>
        <ListItem>
          Licking or scratching any part of the body obsessively
        </ListItem>
        <ListItem>Howling</ListItem>
        <ListItem>Excessive urination/defecation</ListItem>
        <ListItem>Chewing hard on toys for prolonged periods </ListItem>
        <ListItem>Escape attempts</ListItem>
        <ListItem>Chewing furniture</ListItem>
        <ListItem>Excessive barking</ListItem>
        <ListItem>Spinning</ListItem>
        <ListItem>Tail chasing </ListItem>
        <ListItem>Growling and snapping </ListItem>
        <ListItem>Lunging</ListItem>
        <ListItem>Hyper excitability for prolonged periods</ListItem>
        <ListItem>Hypervigilance</ListItem>
        <ListItem>Inability to concentrate</ListItem>
        <ListItem>Clinginess</ListItem>
        <ListItem>Persistent and prolonged attention seeking</ListItem>
      </div>
      <div>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(57).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.underlineHeading}>
      Understanding the Body Language of Dogs:
    </Text>
    <Text style={styles.text}>
      The body language of dogs can refer directly to how they are feeling and
      involves social signals to aid communication. In terms of evolution,
      communication is about the sender gaining some degree of advantage. For
      example, a dog communicating distance-increasing signals to ward off a
      perceived threat. They are doing this for survival reasons.
    </Text>
    <Text style={styles.text}>
      Dogs have a wide vocabulary of body language signals, facial expressions
      and sounds which they use to get their meaning across. In terms of
      intraspecies communication (dog-dog), dogs communicate via all their
      senses:
    </Text>
    <ListItem>Pheromones and scent </ListItem>
    <ListItem>Smell </ListItem>
    <ListItem>Taste</ListItem>
    <ListItem>Touch</ListItem>
    <ListItem>Sight</ListItem>
    <ListItem>Sound</ListItem>
    <Text style={styles.text}>
      Through modification of their bodies a wide range of postures and
      positions of body parts can be used to convey different information.{' '}
    </Text>
    <Text style={styles.text}>
      Interspecies communication (e.g. dog-human) is more about body language
      and facial expressions. This is why it is of{' '}
      <Text style={styles.text}>paramount importance</Text> to be able to
      understand what a dog is telling us through their bodies when working with
      them in any training capacity.{' '}
    </Text>
    <Text style={styles.text}>
      Dogs have evolved certain skills allowing them to communicate with humans
      successfully. Whilst living with us, dogs are certainly able to further
      their communicative abilities (through operant conditioning – simply doing
      what works!); however, the evolutionary process of dog domestication has
      selected behavioural predispositions that facilitate their interactions
      with us also.
    </Text>
    <Text style={styles.text}>
      For effective communication, the sender first needs to gain the attention
      of the receiver. Dogs have been found to use gazing and gaze alternation
      in order to initiate a communicative interaction with humans.{' '}
    </Text>
    <Text style={styles.text}>
      Further to this, dogs can learn through reinforcement history that
      vocalising can also gain the attention they seek. Think of a dog who has
      learnt to whine at the back door in order to communicate a wish to go out
      into the garden. Dogs have a “theory of mind” in that they know when a
      person or dog has their attention elsewhere. Dogs have been studied to
      change their behaviour according to a human&apos;s attentive state.{' '}
    </Text>
    <Text style={styles.text}>
      In assistance dog training this theory of mind is utilised. Assistance
      dogs are trained to gain the attention of a human in a specific manner
      that works for both parties.{' '}
    </Text>
    <Text style={styles.italics}>
      * Please read the supplementary file: Miklosi 2009 Evolutionary approach
      to communication between humans and dogs{' '}
    </Text>
    <Text style={styles.text} id="body-language">
      In general a dog&apos;s body language must be taken as a whole.
      Hyper-focusing on one aspect is not going to give handlers a full picture.
      We will go through each body part individually, but try to integrate these
      together in your mind to build up a picture of a dogs&apos;s whole body
      language.{' '}
    </Text>
    <Text style={styles.subtitleBold}>The Eyes:</Text>
    <Text style={styles.text}>
      Eye tracking studies have demonstrated that dogs direct their attention
      primarily to the eye region when processing conspecific faces. Therefore,
      this region clearly plays an important role in dog-dog communication and
      it is necessary to be able to understand what a dog is
      feeling/communicating via their eyes.{' '}
    </Text>
    <Text style={styles.text}>
      The shape of the eye, the rate of blinking, the dilation of pupils and the
      muscles around the eye can all give handlers an idea of how a dog is
      feeling.
    </Text>
    <ListItem>
      A calm relaxed dog will have soft relaxed eyes. The eyes may be almond
      shaped; however, this can depend on the breed of dog. Some breeds
      naturally have much rounder eyes, even when relaxed.{' '}
    </ListItem>
    <ListItem>
      A relaxed dog will be blinking softly, and the gaze will not be intense or
      ‘hard’{' '}
    </ListItem>
    <ListItem>
      A dog who is feeling anxious or stressed will have much rounder eyes and
      their pupils are likely to be dilated.
    </ListItem>
    <ListItem>
      Aroused dogs will have a “hard stare”; staring unblinking. They may also
      show whale eye, where the eyes are opened wide and therefore lined with
      the white of the sclera. You can see it in the figure above.
    </ListItem>
    <Text style={styles.subtitleBold}>The Ears:</Text>
    <Text style={styles.text}>
      A relaxed dog will hold his ears in a “normal” position; however,
      depending on the breed and individual dog “normal” can be slightly
      different. In general, the ears will be relaxed to the sides. It is easier
      to see ear position in dogs with erect ears but it is possible to see
      changes in ear position with floppy-eared dogs as well, by looking at the
      base of the ear.
    </Text>
    <View style={bulletPointStyles.row}>
      <div style={{ width: '400px' }}>
        <ListItem>
          Dogs will bring their ears forwards when aroused, generally pointed
          towards a subject of interest (whether that interest is positive or
          negative).
        </ListItem>
        <ListItem>
          Tension in the ears like this simply predicts a dog is likely to react
          to something. (see Figure 10)
        </ListItem>
        <ListItem>
          Dogs will hold their ears back to communicate an appeasement
          intention.
        </ListItem>
        <ListItem>
          Appeasement behaviours are used by dogs to decrease the probability of
          an agonistic behaviour happening, and to indicate uncertainty.{' '}
          <Text style={styles.italics}>
            Please see the supplementary file ‘Overall 2014 – Do dogs appease
            each other – or us?’ for further discussion on appeasement signals
            and their role.
          </Text>
        </ListItem>
        <ListItem>
          Dogs in a state of conflict may show “aeroplane ears” – that is, ears
          extended out to the sides of their heads. Conflict means a dog is
          likely experiencing a mixed set of emotions and may be making a
          decision on how to react.
        </ListItem>
        <ListItem>
          Dogs will flatten their ears back when frightened. Extremely
          frightened individuals can press their ears so far back they are no
          longer visible from the front.
        </ListItem>
        <ListItem>
          Flattening of the ears can also be an agonistic response to a
          perceived threat.
        </ListItem>
      </div>
      <div>
        <Text style={{ ...styles.subheading, margin: '0 auto' }}>
          Figure 10
        </Text>
        <Image
          style={{ ...styles.image, width: '200px' }}
          src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(58).jpg?alt=media"
        />
      </div>
    </View>
    <Text style={styles.subtitleBold}>The Mouth:</Text>
    <Text style={styles.text}>
      A dog with his mouth open and tongue lolling out of the mouth is typically
      feeling relaxed. There will also be no facial or mouth tension in a
      relaxed dog. (see Figure 11)
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(59).jpg?alt=media"
      />
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 11</Text>
    </div>
    <Text style={styles.text}>
      Whilst a relaxed dog may have his mouth open panting, a stressed dog will
      show a different kind of panting: stress panting. With stress panting
      there is likely to be less protrusion of the tongue, the mouth is more
      ‘tight’ and closed and the panting is typically more rapid. In some dogs
      you may also be able to observe the upper lips holding tension which can
      lead to the teeth on the upper jaw slightly showing.
    </Text>
    <ListItem>
      The sides of the mouth are also important to observe. A stressed dog will
      have his lips retracted at the commissure and will have wrinkled skin.
      (see Figure 4)
    </ListItem>
    <ListItem>
      The labial commissures are drawn forward in agonistic displays and pulled
      back to communicate stress.
    </ListItem>
    <ListItem>
      Furthermore, a curled tongue is a sign of stress also. This is termed
      ‘spatulate tongue’. (see Figure 12)
    </ListItem>
    <div>
      <Image
        style={{ ...styles.image, width: '200px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(60).jpg?alt=media"
      />
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 12</Text>
    </div>
    <Text style={styles.italics}>
      *Note ‘spatulate tongue’ can also be a sign of heat stress and is often
      seen in brachycephalic dogs.
    </Text>
    <Text style={styles.text}>
      Whilst a closed mouth doesn’t necessarily mean a dog is feeling stressed,
      the action of closing the mouth whilst looking at a stimuli is likely to.
      For example, if a dog closes their mouth whilst you approach them, they
      are likely worried about your intent.
    </Text>
    <Text style={styles.text}>
      Whilst humans generally recognise that when a dog lifts his upper lips up,
      showing his teeth, he is displaying a physical warning, it is often missed
      that the dog is warning due to FEAR. Sometimes erroneously labelled trying
      to exert dominance, or just “being aggressive”, the underlying emotions of
      the behaviour can still be easily spotted in a dog who is having to resort
      to threats.
    </Text>
    <Text style={styles.subtitleBold}>The Tail:</Text>
    <Text style={styles.text}>
      Generally, a relaxed dog holds his tail in a neutral position, extending
      out from the spine, or lower. The more aroused/confident dog will lift his
      tail above spine level.
    </Text>
    <Text style={styles.text}>
      A dog&apos;s neutral tail position varies with breed. Beagles naturally
      hold their tails higher than other breeds. Whippet-like dogs naturally
      hold their tails lower.
    </Text>
    <Text style={styles.text}>
      Breeds with a curled tail have less pronounced differences in how they
      hold this tail as a result of how they are feeling. However, looking how
      tightly the tail is curled can give an idea. A loosely curled tail is a
      sign of a calm dog. A tightly curled tail is a sign of an aroused dog;
      however, this arousal could be positive or negative so it’s important to
      learn the situational differences. (see Figure 13)
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Image
        style={{ ...styles.image, width: '250px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(61).jpg?alt=media"
      />
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 13</Text>
    </div>
    <Text style={styles.text}>
      Importantly, a wagging tail is not necessarily the sign of a happy dog. A
      happy wag (friendly and excitable) typically spreads up the dogs back as
      well – causing a full body wiggle. A happy wag is loose and sweeping and
      may be circular in shape (‘helicopter tail’).
    </Text>
    <Text style={styles.text}>
      Short rapid movements indicate arousal. A tail held up high, stiff, and
      even vibrating can indicate a dog is about to act and is feeling confident
      about doing so. A slow wag of a stiff tail can denote the dog is ready to
      act also.
    </Text>
    <Text style={styles.text}>
      A fearful dog, or a dog showing appeasement, will tuck his tail between
      his rear legs, in order to decrease his body size. Whilst this can’t be
      fully observed in dogs with docked tails it is possible to see the base of
      the tail be pressed into the dog’s rear, suggesting that if there was an
      extension it would be lowered between their legs. A low rapid wagging of a
      stiff tail is generally associated with nervousness or internal conflict.
    </Text>
    <Text style={styles.italics}>
      * We must remember that a tail is only a part of the picture. Usually a
      tail influences the whole posture of the dog
    </Text>
    <Text style={styles.text}>
      Dogs have asymmetrically organised brains with the left and right
      hemispheres playing different roles. Interestingly itt has been found that
      dogs wag more to the right when they feel positive emotions and to the
      left when they feel negative emotions. This difference is felt by other
      dogs as well. According to Vallortigara, a dog looking at another dog
      wagging with a bias to the right side — thus showing left-hemisphere
      activation - produced relaxed responses from the dog. Vallortigara’s
      results also showed that a dog looking at another dog wagging with a bias
      to the left — thus showing right- hemisphere activation - produced anxious
      responses as well as increased cardiac frequency as if he was experiencing
      some sort of negative/withdrawal response.
    </Text>
    <Text style={styles.subtitleBold}>The Hair:</Text>
    <Text style={styles.text}>
      The hair along a dogs back, shoulders and base of tail can raise when they
      are feeling upset or aroused. This is known as piloerection or raised
      hackles (see Figure 14). This sign is an indicator that the dog is feeling
      an emotion quite intensely, but it isn’t necessarily a negative emotion
      e.g. fear or frustration. It could also be excitement. However, it is
      important to recognise that over- excitement to that degree does have the
      potential to flip across to negative behaviours quite quickly. It’s
      essentially a sign of a dog with a “short fuse”.
    </Text>
    <Text style={styles.text}>
      An aroused dog may also show piloerection on their tail. A raised tail
      that appears bushier than normal can be spotted.
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 14</Text>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(62).jpg?alt=media"
      />
    </div>
    <Text style={styles.subtitleBold}>Body Posture:</Text>
    <Text style={styles.text}>
      Each dog is an individual and may have a preferred way of expressing
      himself, depending on his confidence level, skills, learning and
      experiences.
    </Text>
    <Text style={styles.text}>
      The dogs’ general posture can go a long way to suggesting a dog&apos;s
      confidence level with regards to dealing with a situation.
    </Text>
    <Text style={styles.text}>
      When dogs are interacting with conspecifics the very first signals
      perceived that will give information about intentions is body size and
      body posture. Dogs can communicate confidence or threat by intentionally
      increasing their own body size by standing tall and using piloerection of
      hackles to further accentuate this (see right hand side Figure 9).
    </Text>
    <Text style={styles.text}>
      On the other hand, a dog can reduce their size by lowering their body and
      tail, this would have the goal of avoiding conflicts (see left hand side
      Figure 9).
    </Text>
    <Text style={styles.text}>
      Where the dog holds the majority of his weight is important. A dog leaning
      more on his back legs, especially with the back legs splayed is
      unconfident and ready to escape and run if necessary.
    </Text>
    <Text style={styles.text}>
      You often see this when a dog is being lured towards something (or
      someone) they don’t want to go near. They may transfer their weight to the
      front of their bodies to stretch towards the feared stimuli, but their
      back legs will be out behind them ready to dart away if they need to.. For
      this reason, luring isn’t often a successful technique as it can simply
      reaffirm to the dog that they are anxious and put them in situations they
      aren’t yet equipped to deal with. On the other hand, a dog with his weight
      either even or leaning forwards is feeling more confident. This may result
      in a more offensive reaction if the dog perceives this necessary, rather
      than defensive. (see right hand side Figure 9)
    </Text>
    <Text style={styles.text}>
      An anxious or fearful dog will have a lowered body position in general,
      almost as if they’re crouching. Whether the dog is standing, sitting or
      running, the lowered posture is visible.
    </Text>
    <Text style={styles.text}>
      In general, attention needs to also be paid to giving the dog a choice
      with whether he moves towards an interaction, or away from one. If a dog
      is on a lead, keeping the lead slack and giving the dog a chance to make
      their own minds up can be enlightening with regards to understanding the
      likes, dislikes and anxieties of the dog before you. Every dog is unique,
      and this goes for assistance dogs also.
    </Text>
    <Text style={{ ...styles.italics, color: 'red' }}>
      *Note to trainer: Assessing a dogs suitability for a role must include a
      lot of observation on the dogs natural body posture around key stimuli.
    </Text>
    <Text style={styles.text}>
      Stiff movements or freezing entirely can be the sign of a stressed dog.
      Relaxed dogs will show fluid movements with loose body language.
    </Text>
    <Text style={styles.subtitleBold} id="affiliative-signals">
      Affiliative Signals:
    </Text>
    <Text style={styles.text}>
      In general, the body language and social signals in dogs can be split up
      into a number of categories according to their purpose.
    </Text>
    <Text style={styles.text}>
      Affiliative signals are signals that communicate the intention to engage
      in prosocial contact i.e. friendly and co-operative. They express a
      dog&apos;s non-fearful and non-aggressive intentions to other dogs and
      people. Generally, they are distance- reducing i.e. serve the function of
      allowing the dog to approach another.
    </Text>
    <Text style={styles.text}>
      Play signals are affiliative signals: play bow, bouncy/lumbering gait,
      play face, pouncing, pawing. These all function as a way to initiate play.
    </Text>
    <Text style={styles.text}>
      Other affiliative signals that are less high energy include calm and
      relaxed body language with a gently wagging tail and slight pant. There
      may also be some eye contact but with soft blinking eyes. (see Figure 15).
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 15</Text>
      <Image
        style={{ ...styles.image, width: '200px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(63).jpg?alt=media"
      />
    </div>
    <Text style={styles.text}>
      Most friendly dogs make use of a few mild appeasement signals when wanting
      to engage with another, along with confident affiliative signals. This can
      show a conflicted state, likely due to a lack of confidence.{' '}
    </Text>
    <ListItem>
      Appeasement signals serve to avoid hostility and prevent aggressive
      behaviour. They are a non-aggressive way of achieving escape or preventing
      hostile treatment that might happen.
    </ListItem>
    <ListItem>
      Appeasement signals are not to be mixed up with submissive behaviour that
      serves to turn off aggression already happening.
    </ListItem>
    <ListItem>
      Appeasement signals include ears back, tail and hindquarters wagging,
      submissive grin, pawing, puppy licking, lowered body posture.
    </ListItem>
    <Text style={styles.subtitleBold} id="agonistic-signals">
      Agonistic Signals:
    </Text>
    <Text style={styles.text}>
      Contrary to Affiliative signals are Agonistic signals. These include acts
      of attack, escape, threat, defence and appeasement. They are signals that
      predict aggressive or appeasing behaviour.
    </Text>
    <Text style={styles.text}>
      Whilst affiliative signals regulate how individuals decrease distance from
      each other, agonistic signals control distance maintenance or increase
      distance.{' '}
    </Text>
    <Text style={styles.text}>
      Distance increasing signals are about conveying a “go away” message to the
      receiver. These generally will accompany aroused body language such as a
      closed mouth, stiff stance, ears pricked forward, tense tail raised over
      the level of the back, body weight leaning forwards and raised hackles. A
      direct stare is typically the first course of action for dogs who feel or
      perceive a threat and are trying to communicate the “go away” message. If
      this is not listened to, a dog can be forced to escalate their threat
      behaviour. They may add lip retraction, showing their teeth, and may
      include snarling or growling to thwart any further advance.
    </Text>
    <Text style={{ ...styles.italics, color: 'red' }}>
      Please see the supplementary file ‘Canine ladder of aggression’.
    </Text>
    <Text style={styles.text}>
      Under natural conditions aggressive behaviours are about communicative
      interactions; physical contact with serious consequences is rarer.
      Aggressive behaviours are about minimising the harmful effects of direct
      interaction between individuals.
    </Text>
    <Text style={styles.italics}>
      * Remember, in practice, most signal clusters are ambivalent, involving
      signals from more than one dimension at a time.
    </Text>
    <Text style={styles.subtitleBold} id="vocal-comms">
      Vocal Communication:
    </Text>
    <Text style={styles.text}>
      When looking into canine communications we must look further than just
      body language and postures. Dogs also communicate through acoustic
      communications.
    </Text>
    <Text style={styles.text}>
      As humans we are able to derive information from a dog&apos;s
      vocalisations about the sender&apos;s physical characteristics (e.g. size)
      and emotional state. For example, humans are thought to be able to
      correctly attribute emotions to growls according to the context in which
      they were produced: play, guarding or threatening.
    </Text>
    <Text style={styles.text}>
      It is thought that domestication caused considerable changes in a dogs’
      vocal behaviour. They have developed novel forms of pre-existing
      vocalisations to help facilitate communication with humans. Stray dogs and
      feral dogs have been studied to vocalise far less than dogs with
      meaningful social contact with humans.
    </Text>
    <Text style={styles.text}>
      Barking is communication. The barks are short repetitive signals with a
      variable acoustic structure. The pitch, volume and pattern of barks is
      variable between breeds and between individuals in different situations.
      Barks are used in short range interactions generally in several contexts:
    </Text>
    <ListItem>Greeting </ListItem>
    <ListItem>Warning and alerting</ListItem>
    <ListItem>Attention seeking</ListItem>
    <ListItem>During play</ListItem>
    <Text style={styles.text}>
      Barking is an allelomimetic behaviour: a behaviour that increases the
      chances of a nearby animal also exhibiting that behaviour. Dogs barking
      stimulates and mirrors other dogs barking.
    </Text>
    <Text style={styles.text}>
      Humans have bred some dogs to have a higher propensity to bark than
      others. For example hunting dogs or guarding dogs.
    </Text>
    <Text style={styles.text}>
      Dogs can differentiate between barks produced by different individuals in
      the same context. A bark can help the receiver hear the size of the animal
      and familiarity.
    </Text>
    <Text style={styles.text}>
      Dogs can also differentiate between barks produced in different contexts.
      The acoustic range of a bark varies predictably according to the context.
    </Text>
    <Text style={styles.text}>
      In dogs, as in humans, vocalisations with low frequencies indicate
      agonistic tendencies as a result of stress. For example, when a stranger
      approaches a dog their bark is likely to be low tonal and lower pitched.
      This is the same for growls. Dogs growling in play and dogs growling as a
      warning have specific acoustics structures. Low pitched growls are a
      threat or warning signal. High pitched and short growls are typically
      produced during play with humans. Tonal high pitched barks are considered
      to be happier.{' '}
    </Text>
    <Text style={styles.text}>Other vocalisations used by dogs include:</Text>
    <ListItem>Whining - greeting, attention-seeking and stress.</ListItem>
    <ListItem>Howls – isolation signals</ListItem>
    <ListItem>Yelps – distress and/or pain signals</ListItem>
    <ListItem>
      Grunts – pleasure related signals e.g. a dog settling down to sleep{' '}
    </ListItem>
    <Text style={styles.subtitleBold}>Olfactory Communication:</Text>
    <Text style={styles.text}>
      When we consider olfactory communication, humans generally do not rely on
      this at all for conveying information to each other. Dogs on the other
      hand do; their high olfactory sensitivity allows them access to social and
      contextual information through their sense of smell. Dogs directly
      interact with each other through close olfactory inspection, particularly
      during greeting.
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 16</Text>
      <Image
        style={{ ...styles.image, width: '200px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(64).jpg?alt=media"
      />
    </div>
    <Text style={styles.text}>
      Dogs will show interest for specific parts of both conspecifics and the
      human body for olfactory investigation. In other dogs, dogs will sniff the
      face, neck, inguinal and peri-anal areas due to the specific glands
      located in those areas, for example anal glands.
    </Text>
    <Text style={styles.text}>
      Scent marking is one way dogs communicate in a highly efficient way due to
      the persistence of odours in an environment. Dogs can gather information
      about other dogs without being in close proximity or having any direct
      interaction with. They can discriminate between conspecifics based on
      their odour.
    </Text>
    <Text style={styles.text}>
      Dogs can also recognise humans by their odour also. It was found by Berns
      et al. (2015) via a functional resonance imaging study, that familiar
      human odours activate the caudate nucleus in a dogs brain. This part of
      the brain is associated with positive expectations and reward. This study
      can be found in your supplementary reading materials.
    </Text>
    <Text style={styles.text}>
      Finally, dogs have been found to be able to detect emotional content of
      human odours (chemosignals). Sniffing of human odours collected in fearful
      situations causes a dog&apos;s cardiac activity and behaviour choice to
      change. Dogs are more likely to approach a stranger after being presented
      with odours from happy situations. On the other hand, they are more likely
      to approach their owners when presented with odours from fearful
      situations, along with showing more stressful behaviours.
    </Text>

    <Text style={styles.subtitleBold} id="assessing-body-lang">
      Assessing Body Language:
    </Text>
    <Text style={styles.text}>
      Dogs can be described as mindful animals. Whilst associations made in the
      past can influence how they feel, what they do/feel at any moment is
      subject to change as each moment passes. Therefore, how a dog feels when
      there is one person standing next to them can be remarkably different to
      how a dog feels when there is a crowd of people standing around them.
    </Text>
    <Text style={styles.text}>
      Anyone who works with dogs under any capacity needs to be aware of this.
      Whilst it may seem obvious, it is equally easy to assume that a dog
      confident in one situation will be confident in another. Or a dog who was
      once confident in one situation, will always feel confident in it. This is
      not the case. Dogs are complex in their reactions and can be influenced by
      external or internal factors at any given moment. It is paramount to be
      aware of this when observing the dog before you in a training session, and
      of course, outside of it.
    </Text>
    <Text style={styles.text}>Assessing body language is important for:</Text>
    <ListItem>Assessing the suitability of a dog for assistance work</ListItem>
    <ListItem>
      Preparing the dog for assistance work e.g. in socialisation
    </ListItem>
    <ListItem>Training the dog for assistance work</ListItem>
    <ListItem>
      Monitoring the dog as he moves through the assistance work career
    </ListItem>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 17</Text>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(65).jpg?alt=media"
      />
    </div>
    <Text style={styles.subtitleBold} id="comms-barriers">
      Communication Barriers:
    </Text>
    <Text style={styles.text}>
      Unfortunately, the human manipulation of certain features through
      breeding, in particular, for cosmetic purposes (e.g. tail docking), has
      hampered some dogs&apos; ability to communicate and be understood by
      others of their own species, and our own.{' '}
    </Text>
    <ListItem>
      Think of a dog with a docked tail trying to communicate fear through a
      tail tuck. Sadly although the practice of docking tails is illegal in the
      UK some owners will seek to have this unnecessary surgery performed. Some
      dogs have been bred to have naturally very small stumpy tails (see Figure
      19)
    </ListItem>
    <ListItem>
      Think of a breed with extremely long dense fur (e.g. chow chow) trying to
      communicate through body posture.
    </ListItem>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 18</Text>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(66).jpg?alt=media"
      />
    </div>
    <ListItem>
      Think of a brachycephalic dog with excessively wrinkled skin trying to
      communicate with facial expressions. (see Figure 18)
    </ListItem>
    <div style={{ textAlign: 'center' }}>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(67).jpg?alt=media"
      />
      <Text style={{ ...styles.subheading }}>Figure 19</Text>
    </div>
    <Text style={styles.text}>
      Unfortunately for these dogs if their earlier social signs and warning
      signs are not read their behaviour is likely to escalate. Much of canine
      body language and social signals is subtle – these physical barriers
      heavily impede communication. This is why it is important to take a
      dog&apos;s body language as a whole and not just focus on one area.
    </Text>
    <Text style={styles.text}>
      Aside from this, a dogs’ biggest communication barrier with humans is our
      inability to read what they are saying through their non-verbal language.
      In our human society, cultural norms define what our specific nonverbal
      communication means. For example, in the UK it is considered polite to
      maintain a degree of eye contact when communicating to superiors. In many
      Asian cultures however, this eye contact would be seen as disrespectful.
      Dogs have an entire language of their own but a significant number of
      humans, even as dog owners and trainers, are mostly unaware of the
      subtleties.
    </Text>
    <Text style={styles.text}>
      Training of assistance dogs is not just reliant on effective operant
      conditioning and a motivated state within the dog. It also requires
      effective reading of a dogs’ emotional state. This is especially important
      when performing classical conditioning which is about building
      associations with previously unknown stimuli (e.g. a harness), or
      counter-conditioning and desensitisation, which is typically about
      changing a dog&apos;s reaction to these stimuli from negative to positive
      through gradual re-exposure.
    </Text>
    <Text style={styles.text}>
      For the safety of a dog&apos;s neck and underlying organs and nerves, a
      dog must ideally be wearing a body harness when working. PADs Foundation
      recommends that a harness is used not only for health and safety reasons
      but to assist with the dog learning when it is in “work mode”.{' '}
    </Text>
    <Text style={styles.text}>
      Unfortunately, a lot of dogs can struggle with the act of having a harness
      put on – a reason for an assistance dog to “fail”. Time must be spent
      ensuring the dog is comfortable with everything about the harness, from it
      being put on, to the sounds of the buckles clipping, to wearing one for a
      length of time, to having it taken off. This can only be done successfully
      when the trainer/handler can correctly read relaxed body language and
      stress signals. Rushing the counter-conditioning and desensitisation and
      moving a dog onto the next step too quickly may simply sensitise the dog
      to the harness e.g making the dogs behaviour worse.
    </Text>
    <Text style={styles.underlineHeading} id="understanding-body-lang">
      Understanding the Body Language of Dogs:
    </Text>
    <Text style={styles.text}>
      The way humans interact with dogs can have an overarching affect on their
      wellbeing. Studies have shown that dogs are tuned into human visual
      communications. They also prefer to rely on hand signals rather than
      auditory cues, although we recommend that you train behaviours on both.
    </Text>
    <Text style={styles.text}>
      Of paramount importance, dogs may not be comfortable with the level of
      touch/contact that many humans naturally offer. Many of these situations
      are not natural to them:
    </Text>
    <ListItem>Bending over a dog and putting face in theirs</ListItem>
    <ListItem>Hugging the dog </ListItem>
    <ListItem>Holding the dogs face and kissing them </ListItem>
    <ListItem>Patting a dog on the head</ListItem>
    <ListItem>Sustained eye contact</ListItem>
    <Text style={styles.text}>
      Working with dogs in a training setting is not just about reading their
      body language but amending your own to allow the dog to feel comfortable
      and at ease in your presence. A dog handler&apos;s body language must be
      calm, clear and consistent. A human’s own posture and movements towards
      the dog is important to monitor. Erratic, intimidating or inconsistent
      body language is stressful for the dog.
    </Text>
    <Text style={styles.text}>
      Patricia McConnell writes that the messages a human intends to send are
      very different from the messages that dogs can receive. Our languages are
      different.
    </Text>
    <Text style={styles.subtitleBold} id="eye-contact">
      Eye Contact:
    </Text>
    <Text style={styles.text}>
      We may naturally want to offer a dog eye contact as in our culture eye
      contact is desired and denotes respect and even love. Canine body language
      however sees eye contact as a threat, looking away is a sign of respect.
      When approaching a dog it is important to do so indirectly in a curve. It
      is also respectful and safer to approach without direct eye contact. The
      softest of short moments of eye contact can start to be offered whilst
      keeping an eye on the dog&apos;s own body language to ascertain how they
      feel about this.{' '}
    </Text>
    <Text style={styles.text}>
      This is not to say dogs cannot learn that eye contact with humans is
      valuable and comfortable; it is just important to remember what a dogs’
      natural instincts are in comparison to our own.
    </Text>
    <Text style={styles.text}>
      When acclimating dogs to eye contact however, considering how we do this
      is necessary. Sitting directly opposite a dog is unlikely to help a dog
      feel at ease. Furthermore, using a treat to lure the dog into looking is
      likely to cause conflict within the dog.{' '}
    </Text>
    <Text style={styles.text}>
      Instead consider shaping a dog to offer eye contact and give rewards for
      choosing to do so. You can sit or stand next to your dog, instead of
      opposite, and ask for its attention (say its name). When the dog gives you
      eye contact you can reward. You can gradually increase the time the dog
      holds their eye contact by delaying the bridge and reinforcement a little
      bit at a time without the dog breaking eye contact. Ensure you have a soft
      gaze and not a hard stare. You can eventually put this behaviour on a hand
      and “look” signal.
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 20</Text>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(68).jpg?alt=media"
      />
    </div>
    <Text style={styles.text}>
      Dogs can show a spontaneous tendency to gaze at human faces and to make
      eye contact to ask for help for example with unsolvable tasks. This truly
      shows that through the domestication process dogs have modified eye
      contact and its meaning to adapt to cross-species communication.
    </Text>
    <Text style={styles.text}>
      Further to this, dogs have been studied to evaluate human gaze and assess
      attentional state through a human’s eye direction. They have also been
      found to follow eye gaze in order to solve a problem task such as finding
      food. Conversely, dogs can communicate to humans using their eyes, showing
      interest in a specific object by alternate gazing between the object and
      the human.
    </Text>
    <Text style={styles.subtitleBold}>Facial Expressions:</Text>
    <Text style={styles.text}>
      Domestic dogs show a functional understanding of the facial expressions of
      humans. They can discriminate between emotional expressions, responding
      differently to each one.{' '}
    </Text>
    <Text style={styles.text}>
      For example, Muller et al. (2015) found that dogs seemed to recognise an
      angry face as aversive. This could be due to domestication, or due to the
      intimate relationships between humans and dogs helping dogs to form
      memories of human emotional expressions in a global manner.
    </Text>
    <Text style={styles.text}>
      Dogs have been found to respond with lip-licking, a stress indicator, more
      often when presented with facial expressions from humans that are
      negative, compared to positive. This lip licking may mean not only are
      their stress levels increased through witnessing the negative facial
      expression, but they may be displaying a behaviour known as an appeasement
      signal towards the human.
    </Text>
    <Text style={styles.text}>
      Dogs have been found to be biassed to look towards the left side of the
      face when observing human faces expressing anger, fear or happiness. They
      were found to be biassed towards the right side when a human is expressing
      surprise.
    </Text>
    <Text style={styles.text}>
      Further to this study by Siniscalchi et al. (2018) it was also found that
      dogs displayed higher behavioural and cardiac activity to human faces
      (pictures) that were expressing an aroused emotional state.
    </Text>
    <Text style={styles.text}>
      What does this mean for our interactions with dogs? Do dogs require us to
      be mindful of our facial expressions when engaging with them? In
      particular, if we want a dog to be calm around us, how can we ensure our
      own face is expressing calm?{' '}
    </Text>
    <Text style={styles.subtitleBold}>Physical Contact:</Text>
    <Text style={styles.text}>
      As a society we can be fixed on greeting another through some form of
      touch – a hand shake for example. As a result, many humans can
      unthinkingly reach out to a dog within seconds of being in their company.
      This is likely due to the benefits to a human&apos;s physiology as a
      result of this tactile contact: decrease in blood pressure and heart rate.
      However, this can be overwhelming for a dog and an unwanted behaviour can
      easily arise as a result e.g. jumping up.
    </Text>
    <Text style={styles.text}>
      Consider when two dogs meet they may circle and sniff each other, but any
      further contact is done by invitation. A play bow to invite play is
      unlikely to jump straight into contact play either. In fact, aside from
      play, most tactile communication between dogs is used during agonistic
      interactions for example a dog putting their paws over the back of
      another. Therefore, it can be understood why immediately reaching out to a
      dog&apos;s back can cause concern and stress.
    </Text>
    <Text style={styles.text}>
      It is best to kneel down and keep your hands close to your body to
      communicate with a dog that you are friendly. Putting your hand out for
      them to sniff is intruding on a dog’s personal space. The dog will come to
      you and sniff around when s/he is comfortable.
    </Text>
    <Text style={styles.text}>
      As well as being more likely to initiate physical contact with dogs,
      humans are more likely to maintain this contact for a longer period of
      time than dogs are instinctively comfortable with. This can cause dogs to
      feel unrelaxed in these interactions, displaying withdrawal responses or
      ambivalent signals such as blinking, looking elsewhere, closing eyes or
      turning their head away.{' '}
    </Text>
    <Text style={styles.text}>
      A dog will communicate to you if they do want to be petted. They may lean
      into you or nuzzle at your hand. Dog friendly spots to stroke tend to be
      their shoulders and sides (not the head); however, it is important to
      watch the dog’s reaction and if they show signs of stress, pull away.
    </Text>
    <Text style={styles.text}>
      It is wise to adopt the “3 second consent rule” when engaging with a dog,
      especially one who you may be new to. Petting a dog for three seconds,
      then pulling your hand away and waiting to see if the dog requests you to
      pet further will give the dog a chance to move away or simply say “no
      thanks”
    </Text>
    <Text style={styles.text}>
      Dogs rarely enjoy hugs. Consider the image below (see figure 21)
    </Text>
    <ListItem>
      What body language can you spot within the dog&apos;s face? Can you see
      the ears are back? Can you see the tongue flick? Can you see the whale
      eye?
    </ListItem>
    <ListItem>Can you see the furrowed brow?</ListItem>
    <ListItem>
      What does this tell you about how the dog might be feeling with this
      interaction?
    </ListItem>
    <Text style={styles.text}>
      Dogs can be very often seen ducking away from unwanted physical
      interactions such as hugs. They may also be looking away, leaning away,
      looking tense and showing other stress signals.
    </Text>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 21</Text>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(69).jpg?alt=media"
      />
    </div>
    <Text style={styles.subtitleBold}>Body Positioning:</Text>
    <Text style={styles.text}>
      Bending over a dog or hovering over them can be offensive to dogs and
      worry them.{' '}
    </Text>
    <Text style={styles.text}>
      Instead, kneeling pointed sideways away from the dog whilst minimising eye
      contact is polite. For dogs who are expressing marked anxiety or fear even
      standing sideways may be necessary whilst you give the dog a chance to air
      sniff and gather information about you from a distance.
    </Text>
    <Text style={styles.text}>
      We need to consider our body orientation when working with dogs, not just
      greeting them. How should we hold ourselves when putting on a dogs
      harness? Can we communicate to the dog what we would like from them using
      our body orientation alone? Is where we stand relevant? How we stand?
      Which direction our body is facing?
    </Text>
    <Text style={styles.subtitleBold}>Movement:</Text>
    <Text style={styles.text}>
      Dogs are sensitive to movement. When around dogs we can help to keep them
      calm and feeling safe by moving slowly and calmly ourselves. Fast, sudden
      and erratic movements can worry a dog, especially those who are in any way
      anxious. Walking into a room with a dog and gesticulating wildly, pacing
      and generally being animated can stress out a dog causing them to resort
      to unwanted behaviours themselves such as jumping up, or being nervous,
      generally feeling wary around you.{' '}
    </Text>
    <Text style={styles.text}>
      As mentioned above, dogs can find direct approaches to anxiety provoking.
      Walking in a curve towards a dog is preferable, anxious dogs needing wider
      curves than others.
    </Text>
    <Text style={styles.subtitleBold}>Tone:</Text>
    <Text style={styles.text}>
      Although not strictly body language it is important to keep in mind also
      that verbal communication is more than just what words are communicated
      towards a dog. It is also HOW these cues are expressed. Further, gestures
      are about more than just an extension of a body part, but how they are
      used is important also.
    </Text>
    <Text style={styles.text}>
      A study by Pettersson et al. (2011) found that tone of voice is important:
      a co-operative voice was listened to, whereas a low- pitched prohibiting
      voice was less so. Further, a co-operative pointing gesture was listened
      to more than a prohibitive one e.g. using a flat palm.
    </Text>
    <Text style={styles.text}>
      This shows that in communicating with dogs how we speak to them, and how
      we gesture can also have an effect on how they see us.
    </Text>
    <Text style={styles.subtitleBold} id="human-language">
      The Importance of Human Body Language in Assistance Dog Training
    </Text>
    <Text style={styles.text}>
      The Importance of Human Body Language in Assistance Dog Training Nearly
      all dogs’ well-being is entwined with that of humans; particularly in the
      pet dog or working dog community. These dogs are dependent on humans to
      get their needs met:
    </Text>
    <ListItem>Biological needs such as nutrition, water and sleep </ListItem>
    <ListItem>Emotional needs such as love, trust and security</ListItem>
    <ListItem>Social needs such as bonding</ListItem>
    <ListItem>Cognitive needs such as choice and problem solving</ListItem>
    <div style={{ textAlign: 'center' }}>
      <Text style={{ ...styles.subheading, margin: '0 auto' }}>Figure 22</Text>
      <Image
        style={{ ...styles.image, width: '300px', margin: '0 auto' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(70).jpg?alt=media"
      />
    </div>
    <Text style={styles.text}>
      Therefore, when working with dogs in an assistance dog role the focus must
      also be on the human end of the lead. The way humans interact with the dog
      affects the way the dog consequently feels and behaves. Furthermore, the
      way an assistance dog trainer interacts with their client (you) in need of
      an assistance dog is of paramount importance.
    </Text>
    <Text style={styles.text}>
      Imagine if a professional went into your home to discuss your needs, and
      the needs of the canine but was:
    </Text>
    <ListItem>Uninterested in hearing what you had to say</ListItem>
    <ListItem>Harsh with the dog</ListItem>
    <ListItem>Treating you in a condescending manner </ListItem>
    <ListItem>
      Following their own agenda, using complicated jargon with unrealistic
      expectations
    </ListItem>
    <Text style={styles.text}>
      This is why it is important to find a trainer that you can work well with
      that understands your needs. Please ensure you have a qualified trainer to
      help you through the training process. While you are an In Training Member
      it is a requirement that you have a minimum of one training session per
      month with your trainer. Information on how to find a suitable trainer is
      available on our website.{' '}
    </Text>
    <Text style={styles.text}>
      Please remember that your dog trainer is just that, a dog trainer and they
      are NOT qualified to provide you with any medical or emotional support.
      Please ensure you reach out to your Dr, Psychiatrist or Mental Health care
      provider as and when needed.
    </Text>
    <Text style={styles.text}>
      Assistance dog training is a partnership between the handler and the dog,
      formed through a partnership with a qualified trainer.
    </Text>
    <Text style={styles.subtitleBold}>Dogs Welfare Needs:</Text>
    <Text style={styles.text}>
      The dog&apos;s health and welfare are to be at the core of everything we
      do. From their pre-training, training, graduation, to the remainder of
      their lives working and retired, the humans around them will have an
      impact on how they feel and behave.
    </Text>
    <Text style={styles.text}>
      The human partner-dog duo is based on mutual respect and needs being met.
      It is necessary to ensure you are informed on how your own body language
      will influence your dog. A few simple pointers can go a long way:
    </Text>
    <ListItem>
      When putting on the dog&apos;s harness, as much as possible, work from the
      side of the dog in a seated position i.e. not looming over.
    </ListItem>
    <ListItem>
      When praising the dog with touch, avoid the top of the head.
    </ListItem>
    <ListItem>
      Keep in mind the consent game when seeking out physical affection from the
      dog.{' '}
    </ListItem>
    <Text style={styles.text}>
      These minor adjustments can go a long way in respecting the assistance
      dog’s emotional life going forwards. We discuss dog welfare needs in more
      detail in Chapter 3 of this Training Guide.
    </Text>

    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

BehaviourAndBodyLanguage.propTypes = {
  styles: PropTypes.object,
}

export default BehaviourAndBodyLanguage

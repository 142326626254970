/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useReducer } from 'react'
import { PropTypes } from 'prop-types'
import ApplicationReducer from './ApplicationReducer'
import { getApplication } from '../../data/application'

const initialContextValue = {
  application: {},
  isLoading: false,
  errorMessage: '',
  fetchApplication: () => Promise.resolve(),
}

export const ApplicationContext = createContext(initialContextValue)

const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ApplicationReducer, initialContextValue)

  const fetchApplication = async (userId, db) => {
    try {
      dispatch({ type: 'FETCH_DATA' })
      const data = await getApplication(userId, db)
      dispatch({ type: 'APPLICATION_DATA_SUCCESS', payload: data })
    } catch (e) {
      dispatch({ type: 'APPLICATION_DATA_ERROR', payload: e.message })
    }
  }

  const contextValue = {
    ...state,
    fetchApplication,
  }

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  )
}

ApplicationProvider.propTypes = {
  children: PropTypes.node,
}

export default ApplicationProvider

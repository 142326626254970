import React, { useState, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'

import { useAuth } from '../contexts/AuthContext'
import logo from '../images/pads-logo-250.png'
import { useNavigate } from 'react-router-dom'

const VerifyEmail = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { sendVerificationEmail, currentUser } = useAuth()

  useEffect(() => {
    if (currentUser.emailVerified) navigate('/application')
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await sendVerificationEmail()
    } catch (error) {
      setLoading(false)
      console.error(error)
      const errorMessage = 'Errpr re-sending verification email'
      setErrorMessage(errorMessage)
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" />
        <Box>
          <Typography variant="body1" mt={5} mb={3} align="center">
            <strong>
              Nearly there! We sent an email to {currentUser.email}
            </strong>
          </Typography>
          <Typography variant="body1" mb={3} align="center">
            Please click on the link in the email to complete you signup. If you
            don&apos;t see it, you may need to check your spam folder
          </Typography>
          <Typography variant="body1" mt={5} mb={2} align="center">
            Still no email?
          </Typography>
          <form
            onSubmit={onSubmit}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {!!errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mb: 2, maxWidth: '300px' }}
            >
              Resend email verification
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </Container>
  )
}

export default VerifyEmail

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Countdown from 'react-countdown'
import { useAuth } from '../contexts/AuthContext'

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

const AutoLogout = ({ children }) => {
  const [open, setOpen] = useState(false)
  const { logOut } = useAuth()

  const handleClose = () => {
    resetTimer()
    setOpen(false)
  }

  const handleLogout = () => {
    localStorage.clear()
    logOut()
  }

  let timer

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer()
        handleTimer()
      })
    })
  }, [])

  const resetTimer = () => {
    if (timer) clearTimeout(timer)
  }

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer()
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer)
      })
      logoutAction()
    }, 300000)
  }

  const logoutAction = () => {
    setOpen(true)
  }

  const showCountdown = ({ seconds, completed }) => {
    if (completed) {
      localStorage.clear()
      logOut()
    } else {
      // Render a countdown
      return <span>{seconds}</span>
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Session Timeout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are being timed out due to inactivity. Please choose to stay
            logged in otherwise you will be logged out automatically
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Log out</Button>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Stay logged in (
            <Countdown date={Date.now() + 60000} renderer={showCountdown} />)
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  )
}

export default AutoLogout

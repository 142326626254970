import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { addActivity } from '../../data/training-log'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  TextField,
  FormControl,
  Slider,
  Typography,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'

const AddActivity = (props) => {
  const { currentUser, db } = useAuth()

  const {
    selectedDay,
    setOpenSnackbar,
    setSnackbarMsg,
    refetchData,
    currentYear,
  } = props
  const uid = currentUser.uid

  // Set query date for updating database
  selectedDay.year = new Date().getFullYear()
  const monthValue = selectedDay.month
  const zeroMonthValue = monthValue.toString().padStart(2, '0')
  const zeroDayValue = selectedDay.day.toString().padStart(2, '0')
  const queryDate = `${currentYear()}-${zeroMonthValue}-${zeroDayValue}`

  // Set default activity object
  const defaultActivity = {
    name: '',
    duration: 5,
    date: queryDate,
    id: uuidv4(),
    trainer: false,
  }

  const [activity, setActivity] = useState(defaultActivity)

  const handleChange = (e, checkbox) => {
    const { name, value, checked } = e.target
    const updatedValue = checkbox ? checked : value
    setActivity({
      ...activity,
      date: queryDate,
      [name]: updatedValue,
    })
  }

  const handleSlider = (e) => {
    const duration = e.target.value
    setActivity({ ...activity, duration: duration })
  }

  const isValid = activity.name === ''

  // Add the activity to firebase via the API made in this app
  const handleSubmit = async () => {
    if (currentUser) {
      await addActivity(activity, uid, db)
      setActivity(defaultActivity)

      // Show notification
      setOpenSnackbar(true)
      setSnackbarMsg('Added activity')
      refetchData()
    }
  }

  return (
    <form noValidate onSubmit={(e) => e.preventDefault()}>
      <FormControl>
        <TextField
          style={{ marginTop: '5px' }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Behaviour"
          value={activity.name}
          name="name"
          onChange={handleChange}
        />
        <Typography id="discrete-slider" gutterBottom>
          Duration (mins)
        </Typography>
        <Slider
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={5}
          marks
          min={5}
          max={90}
          name="duration"
          value={activity.duration || 5}
          onChange={handleSlider}
          style={{ marginBottom: '10px' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activity.trainer}
              name="trainer"
              onChange={(e) => {
                handleChange(e, true)
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: '15px' }}>
              Completed with trainer
            </Typography>
          }
        />
        <FormHelperText sx={{ m: 0, mb: 3 }}>
          Please check this box if the activity was completed with your
          registered trainer
        </FormHelperText>
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isValid}
      >
        Add activity
      </Button>
    </form>
  )
}

export default AddActivity

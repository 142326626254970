import React from 'react'
import dayjs from 'dayjs'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const CalendarHeader = (props) => {
  const {
    allMonths,
    setMonth,
    setYear,
    toggleMonthSelect,
    currentMonth,
    currentYear,
    showMonthTable,
    showYearTable,
    toggleYearSelect,
  } = props

  const months = []

  allMonths.map((month) =>
    months.push(
      <TableCell
        colSpan="2"
        className="month-cell"
        style={{ textAlign: 'center' }}
        key={month}
        onClick={(e) => setMonth(month)}
      >
        <span>{month}</span>
      </TableCell>,
    ),
  )

  const rows = []
  let cells = []

  months.forEach((month, i) => {
    if (i % 4 !== 0 || i === 0) {
      cells.push(month)
    } else {
      rows.push(cells)
      cells = []
      cells.push(month)
    }
  })
  rows.push(cells)

  const monthList = rows.map((row, i) => <TableRow key={i}>{row}</TableRow>)

  return (
    <TableContainer component={Paper} className="month-selector">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              className="toggle-month"
              colSpan="4"
              onClick={() => toggleMonthSelect()}
            >
              {currentMonth()}
              <ArrowDropDownIcon className="arrow-icon" />
            </TableCell>
            <TableCell colSpan="4" onClick={() => toggleYearSelect()}>
              {currentYear()}
              <ArrowDropDownIcon className="arrow-icon" />
            </TableCell>
          </TableRow>
        </TableHead>
        {showMonthTable ? (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan="8"
                style={{ textAlign: 'center' }}
                className="select-month-title"
              >
                Select a month
              </TableCell>
            </TableRow>
            {monthList}
          </TableBody>
        ) : null}
        {showYearTable ? (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan="8"
                style={{ textAlign: 'center' }}
                className="select-month-title"
              >
                Select a year
              </TableCell>
            </TableRow>
            <TableCell
              colSpan="2"
              className="month-cell"
              style={{ textAlign: 'center' }}
              key={dayjs().subtract(3, 'year').format('YYYY')}
              onClick={(e) =>
                setYear(dayjs().subtract(3, 'year').format('YYYY'))
              }
            >
              <span>{dayjs().subtract(3, 'year').format('YYYY')}</span>
            </TableCell>
            <TableCell
              colSpan="2"
              className="month-cell"
              style={{ textAlign: 'center' }}
              key={dayjs().subtract(2, 'year').format('YYYY')}
              onClick={(e) =>
                setYear(dayjs().subtract(2, 'year').format('YYYY'))
              }
            >
              <span>{dayjs().subtract(2, 'year').format('YYYY')}</span>
            </TableCell>
            <TableCell
              colSpan="2"
              className="month-cell"
              style={{ textAlign: 'center' }}
              key={dayjs().subtract(1, 'year').format('YYYY')}
              onClick={(e) =>
                setYear(dayjs().subtract(1, 'year').format('YYYY'))
              }
            >
              <span>{dayjs().subtract(1, 'year').format('YYYY')}</span>
            </TableCell>
            <TableCell
              colSpan="2"
              className="month-cell"
              style={{ textAlign: 'center' }}
              key={dayjs().format('YYYY')}
              onClick={(e) => setYear(dayjs().format('YYYY'))}
            >
              <span>{dayjs().format('YYYY')}</span>
            </TableCell>
          </TableBody>
        ) : null}
      </Table>
    </TableContainer>
  )
}

export default CalendarHeader

import React, { useState, useEffect } from 'react'
import {
  Alert,
  Container,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import VisibilityIcon from '@mui/icons-material/Visibility'
import LinearProgress from '@mui/material/LinearProgress'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import { updateUser } from '../data/user'
import { sendApprovedEmail } from '../data/email'
import { listApplications, updateApplication } from '../data/application'
import { getUser } from '../data/user'

const ApplicationTable = () => {
  const { currentUser, db, functions } = useAuth()
  const [approving, setApproving] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [loadingList, setLoadingList] = useState(true)
  const navigate = useNavigate()
  const [applicationList, setApplicationList] = useState([])

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    getApplicationList()
  }, [])

  const approveApplication = async (userId) => {
    setApproving(true)
    const update = await updateUser({ role: 'member' }, userId, db)
    const updateApp = await updateApplication(
      { status: 'Complete' },
      userId,
      db,
    )
    if (update && updateApp) {
      const selectedUser = await getUser(userId, db)
      const emailSent = await sendApprovedEmail(functions, selectedUser)
      setApproving(false)
      if (emailSent) {
        setAlert({
          open: true,
          message: 'Application approved! Email sent to applicant',
          type: 'success',
        })
      }
      getApplicationList()
    }

    if (!update) {
      setApproving(false)
      setAlert({
        open: true,
        message: `Error approving application, please try again`,
        type: 'error',
      })
    }
  }

  const reopenApplication = async (userId) => {
    setUpdating(true)
    await updateUser({ submittedApplication: false }, userId, db)
    const updateApp = await updateApplication(
      { status: 'In progress' },
      userId,
      db,
    )
    if (updateApp) {
      setUpdating(false)
      setAlert({
        open: true,
        message: 'Application re-opened successfully',
        type: 'success',
      })
      getApplicationList()
    }

    if (!updateApp) {
      setUpdating(false)
      setAlert({
        open: true,
        message: `Error re-opening application, please try again`,
        type: 'error',
      })
    }
  }

  const declineApplication = async (userId) => {
    setUpdating(true)
    await updateUser({ submittedApplication: false, role: 'user' }, userId, db)
    const updateApp = await updateApplication(
      { status: 'In progress' },
      userId,
      db,
    )
    if (updateApp) {
      setUpdating(false)
      setAlert({
        open: true,
        message: 'Application re-opened successfully',
        type: 'success',
      })
      getApplicationList()
    }

    if (!updateApp) {
      setUpdating(false)
      setAlert({
        open: true,
        message: `Error re-opening application, please try again`,
        type: 'error',
      })
    }
  }

  const updateCheckbox = async (userId, field, event) => {
    const updateApp = await updateApplication(
      { [field]: event.target.checked },
      userId,
      db,
    )

    if (updateApp) getApplicationList()

    if (!updateApp) {
      setAlert({
        open: true,
        message: `Error setting checkbox, please try again`,
        type: 'error',
      })
    }
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: 'updatedOn',
      headerName: 'Last Updated',
      flex: 1,
      renderCell: (params) => {
        const date = params.row.updatedOn
        return date
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        const status = params.row.status
        switch (status) {
          case 'In progress':
            return <Typography sx={{ color: '#22a54a' }}>{status}</Typography>
          case 'Complete':
            return <Typography sx={{ color: '#bbb' }}>{status}</Typography>
          case 'Submitted':
            return <Typography sx={{ color: '#7f23e0' }}>{status}</Typography>
          default:
            return status
        }
      },
    },
    {
      field: 'none2',
      width: 90,
      headerName: 'Paused',
      sortable: false,
      renderCell: (params) => {
        const currentRow = params.row
        return (
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!currentRow.paused || false}
                  onChange={(event) =>
                    updateCheckbox(currentRow.id, 'paused', event)
                  }
                />
              }
            />
          </Stack>
        )
      },
    },
    {
      field: 'none',
      width: 200,
      headerName: 'Form Review',
      sortable: false,
      renderCell: (params) => {
        const currentRow = params.row
        return (
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!currentRow.doctorFormReview || false}
                  onChange={(event) =>
                    updateCheckbox(currentRow.id, 'doctorFormReview', event)
                  }
                />
              }
              label="Doctor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!currentRow.vetFormReview || false}
                  onChange={(event) =>
                    updateCheckbox(currentRow.id, 'vetFormReview', event)
                  }
                />
              }
              label="Vet"
            />
          </Stack>
        )
      },
    },
    {
      field: 'action',
      width: 100,
      type: 'actions',
      headerName: 'Action',
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          showInMenu
          onClick={() => navigate(`/application/view/${params.row.id}`)}
          icon={<VisibilityIcon />}
          label="View"
          key={0}
        />,
        <GridActionsCellItem
          onClick={() => approveApplication(params.row.id)}
          icon={<ThumbUpOffAltIcon />}
          label="Approve"
          key={1}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => reopenApplication(params.row.id)}
          icon={<LockOpenIcon />}
          label="Re-open"
          key={2}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => declineApplication(params.row.id)}
          icon={<ThumbDownOffAltIcon />}
          label="Decline"
          key={2}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => navigate(`/application/log/${params.row.id}`)}
          icon={<CalendarMonthIcon />}
          label="View training log"
          key={2}
          showInMenu
        />,
      ],
    },
  ]

  const getApplicationList = async () => {
    const applicationList = await listApplications(db)
    if (applicationList) setApplicationList(applicationList)
    setLoadingList(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px', maxWidth: '100%' }}>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={alert.open}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Manage Applications
          </Typography>
          <Link to="/admin/dashboard">{'< Back to Admin Dashboard'}</Link>

          <Container component="main" sx={{ marginTop: 5, marginBottom: 5 }}>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                checkboxSelection={false}
                loading={loadingList}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                rows={applicationList}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
              />
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ApplicationTable

import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'

const addActivity = async (newActivity, uid, db) => {
  try {
    const docRef = doc(db, 'training-logs', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()

      if (data && data.activities) {
        data.activities.push(newActivity)
      } else {
        data.activities = [{ ...newActivity }]
      }

      await setDoc(docRef, data, { merge: true })
      return data
    } else {
      const data = {}
      data.activities = [{ ...newActivity }]
      await setDoc(docRef, data, { merge: true })
    }
  } catch (error) {
    return false
  }
}

const updateActivity = async (updatedActivity, uid, db) => {
  try {
    const docRef = doc(db, 'training-logs', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()

      const updatedData = data.activities.map((activity) => {
        if (activity.id === updatedActivity.id) return updatedActivity
        return activity
      })
      await updateDoc(docRef, { activities: updatedData })

      return data
    } else {
      console.log('no activites found')
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

const listActivities = async (uid, db, queryDate) => {
  try {
    const docRef = doc(db, 'training-logs', uid)
    const docSnap = await getDoc(docRef)
    const data = docSnap.data()

    if (queryDate) {
      if (data && data.activities) {
        const activities = data.activities.filter(
          (act) => act.date === queryDate,
        )
        return { activities }
      }
    }

    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

const deleteActivity = async (key, uid, db) => {
  try {
    const docRef = doc(db, 'training-logs', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      const updatedData = data.activities.filter((v) => v.id !== key)

      await setDoc(docRef, { activities: updatedData })

      return { activities: updatedData }
    } else {
      console.log('no activites found')
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

export { updateActivity, deleteActivity, addActivity, listActivities }

/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const ManagingCanineBehaviour = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="managing-behaviour" style={styles.title}>
      5. Managing Canine Behaviour
    </Text>
    <Text style={styles.subtitleBold} id="nature-nurture">
      Nature vs Nurture:
    </Text>
    <Text style={styles.text}>
      Canine behaviour is the result of complex interactions between both
      internal and external factors that drive a dog’s response in any
      situation. Internal factors can be identified as aspects of biological
      make-up such as a dog’s genetic inheritance, and biological construction
      (often referred to as Nature). External factors are those influences that
      affect a dog after conception and are often known as environmental factors
      (often referred to as Nurture). The interaction between the two states
      (internal and external influences) has a fundamental effect on canine
      emotions and behaviour and for a full understanding of individual canine
      behaviour, both aspects need to be considered.
    </Text>
    <Text style={styles.text}>
      Behavioural traits of a dog may be the result of either nature or nurture,
      where one aspect may predominate, but the likelihood is that all behaviour
      is a result of interaction between the two. No animal is formed entirely
      from its genetic and biological inheritance, but neither is it entirely a
      product of environmental influences
    </Text>
    <Image
      style={{
        width: '300px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(71).jpg?alt=media"
    />
    <Text style={styles.underlineHeading} id="unwanted-behaviour">
      Understanding the Causes of Unwanted Canine Behaviour
    </Text>
    <Text style={styles.text}>
      Unwanted behaviour is typically those behaviours either an individual, or
      society as a whole, do not want to live with.
    </Text>
    <Text style={styles.italics}>
      Note: unwanted behaviours are not necessarily behaviour problems.
    </Text>
    <Text style={styles.text}>
      It is not a behaviour problem that a dog is digging in the garden. In
      fact, digging is an entirely natural behaviour for a dog. They are not
      expressing any degree of abnormal behaviour in finding an outlet for this.
      However, it is unwanted from the{' '}
      <Text style={styles.textBold}>human</Text> perspective.
    </Text>
    <Text style={styles.text}>
      All behaviours serve a function. For this reason, from a dog&aposs
      perspective, no behaviour is <Text style={styles.textBold}>unwanted</Text>
      . If a dog barks at another dog due to anxiety, the barking is to keep the
      other dog away, and they have probably learnt over time that it works.
      Therefore, when considering “unwanted behaviours” we need to keep in mind
      that they’re often unwanted for the{' '}
      <Text style={styles.textBold}>human</Text>, but not the dog.{' '}
    </Text>
    <Text style={styles.subtitleBold} id="causes">
      Causes:
    </Text>
    <Text style={styles.text}>
      Causes of unwanted behaviour can include, but not limited to:
    </Text>
    <ListItem>
      <Text style={styles.textBold}>
        Lack of socialisation during puppyhood
      </Text>{' '}
      causes dogs to become fearful of certain stimuli that can lead to
      aggression or other unwanted behaviours. Negative experiences in
      socialisation can also have a lasting effect. It is important to not
      overwhelm the puppy and
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Boredom</Text> can occur in an environment
      which lacks mental stimulation. This can often lead to destructive or
      compulsive behaviours.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Excess energy</Text> through a lack of
      appropriate physical and mental activity. Or, as we will see later, TOO
      MUCH physical exercise and not enough opportunities to rest.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Human handler behaviour</Text> can
      exacerbate unwanted behaviours through giving attention at the wrong time,
      reinforcing the behaviours. Dog behaviour varies as a result of handlers
      behaviour. Patience of a handler/owner is positively associated with
      obedience. Involvement in play is also positively correlated. The
      attentional state of the handler affects dog behaviour.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Unrealistic handler expectations</Text> for
      example, being asked to cope with situations that the dog is not prepared
      for.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Breed specific traits</Text> that may be
      incompatible with the lifestyle that the dog is asked to adopt in a home
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Bad breeding practices</Text> leading to
      dogs with poor genetics and predispositions to certain difficulties.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Diet</Text> can influence a dog’s behaviour.
      Some commercial dog foods have extremely poor ingredients, even sugar.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Inadequate or incorrect training</Text>{' '}
      during the dog’s life for example, using punishment to suppress a
      fear-based behaviour difficulty.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>Physical health problems</Text> can
      contribute to the way a dog feels and therefore the way they behave. For
      example, dogs in pain may be less tolerant and comfortable in their
      environment due to limited impulse control and irritability.
    </ListItem>
    <Text style={styles.text}>
      When an unwanted behaviour presents itself, especially if a sudden change
      in behaviour or sharp contrast to previous behaviour choices within the
      dog, a vet check must be first done to ensure a physical disorder is not
      the cause.
    </Text>
    <Text style={styles.text}>
      Physical disorders that can influence behaviour include:
    </Text>
    <ListItem>Arthritis </ListItem>
    <ListItem>Otitis externa </ListItem>
    <ListItem>Thyroid disease</ListItem>
    <ListItem>Dental disease</ListItem>
    <ListItem>Physical trauma</ListItem>
    <ListItem>Allergies</ListItem>
    <ListItem>Coronary heart disease </ListItem>
    <ListItem>Neurological issues e.g. epilepsy </ListItem>
    <ListItem>Sense deficiencies e.g. hearing/sight loss</ListItem>
    <Text style={styles.text}>
      Furthermore, a dog in pain may growl, snap or lunge either when handled or
      when she anticipates being handled as a way of avoiding expected pain.
      Although this type of reaction may be temporary and entirely related to
      physical stressors, once the condition has been alleviated it is possible
      that the behaviour may have become learned and persistent. Dogs who have
      experienced long term physical sensitivity may continue to feel uneasy
      about being handled in certain areas and may continue to use distance
      increasing behaviours when approached.
    </Text>
    <Text style={styles.text}>
      When problem solving and looking into why an unwanted behaviour may be
      apparent, it is important to consider all the factors above. A holistic
      view of that individual dogs’ life is paramount.
    </Text>
    <Text style={styles.subtitleBold}>A Dog&apos;s Basic requirements:</Text>
    <Text style={styles.text}>
      Dogs have basic needs that need to be met in order to ensure unwanted
      behaviour does not develop. These basic needs are:{' '}
    </Text>
    <ListItem>Food and water </ListItem>
    <ListItem>Shelter </ListItem>
    <ListItem>Able to express natural behaviour </ListItem>
    <ListItem>Kept from feeling pain, injuries and disease</ListItem>
    <ListItem>Kept from distressing situations and fear </ListItem>
    <Image
      style={{
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(72).jpg?alt=media"
    />
    <Text style={styles.text}>
      Throughout a dog’s life it is the responsibility of humans to ensure these
      needs are being met.
    </Text>
    <Text style={styles.subtitleBold} id="undesirable-behaviour">
      Undesirable Behaviour in Assistance Dogs:
    </Text>
    <Text style={styles.text}>
      Craigon et al. (2017) undertook interviews with owners of guide dogs to
      ascertain what traits of their assistance dogs they found helpful and what
      traits were unhelpful.{' '}
    </Text>
    <Text style={styles.subtitleBold} id="distract">
      Distractibility{' '}
    </Text>
    <Text style={styles.text}>
      Easily distractible was an undesirable trait, for example, having to
      repeat a signal or request more than once. In the study, distractions
      identified included food, scents, other animals, other dogs and people.
      Ensuring the assistance dogs are trained heavily around places of high
      distractions will help with this level of attentiveness and obedience.
      Some guide dog owners wanted a high level of obedience both when the dog
      was working, and when the dog was not. Unpredictability in attentiveness
      caused guide dog owners anxiety.
    </Text>
    <Text style={styles.subtitleBold}>Too little or too much confidence</Text>
    <Text style={styles.text}>
      A good degree of confidence is desired as confidence to disobey a request
      is imperative for safety; for example, in traffic. Over - confidence
      however was problematic, such as in making navigational decisions without
      following the human partners request.
    </Text>
    <Text style={styles.subtitleBold}>Unwillingness to work</Text>
    <Text style={styles.text}>
      Occasionally, dogs were criticised for lacking willingness at times. Dogs
      who did not follow signals were described as frustrating, or hard work.
      Although these were isolated incidents it caused concern for the guide dog
      owner as the worry was that they would do this again.
    </Text>
    <Text style={styles.subtitleBold}>Unpredictable in traffic</Text>
    <Text style={styles.text}>
      This unwanted behaviour is of understandable concern. The owners tended to
      put this down to the dog being scared, distracted or close to home.
    </Text>
    <Text style={styles.subtitleBold}>Excitability</Text>
    <Text style={styles.text}>
      Noise or overactivity is seen as embarrassing and hard work for the guide
      dog owners. It can lead to the dog pulling on lead in new situations or
      when meeting new or familiar people and dogs.
    </Text>
    <Text style={styles.subtitleBold}>Non-working behaviours</Text>
    <Text style={styles.text}>
      Guide dog owners in this study identified a number of behaviours they felt
      were inappropriate:
    </Text>
    <ListItem>stealing </ListItem>
    <ListItem>scavenging </ListItem>
    <ListItem>chewing </ListItem>
    <ListItem>destruction in the garden</ListItem>
    <ListItem>barking </ListItem>
    <Text style={styles.subtitleBold}>Inappropriate toileting</Text>
    <Text style={styles.text}>
      Defecation out of time/space provided was identified as problematic. As
      was coprophagia (eating faeces)
    </Text>
    <Text style={styles.italics}>
      *You will find the full copy of this study in the supplementary reading
      files.{' '}
    </Text>
    <Text style={styles.underlineHeading}>
      Understanding Problem Solving Methods for Addressing Undesirable Canine
      Behaviour:
    </Text>
    <Text style={styles.text}>
      Unwanted behaviours from a human’s point of view is quite often simply
      natural behaviour from a dogs point of view. Natural behaviours cannot and
      should not be “punished” out of a dog. Neither can they be completely
      removed from a dog’s behaviour repertoire by positive reinforcement.
      Remedying these types of unwanted behaviours are very often about
      compromising.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(72.5).png?alt=media"
    />
    <Text style={styles.subtitleBold}>Digging:</Text>
    <Text style={styles.text}>
      Dogs bred to dig, such as terriers, need to be given a place to express
      this behaviour so a compromise can be made. Sandpits in the garden are one
      option for this. A dog may also dig due to a lack of stimulation and
      exercise i.e. a basic need not being met.
    </Text>
    <Text style={styles.text}>
      Digging can quickly become a behaviour from which attention is gained due
      to a human partner giving them attention every time it happens – remember
      even negative attention is attention! Unfortunately if a dog is subjected
      to punishment that scares him/her it will impact the dogs well-being
      outside of that single behaviour as well. As well as damage the bond
      between the human and dog.
    </Text>
    <Text style={styles.subtitleBold}>Scavenging:</Text>
    <Text style={styles.text}>
      Another natural behaviour for dogs is scavenging. Whilst a dog rummaging
      through garbage when on his own, or jumping on kitchen surfaces to find
      food, or pulling towards a bit of food on the floor on a walk are all
      unwanted behaviours according to humans – these are all examples of a dog
      exhibiting his natural instincts.
    </Text>
    <Text style={styles.text}>
      This is a behaviour that needs to be managed as the risks are a dog could
      eat something that is toxic to them, or something indigestible, or
      something poisonous. Taking things in the home can be reinforcing for a
      number of reasons:
    </Text>
    <ListItem>The food/item itself</ListItem>
    <ListItem>
      The attention the dog gets as a human chases them to retrieve the item
    </ListItem>
    <ListItem>Both!</ListItem>
    <Text style={styles.text}>
      Ignoring scavenging opportunities is not a behaviour best dealt with
      through obedience. Simply because, what if the human partner is not able
      to see the scavenging opportunity before them? “Leave it” cues are reliant
      on the human partner being around and able to micro-manage. Building
      impulse control into a dog (especially practising around high value food)
      can help with this behaviour. Impulse control is a life skill that a dog
      can use to manage his own behaviour. Assistance dogs are trained to ignore
      any food they may find on the floor as they walk by.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(73).jpg?alt=media"
    />
    <Text style={styles.subtitleBold}>Chewing:</Text>
    <Text style={styles.text}>
      Chewing is another natural behaviour. Dogs need a supply of safe chews and
      toys, perhaps on rotation to prevent boredom, to give them an appropriate
      outlet for this behaviour. Young dogs/puppies go through teething during
      age 3-6 months. Therefore, the chewing relieves some discomfort and helps
      facilitate the removal of puppy teeth. To ensure what the puppy chews on
      is safe for them it is important to manage the puppies access in the home.
      A puppy pen can be a safe place for a puppy to rest, learn to feel
      positive about being alone, and manage chewing when the puppy cannot be
      actively supervised.
    </Text>
    <Text style={styles.text}>
      When considering behaviour management and training we always want to
      consider teaching the dog what we DO want them to do; rather than focusing
      on what we DON’T want them to do. Tools such as aversive anti-chew sprays
      have a focus on punishing a dog for chewing on something inappropriate.
      This is rarely successful. Instead we need to focus on showing a dog what
      they CAN chew.
    </Text>
    <Text style={styles.subtitleBold}>Jumping Up:</Text>
    <Text style={styles.text}>
      Jumping up starts as a functional behaviour that helps puppies gain
      information about the person they are investigating.
    </Text>
    <Text style={styles.text}>
      Jumping up is easily reinforced, especially with puppies. A jumping puppy
      is very often looked at, talked to, and touched by people.. Puppies may
      also jump up for reassurance if frightened. Please ensure you and all
      others meeting the dog understand that they must not pay any attention to
      a puppy (or adult dog) if it is jumping/climbing up on them.
    </Text>
    <Text style={styles.text}>
      Adult dogs jumping up is an unwanted behaviour by many, however, dogs
      under conflict may also jump up. Dogs are not as ‘touchy- feely’ as we are
      as humans. Sometimes dogs may jump up when they want to greet a person but
      are apprehensive of being touched. Touch can be very overstimulating for
      some dogs
    </Text>
    <Text style={styles.text}>
      If a dog is practising jumping up, giving any form of attention by asking
      the dog to “down” or pushing on a dogs chest to get them down will be
      reinforcing the jumping up. Any attention to a dog can be reinforcing.
    </Text>
    <Text style={styles.text}>
      If a dog does jump up, simply twisting your head and body away both uses a
      cut off signal in their language (head-turn) and typically means they fall
      onto the floor. No attention must be given until the dog has four paws on
      the floor.
    </Text>
    <Text style={styles.text}>
      Training sessions rehearsing situations where a young dog might get
      excited and jump up are important to show the dog what to do, instead of
      just focusing on what not to do.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(74).jpg?alt=media"
    />
    <Text style={styles.subtitleBold}>Training Tips to Prevent Jumping:</Text>
    <Text style={styles.text}>
      Start by walking quickly backwards from the puppy bridging the dog’s
      correct behaviour, rewarding for following, but keeping four feet on the
      floor.
    </Text>
    <ListItem>
      Practice walking towards the puppy and as above, bridge and reward for
      having four feet on the floor.
    </ListItem>
    <ListItem>Practice coming in through doorways</ListItem>
    <ListItem>Practise coming in through closed doors</ListItem>
    <Text style={styles.text}>
      On each step ensure you begin by rewarding before the dog even has time to
      begin jumping up. In time you can start to leave it longer between the
      human presenting themselves and the dog getting rewarded for still having
      four feet on the floor.
    </Text>
    <Text style={styles.text}>
      If a dog has been practising jumping up for a while, management might be
      needed in order to stop the dog from being able to rehearse the behaviour
      anymore. Management can include having the dog behind a baby gate or on
      harness and lead so the dog is at a distance to exhibit calmness before
      getting up close to the person.
    </Text>
    <Text style={styles.text}>
      Aversive based methods for addressing jumping up are to be avoided at all
      costs. Any positive punishment (adding something aversive in order to
      reduce the behaviour) will run the risk of the dog associating people with
      aversives therefore creating anxiety.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(75).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="pulling">
      Pulling on the Lead:
    </Text>
    <Text style={styles.text}>Dogs pull for a variety of reasons:</Text>
    <ListItem>
      Due to excitement and a desire to quickly move from one smell to the next
      or get to the destination.
    </ListItem>
    <ListItem>
      Due to anxiety and a desire to get away from the source of anxiety e.g.
      the triggers or generally being outside.
    </ListItem>
    <ListItem>
      Due to a history of reinforcement – they have simply learnt that pulling
      forwards gets them to where they want to go.
    </ListItem>
    <Text style={styles.text}>
      A dog moving rapidly towards something is self-rewarding. Teaching a puppy
      loose lead walking from the start is easier than teaching an adult dog who
      has the pulling well-rehearsed. A dog can be shaped by positive
      reinforcement to find walking next to a handler rewarding. We call this
      <Text style={styles.textBold}>‘Connected Walking’</Text>. Instead of being
      forced to walk next to their handler by having their lead held tightly or
      yanked, they are heavily reinforced with a variety of treats for choosing
      to walk next to their handler, which creates connection between dog and
      owner. Connection is a state that exists between dog and owner when both
      are responsive to each other. There doesn’t necessarily need to be eye
      contact, but the dog will change direction willingly and fluidly,
      following her handler if the handler shifts direction. All these movements
      take place without the lead going taut.
    </Text>
    <Text style={styles.text}>
      Whenever the dog chooses to walk near the handler, the handler
      bridges/marks the behaviour and treats the dog, putting the treat directly
      in the dog’s mouth at the handler’s side at working position
    </Text>
    <Text style={styles.text}>
      Doing this over a period of several days in short sessions means that the
      dog will start to position herself in this spot. This can be done in a
      garden off lead initially or on a loose lead ensuring that there is enough
      slack as to not pull the dog. The most important aspect of teaching loose
      lead walking is consistency. Teaching a dog to walk on a loose lead
      provides mental exercise because of the amount of concentration that they
      need in the early stages while learning impulse-control. Learning
      self-control can be taxing on the brain and will be equally as tiring as
      running over a large distance.
    </Text>
    <Text style={styles.text}>
      If the handler wishes to use cues, then the time to do this is to add a
      verbal signal such as ‘heel’ once the dog is constantly walking on a loose
      lead for about 2 weeks. There may still be a need to turn and walk in the
      opposite direction every so often over the first 2 months of learning
      loose lead skills if there is a particularly enticing trigger, which is
      why it is so important to be consistent about not allowing the dog to
      pull.
    </Text>
    <Text style={styles.text}>
      If a dog is pulling due to anxiety, the source of the anxiety needs to be
      dealt with. This is less a training issue, more a behavioural one.
    </Text>
    <Text style={styles.text}>
      It is not necessary to use punishment when teaching a dog to walk nicely
      on a lead. Assistance dogs in particular need to feel happy and confident
      being on a lead/harness with their handler. Using punishment can simply
      create a dog who wants to get away from them.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(76).jpg?alt=media"
    />
    <Text style={styles.subtitleBold} id="barking">
      Barking:
    </Text>
    <Text style={styles.text}>
      Barking is another natural canine behaviour that is often labelled as
      unwanted by humans. Some breeds (guard breeds, terriers and cocker
      spaniels) all have a predisposition to bark.
    </Text>
    <Text style={styles.text}>
      Dogs barking is akin to humans talking – it is communication.
    </Text>
    <Text style={styles.text}>
      Dogs can quickly get in the habit of barking when postal workers come to
      the door. Often this is reinforced by the very fact that once the post has
      been delivered, the postal worker goes away. From the dog’s perspective
      his barking led to the postal worker leaving.
    </Text>
    <Text style={styles.text}>
      Barking can be further exacerbated by a human partner giving attention to
      the dog when the dog is barking. For example, telling the dog to be quiet.
      Attention is a powerful reinforcer for many dogs; the attention doesn’t
      have to be meant in a positive way from our perspective.
    </Text>
    <Text style={styles.text}>
      Attention-seeking barking in particular can become an issue when dogs are
      taught to bark for a treat. It creates a pathway in the way linking up
      barking with attention AND food. This takes consistency to undo.
    </Text>
    <Text style={styles.text}>
      Dogs can also bark out of anxiety or frustration
    </Text>
    <ListItem>Anxiety towards other dogs</ListItem>
    <ListItem>Anxiety towards other people</ListItem>
    <ListItem>Isolation distress</ListItem>
    <ListItem>Separation anxiety</ListItem>
    <ListItem>Resource guarding</ListItem>
    <Text style={styles.text}>
      Treatment for excessive barking can only be planned once the antecedent of
      the behaviour is known, as well as what is reinforcing it. Suppressing
      barking through aversive methods or even just implementing a “quiet” cue
      is not dealing with the underlying cause and will often just cause
      unwanted behaviours in other areas of the dogs life.
    </Text>
    <Text style={styles.subtitleBold} id="scent-marking">
      Scent Marking:
    </Text>
    <Text style={styles.text}>
      Canine urine marking is a natural, instinctive behaviour in dogs, but it
      goes without saying that this is not an appropriate indoor activity. Dogs,
      especially sexually intact male dogs, urinate on objects to mark territory
      or leave a message for other dogs. Urine marking behaviour usually begins
      when the dog reaches sexual maturity.
    </Text>
    <Text style={styles.text}>
      An intact male dog is most likely to mark when there is a female dog in
      heat nearby. Intact female dogs are also prone to marking when they are in
      heat. However, any dog may mark if another dog has urinated anywhere in
      the house. By urinating on the previous site of urination, the dog
      essentially “re-marks” that location as its own territory. Unless the
      scent of the urine is completely removed, the dog is likely to keep
      urinating there.
    </Text>
    <Text style={styles.text}>
      Any anxiety-producing situation can trigger urine marking as well. Workmen
      in the house, the arrival of a new baby, or visiting relatives can all
      produce anxiety in a dog. Other related housetraining problems include:
    </Text>
    <ListItem>
      Soiling in the house – could be due to anxiety, habit, medical problem or
      confusing housetraining.
    </ListItem>
    <ListItem>
      Soiling when alone – could be due to anxiety such as isolation distress or
      confusing housetraining.
    </ListItem>
    <ListItem>
      Urinating when excited – could be due to excitement, anxiety or conflict
      or a medical problem.
    </ListItem>
    <ListItem>
      Submissive urination – could be due to anxiety or conflict.
    </ListItem>
    <ListItem>
      Refusing to toilet in front of a person – could be due to confusing house
      training such as punishment used so the dog has learnt being around a
      human when toileting is wrong.
    </ListItem>
    <Text style={styles.text}>
      All house accidents need to be cleared up with a biological solution (e.g.
      diluted biological washing powder) to remove the smell of the ammonia and
      decrease the chances of repeat offences. Many cleaning products from pet
      shops actually contain a derivative of urea (urea is the chemical
      derivative of urine), which simply smells of urine to a dog and is likely
      to encourage them to urinate over the spot which has been cleaned. The
      underlying cause of the difficulty must be determined and dealt with.
    </Text>
    <Text style={styles.subtitleBold} id="growling">
      Growling:
    </Text>
    <Text style={styles.text}>
      Growling, like barking, is a way a dog communicates. Take a look at the
      BSAVA (British Small Animal Veterinary Association) ladder of aggression
      below. Note how high up “growling” is!
    </Text>
    <Image
      style={{
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(77).jpg?alt=media"
    />
    <Text style={styles.text}>
      Generally, if a dog is growling, then a good number of communications from
      the lower rungs of the ladder have been missed. At the point of growling
      the dog is having to really communicate very strongly that they want
      whatever is happening that is worrying him to stop.
    </Text>
    <Text style={styles.text}>
      Punishing or suppressing the growling can result in a dog snapping or
      biting without warning. Whilst aggressive behaviours from the top rungs of
      the ladder are generally last resort for a dog, if they have had to
      practise this previously, they are more likely to use it again in the
      first instance. Aggression is a normal part of animal behaviour. Often
      people only consider aggressive behaviour in a dog to be a problem when it
      reaches the extremes of biting, but it can include lesser degrees, such as
      “grumbling”, growling, snarling, teeth baring and snapping at the air
      without making contact. Bites too can vary from light touches with the
      teeth right through to inflicting serious injury.
    </Text>
    <Text style={styles.text}>
      Aggression is the outward expression of an emotion and can be used to
      communicate various intentions by the dog. Rather than being something
      that switches on and off suddenly, aggression is part of a range of
      behaviours that dogs have available to them to deal with life’s everyday
      challenges.
    </Text>
    <Text style={styles.text}>
      Many of these behaviours are passive and it is only when the dog changes
      from trying to avoid a situation, through things like moving away or
      showing that they are not happy, to actively trying to manage the
      situation, that we term it “aggression”. When a dog has to cope with a
      particularly unpleasant or stressful situation, they have a number of
      options. Aggression is simply one of those options.
    </Text>
    <Text style={styles.text}>
      Dogs are aggressive in response to the situation they are experiencing. As
      it is part of their natural behaviour, any dog has the ability to use
      aggression. Whether the dog resorts to aggression depends on a variety of
      factors.
    </Text>
    <Text style={styles.text}>For example;</Text>
    <ListItem>Has the dog got other ways to deal with the situation?</ListItem>
    <ListItem>
      Has the dog used aggression before and was the aggression successful?
      (i.e. did the dog get rid of the threat/stressor).
    </ListItem>
    <ListItem>What is the dog’s temperament? And so on.</ListItem>
    <Text style={styles.text}>
      Although it may seem like a dog who is behaving aggressively is confident,
      usually fear is the main motivation for the aggressive display. Therefore,
      when a dog uses aggression it is almost invariably because the dog feels
      under some form of threat. For example, the threat could be to their
      personal safety, something they value being taken away, or the dog is
      being prevented from doing something they really want to do, which causes
      frustration. Aggression can be used to control or reduce this challenge.
      Theoretically, every single dog, if pushed far enough, can and will use
      aggression.
    </Text>
    <Text style={styles.text}>
      There are inherited behaviours that are particular to some breeds that
      will make it more likely for dogs of that breed to use aggression where
      others would not. Because no two dogs are the same, individuals will
      differ too. For example, some breeds are intentionally bred to be more
      reactive and some to be “wary of strangers” (most guarding breeds), which
      might make them more likely to be defensive if approached. In every breed,
      there will be individuals that use aggression inappropriately and others
      that will not.
    </Text>
    <Text style={styles.text}>
      Guarding breeds such as the Doberman have been selected to show aggression
      when threatened, but the levels of threat always have to be learned
      through experience. If you were to take a puppy from a very defensive
      guarding breed and raise the pup correctly, they would not necessarily
      show aggression except in the most extreme circumstances. If you were to
      take a puppy from the least defensive breed and raise that pup badly, the
      pup could very well turn out to be aggressive in all kinds of
      circumstances. Most forms of aggression, except for predation, are
      distance- increasing behaviour (i.e. the dog is attempting to actively
      increase the distance between him/her and the stimulus).
    </Text>
    <Text style={styles.text}>
      There are many types of aggressive behaviours with different motivations.
      However, fear, anxiety, genetics, and learned responses generally play a
      role in most cases. The effects of early development, socialisation, and
      previous experience all play a role in the development of aggression.
    </Text>
    <Text style={styles.subtitleBold} id="fears">
      Fears and Anxieties:
    </Text>
    <Text style={styles.text}>
      Fears or anxieties within a dog can present in different ways. Some
      observable behaviours include:
    </Text>
    <View style={bulletPointStyles.row}>
      <div>
        <ListItem>Shaking</ListItem>
        <ListItem>Excessive panting</ListItem>
        <ListItem>Hiding</ListItem>
        <ListItem>Escape behaviours</ListItem>
        <ListItem>Aggressive behaviours</ListItem>
        <ListItem>Cowering</ListItem>
        <ListItem>Excessive salivating</ListItem>
        <ListItem>Refusal to eat</ListItem>
      </div>
      <Image
        style={{ ...styles.image, width: '200px' }}
        src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(57).jpg?alt=media"
      />
    </View>
    <Text style={styles.text}>
      Fears and anxieties can be caused by a lack of socialisation, negative
      experience/s, genetic predispositions and/or unhelpful handler input.{' '}
    </Text>
    <Text style={styles.text}>
      A dog who is not exposed to fireworks or loud noises, for example, during
      the socialisation period of 3 to 14 weeks of age may not be able to cope
      with these sounds when older. Prevention is of course better than cure; so
      puppy socialisation is paramount. Counter-conditioning and desensitisation
      (CC/DS) can help with anxieties or fears that are already ingrained. It is
      imperative to, as much as possible, block the dogs exposure to his
      triggers whilst undergoing this behavioural modification.
    </Text>
    <Text style={styles.text}>
      Using a sound recording of the sounds can help with CC/DS to noise
      triggers. Pairing the noise with an appetitive stimuli such as a
      kong/lickimat of high value foods will help to change the dogs conditioned
      emotional response (CER) to the noise.
    </Text>
    <Text style={styles.text}>
      However, the sound must be played at lowest volume first to ensure the dog
      stays under threshold. Slowly you can increase the volume of the noise. If
      the dog shows any stress behaviours as you increase the volume the dog is
      not yet ready for an increased volume.
    </Text>
    <Text style={styles.text}>
      A study in the UK found that dogs with musculoskeletal pain formed a
      greater sensitivity to noise. It is suggested that a noise can make a dog
      tense up – putting strain and stress on a body that is already feeling in
      pain. When the noise is then frequently followed up with further pain, an
      association is made.
    </Text>
    <Text style={styles.text}>
      One-trial learning is also enough. A dog who jumps away from a loud noise
      suddenly and seriously damages a knee joint for example may then become
      fearful of loud noises from that point. Often noise sensitivity
      generalises over time if not dealt with. The study can be found in your
      supplementary materials: Noise sensitivity and pain.
    </Text>
    <Text style={styles.subtitleBold} id="repetitive">
      Repetitive Behaviours:
    </Text>
    <Text style={styles.text}>These include:</Text>
    <ListItem>Tail chasing</ListItem>
    <ListItem>Shadow/light chasing</ListItem>
    <ListItem>Compulsive licking of self or other objects</ListItem>
    <ListItem>Pacing</ListItem>
    <ListItem>Barking</ListItem>
    <Text style={styles.text}>
      These behaviours may develop due to a medical problem (for example, full
      anal glands can cause a dog to spin around his back end, and compulsive
      licking can be due to digestive issues).They can quickly develop into a
      self-rewarding action that can relieve boredom or stress. They are
      sometimes adopted by dogs in kennels who are under high stress with
      limited outlets. These behaviours can release endorphins in the dogs body
      helping them to cope with what they are feeling. Left unchecked these
      behaviours can quickly become learned behaviour through repetition or a
      human accidentally rewarding it through their own reactions.
    </Text>
    <Text style={styles.text}>
      A dog exhibiting these behaviours needs to be checked by a vet for
      physical causes primarily. Management of the behaviour is paramount.
      Discerning what is triggering the behaviour (e.g. boredom?
      Overstimulation? A certain stimuli?) and as much as possible block
      exposure to those triggers will stop the dog from practising it and
      further cementing the behaviour.
    </Text>
    <Text style={styles.text}>
      These behaviours must be interrupted in a way that doesn’t give the dog
      direct attention. Walking away from the dog and making a noise in another
      area of the room can interrupt it, only giving the dog direct attention
      when the dog is offering another WANTED behaviour such as standing calmly
      looking at you.
    </Text>
    <Text style={styles.text}>
      Behaviour modification as early as possible is important. This could be
      through counter-conditioning of triggering stimuli, changing a dog’s
      activity levels (both physical and mental), and offering the dog
      alternative behaviours to help cope with their stress levels.
    </Text>
    <Text style={styles.subtitleBold} id="travel">
      Travel Problems:
    </Text>
    <Text style={styles.text}>These may include:</Text>
    <ListItem>
      Barking at things outside of the car – could be due to excitement,
      anxiety, frustration or a reinforced habit.
    </ListItem>
    <ListItem>
      Sickness or diarrhoea whilst travelling in a car– could be due to fear,
      lack of travelling experience, illness.
    </ListItem>
    <ListItem>
      Shaking, salivating or whimpering whilst travelling in a car – could be
      due to feeling sick, excitement, anxiety.
    </ListItem>
    <ListItem>
      Destructive behaviour or excessive activity in the car – could be due to
      over-excitement, anxiety, or a reinforced behaviour.
    </ListItem>
    <Text style={styles.text}>
      Whether the cause of the unwanted behaviour in the car is anxiety,
      excitement, car sickness or habit the way to deal with it is much the
      same: counter-conditioning and desensitisation.
    </Text>
    <Text style={styles.text}>
      Even if you do not personally own a car it is a vital part of the dog’s
      training and you will need to start training early on to ensure your dog
      is comfortable travelling in vehicles.{' '}
    </Text>
    <Text style={styles.subtitleBold}>Travel Training Tips;</Text>
    <ListItem>
      Start in a stationary car ensuring that being in the car is a positive but
      calm place to be. Feed the dog a kong or similar in the car for a number
      of days.
    </ListItem>
    <ListItem>
      Progress to having the engine on. Keep rewarding the dog for calm
      behaviour. Ensure the dog is secured safely in the vehicle from this point
      through a travel harness or car crate.
    </ListItem>
    <ListItem>
      Progress to moving the car a few feet. As above with rewarding
    </ListItem>
    <ListItem>
      As long as the dog stays calm and quiet, progress with a longer journey.
    </ListItem>
    <Text style={styles.italics}>
      *Note - for safety reasons you will need assistance either as a helper to
      drive or to reinforce
    </Text>
    <Text style={styles.subtitleBold} id="stress">
      Stress and Behaviour:
    </Text>
    <Text style={styles.text}>
      Stress can be defined as{' '}
      <Text style={styles.italics}>
        “a state of mental or emotional strain or tension resulting from adverse
        or demanding circumstances”.
      </Text>{' '}
      (Ref: Oxford Dictionaries)
    </Text>
    <Text style={styles.text}>
      Certain levels of stress are essential for normal growth, development and
      survival in any organism. When a stressor is perceived, the stress
      response system starts up a series of biological events. As a result, the
      body releases the stress hormones adrenaline and cortisol which activate
      the body’s fight or flight mode. These glucocorticoids bind to stress
      hormone receptors in cells throughout a dog’s body, conveying messages to
      each cell about what it needs to do to react to the perceived stressor. A
      healthy reaction to this is for the body to become on the alert and ready
      to take action and a dog in this state will be showing a normal,
      emotionally balanced response to stress. Once the stressful situation is
      over, the body will return to a state of calm and a healthy, emotionally
      stable dog will relax.
    </Text>
    <Text style={styles.text}>
      Interpreting a stressor activates the stress system but once the stressful
      situation is over, the body needs to return to a state of homeostasis to
      avoid the production of excessive amounts of adrenaline and cortisol. The
      longer this takes, the more damaging it can be, because the body is under
      physical stress until the hormones dissipate.
    </Text>
    <Text style={styles.text}>
      There are two types of stress: Eustress and Distress. Not all stress is
      harmful. The production of stress hormones stimulates an organism to
      respond appropriately to danger. Small, manageable amounts of stress teach
      the brain to adapt and learn to deal with new experiences, change and
      challenges. This is known as eustress or “positive” stress and allows an
      organism to develop new capabilities.
    </Text>
    <Text style={styles.text}>
      When something stressful happens, the body is governed by the Sympathetic
      Autonomic Nervous System(SANS). The SANS is responsible for controlling
      the flight or fight response by sending messages to the most important
      muscles needed for fleeing or fighting: the heart, the lungs and the
      limbs. When the SANS is activated, the dog’s body goes on auto-pilot to
      protect her from a perceived threat while blood flow is restricted to all
      other muscles (such as the digestive system).
    </Text>
    <Text style={styles.text}>
      When the limbic system (emotional auto-pilot) is activated, the cerebral
      cortex is suppressed. The cerebral cortex is responsible for rational
      thought, which is why animals have a limited ability to behave calmly and
      rationally when in a highly charged emotional state. Dogs who feel highly
      stressed are frequently unable to respond to cues because their cerebral
      cortex has “switched off” and they cannot carry out previously learned
      commands.
    </Text>
    <Text style={styles.text}>
      Once the stressful situation has passed, the levels of neurotransmitters
      and stress hormones in a healthy body will return to normal levels.
      However, these levels can take over 24 hours to return to a state of
      homeostasis, in fact research has shown that some dogs can take up to 72
      hours for levels of cortisol and adrenaline to return to normal following
      a stressful experience.
    </Text>
    <Text style={styles.text}>
      Consequently, if a dog is exposed to frequent or prolonged stress events
      (daily or multiple times per day) those levels may never return to normal,
      resulting in a constant/chronic state of stress. In some dogs, being
      exposed to chronic stress results in neurotransmitters malfunctioning and
      unable to return the body to a normal state, with levels of cortisol
      continuing to build. This means that the cortisol level of ‘normal’ for
      that individual dog is at a higher level than previously and the body
      subsequently begins to exhibit signs of chronic stress.
    </Text>
    <Text style={styles.text}>
      Most domestic dogs suffer some level of stress throughout their lives
      because they live in a state of captivity with very little control over
      the choices they are able to make. Allowing dogs some capacity of
      self-regulation teaches them to problem solve and become more resilient,
      because of the subsequent development of the cerebral cortex. A dog who is
      able to think her way through problems is a more confident, calmer dog who
      is able to develop coping mechanisms.
    </Text>
    <Text style={styles.text}>
      It could be argued that assistance dogs are more likely to be under a
      chronic state of stress due to various demands being put on them on a
      daily basis. This is why it is of utmost importance to be sensitive to an
      assistance dog’s emotional wellbeing through giving them “down time” –
      away from learning, away from doing, away from having expectations put on
      them.
    </Text>
    <Text style={styles.text}>
      As humans it is not recommended that we work without breaks; the same is
      said for our working dogs in society. Too much stimulation and exercise
      can be detrimental for a dog; leading to a brain in a constant state of
      arousal. Adequate opportunities for rest and recovery is highly important.
      A dog who is constantly on the move is experiencing high levels of stress.
    </Text>
    <Text style={styles.text}>
      To conclude; when addressing unwanted canine behaviours we need to:
    </Text>
    <ListItem>
      Use management to stop the behaviour from being rehearsed
    </ListItem>
    <ListItem>Determine what is causing the unwanted behaviour</ListItem>
    <ListItem>Tend to the emotions behind the behaviour</ListItem>
    <ListItem>Focus on teaching an alternative wanted behaviour</ListItem>
    <ListItem>
      Avoid punishment-based techniques as they have negative side effects
    </ListItem>

    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

ManagingCanineBehaviour.propTypes = {
  styles: PropTypes.object,
}

export default ManagingCanineBehaviour

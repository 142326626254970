import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { TextField, Button, FormGroup, Typography, Alert } from '@mui/material'
import { sponsorRequired } from '../../utils/requiredFields'

const Personal = ({ application, goBack }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const [sponsorDetails, setSponsorDetails] = useState(application)
  const [startedForm, setStartedForm] = useState(false)
  const [missingFields, setMissingFields] = useState([])

  useEffect(() => {
    setSponsorDetails(application)
    const s = sponsorRequired.every(
      (x) =>
        application && (application[x.field] === '' || !application[x.field]),
    )

    const fields = []
    sponsorRequired.forEach((input) => {
      if (
        application &&
        input.field &&
        (application[input.field] === '' || !application[input.field])
      ) {
        fields.push(input.label)
      }
    })
    setMissingFields(fields)
    setStartedForm(!s)
  }, [application])

  return (
    <>
      <Typography variant="h4" mb={2}>
        Sponsor Details
      </Typography>
      <Typography
        variant="body1"
        mb={!!startedForm && missingFields.length > 0 ? 2 : 3}
      >
        <strong>Please provide name and contact details of your sponsor</strong>
      </Typography>
      <Typography mb={!!startedForm && missingFields.length > 0 ? 2 : 5}>
        Your sponsor can be a friend or family member who is willing to take
        full ownership of your dog should, for any reason, you no longer be able
        to care for them. Your sponsor will also look after your dog should you
        have a mental health crisis that would stop you from doing so. By
        filling in this section it is assumed that you have discussed this with
        your sponsor and they are happy for you to pass their details onto us.
        We may be in contact with your sponsor to confirm.
      </Typography>
      {!!startedForm && missingFields.length > 0 && (
        <Alert severity="warning" sx={{ marginBottom: 4 }}>
          The following fields have yet to be completed:
          {missingFields.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </Alert>
      )}
      <FormGroup>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorFirstName}
              id="sponsorFirstName"
              label="First Name"
            />
          )}
          control={control}
          name="sponsorFirstName"
          defaultValue={sponsorDetails?.sponsorFirstName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorLastName}
              id="sponsorLastName"
              label="Last Name"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorLastName"
          defaultValue={sponsorDetails?.lastsponsorLastNameName || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorEmail}
              id="sponsorEmail"
              label="Email"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorEmail"
          defaultValue={sponsorDetails?.sponsorEmail || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorPhone}
              id="sponsorPhone"
              label="Phone"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorPhone"
          defaultValue={sponsorDetails?.sponsorPhone || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorAddress}
              id="sponsorAddress"
              label="Address"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorAddress"
          defaultValue={sponsorDetails?.sponsorAddress || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorAddress2}
              id="sponsorAddress2"
              label="Address 2"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorAddress2"
          defaultValue={sponsorDetails?.sponsorAddress2 || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorCity}
              id="sponsorCity"
              label="City"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorCity"
          defaultValue={sponsorDetails?.sponsorCity || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorCounty}
              id="sponsorCounty"
              label="County"
              sx={{ marginTop: 3 }}
            />
          )}
          control={control}
          name="sponsorCounty"
          defaultValue={sponsorDetails?.sponsorCounty || ''}
        />

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.sponsorPostcode}
              id="sponsorPostcodesponsorPostcode"
              label="Post code"
              sx={{ marginTop: 3, width: '50%' }}
            />
          )}
          control={control}
          name="sponsorPostcode"
          defaultValue={sponsorDetails?.sponsorPostcode || ''}
        />
      </FormGroup>

      <Button
        variant="contained"
        color="info"
        type="button"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
        onClick={() => goBack()}
      >
        Back
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 8, marginBottom: 8, marginRight: 2 }}
      >
        Save and finish
      </Button>
    </>
  )
}

Personal.propTypes = {
  application: PropTypes.object,
  goBack: PropTypes.func,
}

export default Personal

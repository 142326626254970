import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Alert, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Snackbar from '@mui/material/Snackbar'
import { Link } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Dog from './Dog'
import Medical from './Medical'
import Sponsor from './Sponsor'
import Personal from './Personal'
import Vet from './Vet'
import { useAuth } from '../../contexts/AuthContext'
// eslint-disable-next-line max-len
import { ApplicationContext } from '../../contexts/application/ApplicationContext'
import {
  personalDetailsRequired,
  dogDetailsRequired,
  veterinaryInfoRequired,
  sponsorRequired,
  medicalRequired,
} from '../../utils/requiredFields'
// import { sendEmail } from '../../data/email'
import { createApplication, updateApplication } from '../../data/application'
import { updateUser } from '../../data/user'

const steps = [
  'Personal Details',
  'Medical Information',
  'Dog Details',
  'Veterinary Information',
  'Your Sponsor',
]

const CreateApplication = () => {
  const { currentUser, db, fetchUser } = useAuth()
  const { application, fetchApplication } = useContext(ApplicationContext)

  const navigate = useNavigate()

  const methods = useForm({
    defaultValues: useMemo(() => {
      return application
    }, [application]),
  })

  useEffect(() => {
    fetchApplication(currentUser.uid, db)
  }, [])

  useEffect(() => {
    if (
      application &&
      (application.status === 'Complete' ||
      application.status === 'Submitted')
    ) {
      navigate(`/application/read-only`)
    }

    methods.reset(application)
  }, [application])

  const onSubmit = async (data) => {
    if (!application) {
      await createApplication(data, currentUser.uid, db)
      fetchApplication(currentUser.uid, db)
      await updateUser({ hasApplication: true }, currentUser.uid, db)
      fetchUser()
    } else {
      await updateApplication(data, currentUser.uid, db)
      fetchApplication(currentUser.uid, db)
    }

    setStepComplete(data)
    if (isLastStep()) {
      navigate('/application')
    } else {
      handleNext()
    }
  }

  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const setStepComplete = (data) => {
    const newCompleted = completed
    newCompleted[activeStep] = true

    personalDetailsRequired.forEach((field) => {
      if (data[field] === '') newCompleted[0] = false
    })

    medicalRequired.forEach((field) => {
      if (data[field] === '') newCompleted[1] = false
    })

    dogDetailsRequired.forEach((field) => {
      if (data[field] === '') newCompleted[2] = false
    })

    veterinaryInfoRequired.forEach((field) => {
      if (data[field] === '') newCompleted[3] = false
    })

    sponsorRequired.forEach((field) => {
      if (data[field] === '') newCompleted[4] = false
    })

    setCompleted(newCompleted)
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
    window.scrollTo(0, 0)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
    window.scrollTo(0, 0)
  }

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  if (currentUser) {
    const renderStep = () => {
      switch (activeStep) {
        case 0:
          return <Personal application={application} goBack={handleBack} />
        case 1:
          return <Medical application={application} goBack={handleBack} />
        case 2:
          return <Dog application={application} goBack={handleBack} />
        case 3:
          return <Vet application={application} goBack={handleBack} />
        case 4:
          return <Sponsor application={application} goBack={handleBack} />
        default:
          return <Personal application={application} goBack={handleBack} />
      }
    }

    return (
      <section>
        <Container fixed sx={{ marginTop: '50px' }}>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Link to="/application">{'< Back to Application Overview'}</Link>

          <Grid container spacing={2}>
            <Grid xs={3} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stepper
                nonLinear
                activeStep={activeStep}
                orientation="vertical"
                sx={{ marginTop: 5 }}
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid xs={12} md={7}>
              <Box sx={{ marginTop: 5 }}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {renderStep()}
                  </form>
                </FormProvider>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    )
  }

  return null
}

export default CreateApplication

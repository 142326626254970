/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page, Link } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const Development = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="puppy-development" style={styles.title}>
      2. Puppy Development
    </Text>
    <Text style={styles.text}>
      &quot;Puppies provided with poor socialisation or deprived of
      environmental exposure often develop lifelong deficits and dysfunctional
      behaviours. A puppy isolated early in life from other puppies and humans
      will not only fail to establish satisfying social contact with
      conspecifics or enjoy companionship with people later in life (such
      puppies are extremely fearful of any social contact), they will also
      exhibit widespread behavioural and cognitive disabilities as well.
      Isolated puppies exhibit poor learning and problem-solving abilities and
      are extremely hyperactive or rigidly inhibited, are emotionally
      over-reactive and unable to encounter novel social or environmental
      situations without extreme fear and avoidance, and are socially and
      sexually incapacitated.&quot;
    </Text>
    <Text style={styles.italics}>
      - Handbook of Applied Behavior and Training, Steven R. Lindsay
    </Text>

    <Text style={styles.text}>
      As dogs develop, behavioural responses to stimuli increase in complexity.
      Each dog will develop differently as an individual, dependent upon his
      genetic make-up, breed and temperament. The canine brain goes through
      several developmental stages from 0 weeks to between 1 and 3 years,
      depending on the breed.
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(40).jpg?alt=media"
    />
    <Text style={styles.text}>
      Many people recommend getting a puppy rather than a rescue dog or an adult
      dog; they claim a puppy is “ a clean slate”. However, this is not true –
      nature and nurture have a massive impact. A puppy inherits genes from his
      or her parents and these genes have an impact on the puppy’s genetic
      potential to behave in a certain way. In other words the puppy may be
      predisposed to certain behaviours for example, if one or both parents were
      fearful, their offspring may be predisposed genetically towards being
      fearful.
    </Text>
    <Text style={styles.text}>
      Other examples include (but are not limited to):
    </Text>
    <ListItem>
      Using aggression as a strategy to deal with difficult situations
    </ListItem>
    <ListItem>Sociability towards people and dogs</ListItem>
    <ListItem>Fearful responses</ListItem>
    <ListItem>
      Strong instinctive behaviour to hunt, chase, grab and hold.
    </ListItem>
    <ListItem>
      Trainability including the ability to learn self-control
    </ListItem>
    <Text style={styles.text}>
      From birth, the pups&apos; first 8 weeks learning with the breeder/foster
      family will have the biggest impact on the development of all these
      genetic traits. 0 – 63 days in a puppy’s life is a crucial time.
    </Text>
    <Text style={styles.text}>
      Whilst genetics set a blueprint and a puppy template is not a clean slate,
      the blueprint is dependent upon the choices made for puppies during this
      period. This is why finding a suitable registered breeder that breeds for
      temperament and does genetic testing is so important. Any stimuli missed
      out during this time is critical. With puppies every single day counts.
    </Text>
    <Text style={styles.text}>
      During this stage, the window of opportunity (neuroplasticity, axonal
      formation, synaptic plasticity) is starting to close, so it is crucial
      that we set the puppy up for success. This can be done by:
    </Text>
    <ListItem>Reducing stress</ListItem>
    <ListItem>Increasing free choice</ListItem>
    <ListItem>Increasing that safe, good decisions are made</ListItem>
    <ListItem>Reducing anxiety</ListItem>
    <ListItem>
      Arranging the environment so he or she is set up to make good choices
    </ListItem>
    <Text style={styles.text}>
      When any animal is born, the brain alters in accordance with sensory
      experiences gained by the animal (e.g. visual, auditory, touch, licking,
      grooming smell, affection etc.) Prior to the eyes even opening, puppies
      use their sense of touch and smell to discover where their mother is for
      feeding, warmth etc. This critical bonding-nurturing stage is an essential
      foundation for the emotional set-point of the animal.
    </Text>
    <Text style={styles.text}>
      Sufficient licking and nursing care in pups cause gene expression that
      reflects adequate concentrations of hormones and neurochemicals which
      enables more resilience to stressors later in life (licking and nurturing
      turns on the hormones in the glucocorticoid system). Genes and the
      environment converge powerfully during early sensitive windows of brain
      development to form the neural circuits underlying behaviour.
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(41).jpg?alt=media"
    />
    <Text style={styles.text}>
      During these early periods, the nervous system is developing and must
      obtain certain critical experiences, fulfilling their sensory, physical,
      or emotional obligations. This enables the dog to mature properly. The
      internal environment is such that the puppy is optimally primed for
      elevated rates of learning, as well as the ideal consequences available
      for neuronal connectivity.
    </Text>
    <Text style={styles.text}>
      Post critical period, connections diminish in number and are less
      susceptible to change, however, the ones that remain are stronger, more
      reliable, and more precise.
    </Text>
    <Text style={styles.text}>
      During the critical stages, the functional and structural connections of
      neurons in the cortex of the brain are particularly prone to alterations.
      Studies of rats raised outside, in contrast to those raised in a cage,
      have demonstrated that they have significantly larger brains and higher
      levels of cognitive function.
    </Text>
    <Text style={styles.text}>
      If an animal is deprived of sensory experiences, the brain will rewire,
      allowing the deprived cortex to process inputs from less deprived senses
      in a process of cross modal plasticity. This helps the animal to increase
      performance in the remaining senses when one is deprived.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Exposure to negative or traumatic experiences early in life will affect
        the way that dogs (and all animals) anticipate and process sensory
        input.
      </Text>
      Traumatic experiences induce a multifaceted series of neurological and
      physiological alterations in what the dog will identify as a threat. This
      will be demonstrated in how they think, feel, behave and regulate their
      biological systems.
    </Text>
    <Text style={styles.text}>
      In other words, the brain will compensate in some way for the lack of
      appropriate wiring to adapt with the skills that it does have.
    </Text>
    <Text style={styles.text}>
      Sensory environmental experiences involving touch, handling, sound,
      movement, light and darkness, texture, plus motor activity and play
      facilitate the brain growth connections which in turn assist the puppy in
      environmental interaction with his environment. The brain literally grows
      each time the puppy adapts.
    </Text>
    <Text style={styles.text}>
      Studies illustrate that the effects of olfactory (smell) and auditory
      (hearing) development share comparable organisational constructs as the
      somatic sensory systems, and that deprivation of olfaction reduces the
      organisation of the brain’s networks (astroglia networks).
    </Text>
    <Text style={styles.text}>
      Lack of early experiences result in puppies who have a less developed
      axonal, dendritic and synaptic connection pattern within their neural
      circuitry. The pup may become easily excited and aroused, resulting in a
      less resilient dog, unable to manage new challenges in life.
    </Text>
    <Text style={styles.text}>
      Resilience in Assistance dogs is essential– they will experience numerous
      different environments throughout their training and working life.
    </Text>
    <Text style={styles.text}>
      Neuroplasticity is crucial to assist puppies in dealing with novel
      situations.
    </Text>
    <Text style={styles.text}>
      If a pup lacks the correct foundation, feedback and experience during the
      critical stages, this will highly likely present as issues with
      self-regulation (impulse control), aggression, challenges with attention,
      physical problems and social problems.
    </Text>
    <Text style={styles.text}>
      The root for the appropriate experience derives from the relationship that
      the puppy has with his attachment figures. Extended alarm reactions, in
      any animal, will adjust the limbic, mid brain and brain stem functions
      through modifications.
    </Text>
    <Text style={styles.textBold}>
      Evidence suggests that if animals are raised in supportive, secure
      environments, they are less affected by traumas that may transpire in the
      future. They are more resilient.
    </Text>
    <Text style={styles.text}>
      If the trauma affects the security/safety of attachment, then the ability
      to amalgamate the sensory, emotional environment is disrupted. This is one
      of the reasons why it is essential to facilitate safe attachment without
      the risk of OVER attachment. The latter being the foundation for a
      separation distress reaction.
    </Text>
    <Text style={styles.text}>
      Secure emotional attachments provide the bases for positive learning
      experiences and decisions. Being able to navigate and negotiate
      environments which may increase arousal, but not to the point where the
      animal’s response is excessive, is the definition of a stable organism.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Ensuring that the dog has significant down time daily is one of the
        fundamental ways of assisting in preventing anxiety, this should be in
        his or her safe area, where it is quiet and he or she can relax and be
        alone.
      </Text>
      This is central to the emotional set point of any animal.
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(42).jpg?alt=media"
    />
    <Text style={styles.text}>
      Often owners are advised to let their puppies “cry it out” and only go to
      them when they are quiet – we now understand this is very damaging to
      puppies and in addition extremely stressful. Leaving an animal to cry will
      make them less healthy, more challenging to manage, anxious and fearful.
      These traits also get passed down genetically to the next generation,
      therefore have long lasting consequences as well as have a huge effect on
      breeding programmes.
    </Text>
    <Text style={styles.text}>
      Studies demonstrate that providing animals with all their needs leads to
      greater independence, greater exploration and better confidence when left
      (Hewlett & Lamb 2005).
    </Text>
    <Text style={styles.text}>
      During stress, hormones circulate that hinder learning. These hormones
      inhibit the factors that relate to the production of protein molecules
      which are shaped when learning occurs.
    </Text>
    <Text style={styles.text}>
      Neuronal interconnections will be damaged, and cortisol released. When
      cortisol is released in excess. it kills neurons; this has enormous
      implications for the developing brain.
    </Text>
    <Text style={styles.text}>
      The functioning of the nervous system becomes impaired when stress and
      reactivity become a pattern, as such meeting the pups needs PRIOR to
      distress will, in turn, help self-regulation.
    </Text>
    <Text style={styles.text}>
      The puppies become resilient by owners/care-givers encouraging and
      supporting independence as well as teaching life and problem-solving
      skills; this eases frustration barriers and is essential for granting the
      puppy control over their outcomes. This has been shown to decrease stress
      and aggression.
    </Text>
    <Text style={styles.text}>
      Throughout development we can draw the probable conclusion that an adult
      dog&apos;s behaviour is strongly influenced during their development as a
      puppy. This includes maternal care, attachment style and the diversity of
      both social and non-social stimuli exposure throughout the stages of
      development. It is important to always remember and note that during these
      periods, inadequate stimulation may increase the risk of behavioural
      disorders emerging as an adult and that developmental interactions are
      also predisposed to genetic variation (Scott & Fuller, 1965).
    </Text>

    <Text style={styles.text}>
      Scott & Fuller executed many experiments to ascertain if there is a
      sensitive or critical period for socialisation in dogs. This work provided
      the basis for many other experiments along with Freedman, King & Elliot
      who reported on the wild dog experiment. These studies looked at the
      development of behaviour, demonstrating that a puppy enters a period of
      transformation and sensitivity regarding social relationships at
      approximately 3 weeks of age.
    </Text>
    <Text style={styles.text}>
      One of the experiments Scott & Fuller carried out with a young female
      named &apos;Christy&apos;. Christy was raised from birth, isolated from
      other dogs, spending the summer with students at the University. As
      predicted, Christy became extremely very friendly with humans. She was
      then introduced to other dogs at the age of 9 weeks. Christy initially
      acted fearfully, but this quickly disappeared (in spite of some growling
      from some adult dogs). She was then placed with her littermates who played
      with her quite aggressively; her response was similar. Nevertheless, after
      4 days the only difference between her and her littermates was that she
      was much more keen on humans than they were.
    </Text>
    <Text style={styles.text}>
      Scott and Fuller recognised six periods of development in dogs:
    </Text>
    <ListItem>Prenatal</ListItem>
    <ListItem>Neonatal</ListItem>
    <ListItem>Transitional</ListItem>
    <ListItem>Socialisation</ListItem>
    <ListItem>Juvenile</ListItem>
    <ListItem>Adult</ListItem>
    <Text style={styles.text}>
      The notion of the first critical period, which lasts from birth to twelve
      weeks of age was discovered.
    </Text>
    <ListItem>Neonatal period 0 to 2 weeks</ListItem>
    <ListItem>Transitional Period 2 to 4 weeks</ListItem>
    <ListItem>Socialisation Period (to dogs and humans) 4 to 6 weeks</ListItem>
    <Text style={styles.text}>
      It is important to note that developmental stages of puppies overlap. They
      are not fixed and vary from dog to dog. Therefore, it is vital to look at
      each dog as an individual and treat the stages as a guide and not a fixed
      or rigid “map”. It can be very fluid and each puppy will not conform to
      each stage in a rigid manner.
    </Text>
    <Text style={styles.text}>
      The process through which a puppy (or any animal) learns how to recognize,
      interact and/or ignore other species is “socialisation”. An example of
      this would be a puppy or dog that is socialised very well with humans will
      be very friendly and receptive to human attention and interaction.
      “Habituation” can be defined as the process by which a puppy (or any
      animal) becomes familiar with and learns to ignore non-threatening
      environmental stimuli. An example of this may be a dog walking on the road
      and past a big noisy lorry.
    </Text>
    <Text style={styles.text}>
      The effect of early socialisation on the future behaviour of a dog is
      highly relevant to canine behaviour issues or undesirable behaviours. It
      has been established that if a dog is isolated from 3 days of age to 20
      weeks of age he will most likely be distressed (Agrawal et al 1967). There
      are severe detrimental effects from isolation and/or insufficient
      socialisation on later behaviour, including but not limited to, increased
      or abnormal fear response, social issues, amplified aggression and reduced
      learning ability. Puppies must be socialised. Additionally, they must be
      carefully and gradually exposed to environmental stimuli as part of the
      socialisation process.
    </Text>
    <Text style={styles.text}>
      “Behavioural and biological development take place within a context of
      inherited constraints, sufficiently variable to allow for change according
      to the necessity dictated by an animal’s unique experience and interaction
      with the environment. This adjustment to the demands of the physical and
      social environment depends on learning, but learning is possible only to
      the extent that an animal is genetically prepared to learn”. (Lindsay,
      2000)
    </Text>
    <Text style={styles.subtitleBold} id="development-periods">
      Prenatal Period
    </Text>
    <Text style={styles.text}>
      Temperament is an influence during gestation (that is pre-birth) and is
      affected by the mother’s (dam’s) temperament and emotional state during
      her pregnancy.
    </Text>
    <Text style={styles.subtitleBold}>Neonatal (1 – 14 days)</Text>
    <Text style={styles.text}>
      In the first two weeks of the puppy’s life, senses are not developed. The
      pup is blind and deaf with very poor motor skills and cognition due to an
      underdeveloped brain (Fox 1971a). They are in an isolated state and
      totally dependent on their mother for all their basic needs – sustenance,
      elimination, warmth. The mother stimulates elimination via anogenital
      licking (Rheingold 1963; Wilson 2016).
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(43).jpg?alt=media"
    />
    <Text style={styles.subtitleBold}>Transitional (12 - 21 days)</Text>
    <Text style={styles.text}>
      Eyes open and pups begin to show ‘startle’ responses to external stimuli
      such as noises. Motor skills (movement) begins to develop at a rapid pace.
      Teeth begin to emerge. Hearing is starting to develop. Commercial sounds
      can be played to the puppies. It is crucial to offer a variety of sounds -
      it should be just loud enough for it to register (not too loud so that it
      becomes harmful to them). The purpose of this is for them to get
      habituated to the sounds –Verbally communicating to puppies is also
      beneficial to them!
    </Text>
    <Text style={styles.text}>
      Placing age appropriate items in the puppy pen can help each area.
    </Text>
    <ListItem>
      Rolled up rugs taped with masking tape or similar will provide obstacles
      (age appropriate)
    </ListItem>
    <ListItem>Soft toys</ListItem>
    <ListItem>Raised surfaces (age appropriate)</ListItem>
    <ListItem>Items to step over, go under and over</ListItem>
    <ListItem>Different textures</ListItem>
    <ListItem>
      Hanging items that puppies can choose to interact with – some that make
      noises
    </ListItem>
    <ListItem>Different smells</ListItem>
    <ListItem>Astro turf</ListItem>
    <ListItem>Pools</ListItem>
    <ListItem>Soft beds with raised sides</ListItem>
    <ListItem>Plastic pipes</ListItem>
    <ListItem>Rubbish bin with bottom cut out</ListItem>
    <ListItem>Plastic sleds</ListItem>
    <ListItem>Garden gnomes and other novel items</ListItem>
    <ListItem>Mirrors</ListItem>
    <Text style={styles.text}>
      Self-discovery should be promoted and if an item is placed in the puppy
      pen the pups will engage with it. Items must be age appropriate and
      certain items should only be used whilst supervised. Always check for
      safety and avoid rough and sharp edges, hard landings and ensure any
      raised surfaces are age appropriate.
    </Text>
    <Text style={styles.text}>
      Flooring should provide good footing which will help build their hips.
      Puppies will be walking by 3 weeks old and need plenty of space. By day 18
      they should be able to negotiate low surfaces
    </Text>
    <Text style={styles.text}>
      Simple modifications to their environment “grow” puppies.
    </Text>
    <Text style={styles.text}>
      Avoid using food all the time – should an item be made available to the
      puppies they will choose to explore it and will not require the lure of
      food.
    </Text>
    <Text style={styles.text}>
      Use a diverse range of items, move them around or add novel items. Safe
      items should be made accessible 24/7, as soon as pups awaken, they are
      ready to learn! Novelty is key to development.
    </Text>

    <Text style={styles.subtitleBold}>
      Awareness (or Identification) (21 – 28 days
    </Text>
    <Text style={styles.text}>
      Healthy pups are using all their senses at this stage. They begin to learn
      appropriate behaviour around conspecifics (other dogs). They are now
      beginning to play-fight. They also start to show appeasement behaviours
      (such as rolling onto their back during interactions with mother and
      siblings). At around 3-4 weeks puppies will learn bite inhibition from
      their littermates and dam. Pups removed from the litter prior to this will
      not have the same learning experience and it is very difficult to
      substitute this learning.
    </Text>
    <Text style={styles.text}>
      Puppies must be allowed to explore with their mouths (provided it is safe
      to do so) and pups must not be prevented from biting completely.
    </Text>
    <Text style={styles.text}>
      *Please see our “Mouthing and Play-Biting” help-sheet in the resources
      section.
    </Text>
    <Text style={styles.subtitleBold}>
      Second Awareness (Identification) (28 – 35 days)
    </Text>
    <Text style={styles.text}>
      Play develops and becomes more sophisticated. Weaning is impending and
      pups are able to ingest real food rather than milk. This is the beginning
      of the socialisation period and pups require mental and physical
      stimulation for healthy development both emotionally and physically.
    </Text>
    <Text style={styles.text}>
      Pups need opportunities to discover they have control over their stimuli.
      This can be done by hanging noisy toys so that they can choose to interact
      and cause noise or choose to not to interact. Noise should not be induced
      by the human caregiver, it is important they explore and make their own
      choices here.
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(44).jpg?alt=media"
    />
    <Text style={styles.text}>
      By 4 weeks old group activities should be provided for instance one or two
      sessions such as group walks. They can also be crate trained for car
      travel. It is important to do this at a time when they are relaxed and
      sleepy, then place them in the crate. As soon as they wake, they should be
      let out to circumvent any panic or anxiety.
    </Text>
    <Text style={styles.text}>
      By day 27 life skills can start to be taught, for instance, all four paws
      on the floor for feeding. Wait until calm, with four paws on the floor
      before placing the food down. Pups at this age swiftly learn what works
      and begin to self- regulate.
    </Text>

    <Text style={styles.subtitleBold}>Socialisation (5 – 16 weeks)</Text>
    <Text style={styles.text}>
      This is a critical learning period for a puppy. The brain is receptive and
      curious at this time and it is essential to manage exposure to all the
      things that the pup will be expected to encounter in later life. Although
      animals continually learn about their environment during their life, they
      are significantly more sensitive to environmental stimuli during this
      stage. During this period, a pup learns through experience; they learn to
      associate social and non-social stimuli with positive or negative
      emotions. These experiences enable the pup to shape an adaptive capacity
      in order to cope with and adapt to new situations. In order to avoid fear
      responses in the future, early exposure to social and non-social stimuli
      is advised. For example, objects, people, sounds. Textures, situations and
      environments. In short, anything that will be a part of their life
      (Battaglia, 2009; Howell et al., 2015).
    </Text>
    <Text style={styles.text}>
      Appleby et al. (2002) conducted a study which revealed that dogs raised in
      stimulus rich domestic environments during their sensitive period for
      socialisation (ie., at the stimulus-rich breeder’s home) were less likely
      to develop fear and aggression towards strangers/unfamiliar people in
      comparison to dogs raised in non-domestic environments). Appropriate
      exposure to relevant stimuli during the early socialisation period appears
      to be associated with lower fearfulness and aggression in dogs (Tiira &
      Lohi, 2015).
    </Text>
    <Text style={styles.text}>
      Puppies should be ready for life by 9 weeks old.
    </Text>
    <Text style={styles.text}>
      Prior to 9 weeks old the pup should be provided with individual training
      away from littermates and the dam. Training can include basic life skills,
      for example follow, recall, stand, wait, impulse control. The collar
      harness, lead and lead walking, car rides and body handling should have
      already been introduced while the puppies are still with the dam. Please
      see out choosing a breeder section for questions to ask your breeder.
    </Text>
    <Text style={styles.text}>
      Gopnick et al. (1999) looked at child development and how synapse
      formation will be pruned if there is no neuron stimulation. It is the same
      with puppies.
    </Text>
    <Text style={styles.text}>
      The critical period of development stages must occur; it is essential for
      appropriate development. Absence at this stage will result in abnormal
      behaviour – permanent abnormal behaviour.
    </Text>
    <Text style={styles.text}>
      The critical period and the sensitive period are frequently muddled and
      used interchangeably.
    </Text>
    <Text style={styles.text}>
      During the sensitive period, puppies are more receptive to forming
      decisions as to whether things are “good/positive” or “bad/negative”.
    </Text>
    <Text style={styles.text}>
      The Socialisation Stage can be divided into further phases:
    </Text>
    <ListItem>
      <Text style={styles.textBold}>5 – 7 weeks</Text> the puppy’s senses are
      becoming fully developed and there is an interest in exploring the
      environment. This is the lowest fear period and highest acceptance of
      novel things, as such, this is the greatest time to introduce as many
      novel experiences as possible. Nevertheless, there is some evidence that
      certain breeds may go through the ‘fear of novelty periods’ during this
      stage.
    </ListItem>
    <ListItem>
      Each new experience needs to be managed and must be positive so the pup’s
      brain develops positive associations.
    </ListItem>
    <ListItem>
      By 5 weeks old activities in pairs should be provided for around 15-30
      minutes. By 6 weeks old individual time with a human caregiver and
      introduced to novel situations every day for approximately 15 – 20
      minutes.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>7 – 12 weeks.</Text> This is the optimum
      time for a puppy to enter his new home. This is the best time for a puppy
      to form and facilitate close attachments to people and other animals. The
      brain has reached a stage where it is able to adjust to changes.
    </ListItem>
    <ListItem>
      Pups of this age will try to make social bonds with other animals and
      people. will They start to learn behavioural responses from their new
      environment. They continue to explore the world through their mouths and
      are probably to chewing and nipping.
    </ListItem>
    <Text>&nbsp;</Text>
    <ListItem>
      <Text style={styles.textBold}>8 – 12 weeks.</Text> This has been deemed as
      the ‘fear of novelty period’, although (as previously stated) there is
      evidence that certain breeds, for example, German Shepherds, can go
      through a fear period earlier ( for example 5 weeks). The brain is
      beginning to form associations with different stimuli and environments,
      and this is a sensitive period when scary, negative experiences may highly
      likely become imprinted on the pup’s brain. All novel experiences
      throughout this period are required to be calm and positive. High or
      increased levels of excitement can result in an over-excitable puppy.
      There is an increase in attention span and motor skills development; new
      behaviours can be taught.{' '}
    </ListItem>
    <ListItem>
      By day 53 it is imperative to provide the dam with a means to escape from
      the puppies. A raised platform (for example a sofa or bed) will assist in
      facilitating this.
    </ListItem>
    <ListItem>
      If time is taken to input appropriate stimulation and activities during 0
      – 65 days it is much more ethical and humane for the puppies, in addition
      it facilitates a smoother transition to their new homes, provides an
      improved owner experience and a more suitable dog for assistance work.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>13 – 16 weeks.</Text> Adolescence commences.
      Adult teeth are coming through, and sexual hormones are beginning to
      develop. Contingent upon breed and temperament, confidence and boldness
      may emerge, and the puppy will probably test boundaries. Many puppies
      become assertive in their behaviour. This is often a period when seek
      attention by chewing, nipping, whining and barking for attention. Some
      puppies will continue to experience fear of novelty periods during these
      weeks. There is some evidence that dogs will experience fear of novelty
      periods throughout the first 2 years of their lives as their brains reach
      full maturity. These periods correspond to growth spurts and may occur
      more than once.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>4 – 6 months.</Text> This period is
      characterised by a rise in independence. Puppies of this age are very
      likely to venture further away from their owners on walks and are more
      keen investigate novel things. The brain is extremely predisposed to
      imprinting during this stage and long-term negative associations can be
      shaped by unpleasant or frightening experiences.
    </ListItem>
    <ListItem>
      <Text style={styles.textBold}>6 – 12 months.</Text> The majority of dogs
      will reach sexual maturity during at this point. There is an upsurge in
      independence and young dogs will need to be cautiously managed to
      safeguard their experiences; it is vital they do not have experiences
      which may have a harmful effect on them during this stage. This is the
      case with meeting new dogs and people, all new experiences need to be
      carefully managed (such as bonfire night). The brain continues to be
      receptive to imprinting of negative experiences.
    </ListItem>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '350px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(45).jpg?alt=media"
    />
    <ListItem>
      <Text style={styles.textBold}>18 months – 3 years.</Text> The puppy is now
      transitioning into adulthood depending upon their breed. Larger dogs
      mature at a slower pace than small breeds. Physical and cognitive growth
      continues. Due to the brain still developing, managing socialisation
      carefully is imperative in ensuring that the dog is able to function in a
      healthy manner, both physically and mentally, in all environments and
      situations.
    </ListItem>

    <Text style={styles.subtitleBold} id="puppy-party">
      Puppy Socials/Parties
    </Text>
    <Text style={styles.text}>
      Puppy Parties and Puppy Socialisation parties may be held as a one-off or
      a series of events. They are usually held by dog care providers ranging
      from veterinary clinics to Dog Day Care Centres. They are increasingly
      popular and can be seen by both owners/guardians and providers as valid
      socialising experiences, especially if held at veterinary clinics. The
      opportunity to bring a puppy into a clinical environment for safe, fun,
      positive experiences can be advantageous for a puppy’s subsequent
      veterinary visits.
    </Text>
    <Text style={styles.text}>
      A senior lecturer at the University of Bristol Dr Rachel Casey, addressed
      a BSAVA Congress report in 2010 outlining advice to veterinary
      professionals about how to run a successful Puppy Party to ensure each
      puppy’s experience is as positive as possible. She stated that the purpose
      of puppy parties should be: “to get across key, simple messages to
      clients, to foster a long-term positive relationship between owner and
      dog. This has the potential to have a profound, positive impact on the
      health, welfare and behaviour of that dog throughout life.”
    </Text>
    <Text style={styles.text}>
      https://www.visionline.co.uk/en/news-story.php?id=14397
    </Text>
    <Text style={styles.text}>
      While positive experiences are valuable, unfortunately, very often puppy
      parties are held by unqualified and inexperienced staff who have little
      knowledge and understanding of puppies&apos; needs in a group setting or
      situation, or how to manage groups of dogs and their owners/guardians.
      Inadequately managed puppy parties can lead to negative experiences for
      puppies during a fundamental time of their emotional development.
    </Text>
    <Text style={styles.text}>
      Casey advised, “A real danger of poorly run classes is the failure to
      recognise fear responses, as puppies may be overexposed to new experiences
      in a way that creates stress or anxiety.”
    </Text>
    <Text style={styles.text}>
      Mishandling of puppy parties or even dog training classes can result in
      long term emotional damage and may unintentionally set up a (negative)
      conditioned response to any of the stimuli present at the venue, if the
      puppy experiences enough negative input during his or her time there. This
      may include (but is not limited to):
    </Text>
    <ListItem>Dogs</ListItem>
    <ListItem>People</ListItem>
    <ListItem>Veterinary clinics</ListItem>
    <ListItem>Car parks, sights</ListItem>
    <ListItem>Sounds</ListItem>
    <ListItem>
      Smells specific to the event, which may then become generalised
    </ListItem>
    <Text style={styles.text}>
      If a puppy attends such an event (or, worse, several events) when he or
      she is going through a fear of novelty period, this could have a long term
      impact on his or her emotional and cognitive development.
    </Text>
    <Text style={styles.text}>
      It is crucial to work with a Veterinary Clinic that understands the
      concept of Fear Free Handling and is willing to support your training
      efforts.
    </Text>
    <Text style={styles.italics}>
      Please refer to the{' '}
      <Link href="https://pads.foundation/developmental-stages/">
        Puppy Development chart
      </Link>{' '}
      on the website{' '}
    </Text>
    <Text style={styles.subtitleBold} id="park-practice">
      Good Park Practice
    </Text>
    <Text style={styles.text}>
      Once your puppy has finished their initial course of vaccinations (at
      approximately 12 weeks of age) you will then be able to go on short walks
      and visit the park. Please consult your vet for time frames as they will
      be able to advise you when it is ok to properly walk your dog. Prior to
      this you can visit different environments (including parks and cafes) but
      it is vital you carry your puppy and do not to put your puppy on the floor
      /ground or meet other dogs as many serious diseases including (but not
      limited to) parvovirus and leptospirosis are highly contagious and can be
      fatal.{' '}
    </Text>
    <Text style={styles.text}>
      Ensure you are always well prepared for your walks, always have poop bags
      to pick up when your dog eliminates, bring water, plenty of treats for
      rewards and a long line (not a flexi) until your dog is very well recall
      trained. Your dog should not be allowed off lead until his or her recall
      is excellent. Training should always be age and stage appropriate and your
      pup must be set up to succeed, please refer to our Training Tips help
      sheet which can be found in the resources folder on the platform. Training
      must be fun and enjoyable – do not pressure your puppy please!
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '400px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(46).jpg?alt=media"
    />
    <Text style={styles.text}>
      Kindly remember your pup’s joints and bones are still developing. Some
      exercise will facilitate and encourage this, and some exercise can be
      damaging. Certainly, jumping on and off things and running around on hard
      surfaces will be damaging, as will pavement pounding on long arduous
      walks. A good rule of thumb in terms of walking and exercise time is 5
      minutes per month of life up to twice per day. So, a 4-month-old puppy
      will have 20 minutes twice per day. Once they are fully grown, they can
      have much longer. There is SOME room around this – some pups may prefer
      shorter, more frequent walks, so this can be split into four 10 minute
      walks, or similar.
    </Text>
    <Text style={styles.text}>
      It is imperative you do not force your pup in any way during walks and/or
      expect him or her to match your pace and stride. Attempting to force your
      pup to participate in a long lead walk is most likely to cause your pup to
      become mentally exhausted, over tired and overwhelmed.{' '}
    </Text>
    <Text style={styles.text}>
      They may refuse to move, lead grab and start jumping up and biting your
      legs, hands, the lead and or people who walk past, or any “melt down”
      illustrative behaviours. This information is also important to keep in
      mind for rescue/rehomed dogs who may be experiencing new things.
    </Text>
    <Text style={styles.text}>
      Ball throwing must be controlled – it is recommended to do this on flat,
      soft surfaces like grass or padded flooring. It is best to encourage them
      to get the ball once it has landed as opposed to going after it whilst it
      is in the air and they are jumping and twisting and contorting their
      bodies in the air and upon landing. In addition, we do not want to
      encourage prey drive which can lead to your dog becoming a chaser and
      hunter which can be very hard to rectify. Ball games must be limited and
      not overused as can cause serious long term injuries to developing joints.
    </Text>
    <Text style={styles.text}>
      From a young age, pups should not be allowed to jump up on people. This
      can be tricky, particularly if you have a very friendly puppy who loves
      being around children/people. Often when dogs jump up dog lovers or people
      who think he or she is cute and will then pet them.
      <Text style={styles.textBold}> Please do not allow this.</Text> Remember,
      not all people, including dog lovers, want or like dogs jumping up on
      them. Assistance dogs should not be randomly jumping up on people to greet
      or for any other reason. A larger more excitable dog can easily do damage
      to any vulnerable human (this includes but is not limited to any children,
      vulnerable or disabled adults and the elderly) or smaller dogs. It can be
      helpful in the aforementioned circumstances to use a harness or jacket
      that says , “In Training” or something similar.{' '}
    </Text>
    <Text style={styles.italics}>
      *PADs Foundation will provide an In Training leash sash and cover letter
      once your dog worked through all Puppy Development and Foundation
      Behaviours training charts and is a minimum of 1 years old to facilitate
      shop access for further training.
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(47).jpg?alt=media"
    />
    <Text style={styles.text}>
      Furthermore, please do not allow your puppy to run up to dogs on lead. In
      fact, if you see a dog on lead good etiquette would be to put your own dog
      on lead or shorten the long line so they cannot rush up to the on-lead
      dog. An on-lead dog may be recovering from an operation, unfriendly,
      elderly, working or for many other reasons we do not know. Older dogs must
      also be given lots of space; they may not have the patience for puppies or
      suffering from illness. Again, even though you may argue that the elderly
      dog’s owner should take the responsibility for this, but some do not,
      always take the responsibility when your dog is involved.
    </Text>
    <Text style={styles.text}>
      Be aware of any labels or colours on dogs harnesses and lead. For example
      some leads/harnesses/jackets have “Nervous” or “Unfriendly”, or even
      “Friendly”. If you see a dog with a yellow ribbon, bandana or similar this
      dog needs space. More information can be found here:
      <Link href="https://www.yellowdoguk.co.uk/">
        https://www.yellowdoguk.co.uk/
      </Link>
    </Text>
    <Image
      style={{
        ...styles.imageWithBorder,
        width: '425px',
        margin: '0 auto',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(48).jpg?alt=media"
    />
    <Text style={styles.text}>
      A good way to have well managed and well rounded walks is to practise
      Training walks. Training walks protocol is as follows. Please see the
      Training Walks help sheet in the resources section.
    </Text>
    <Text style={styles.text}>
      There may be instances where your pup will be unsure and hesitant, always
      support your puppy in these circumstances. If he or she backs away from
      something, go with them. In certain circumstances, you may feel the need
      to pick up your puppy, for example (including but not limited to) they are
      very tired/ refuse to walk, become very over excited, another dog has a
      play style that is making your dog uncomfortable. However, it is better if
      walks and stimulation is managed so that the aforementioned negative
      situations do not occur very often, if at all. If another dog approaches
      and is friendly, do not rush to pick up your dog, allow your dog to greet
      if they wish; if they want to greet then back away go with them. Please
      have a detailed discussion with your trainer if you are unsure of anything
      in these situations.
    </Text>
    <Text style={styles.text}>
      An important asset when training your dog and managing them and any
      stimulus around them is learning dog body language.
    </Text>
    <Text style={styles.subtitleBold} id="body-language">
      The Significance of Body Language
    </Text>
    <Text style={styles.text}>
      Dogs communicate through body language. In canines, this is a form of
      non-verbal communication whereby they express emotions and intentions
      through a series of bodily movements (posturing). Learning what these
      movements and posturing mean, enables us to understand them and become
      closer to them. In addition, it enables us to keep them safe and
      comfortable, and read other dogs that your dog may come into contact with.
      It is important that your dog has positive interactions with other dogs to
      prevent behaviour issues arising. Negative experiences can have an adverse
      effect (for example being scared of other dogs) which may then require
      behaviour modification. Sometimes asking an owner if their dog is friendly
      is not enough; not all owners take the time to learn how a dog
      communicates and often get it wrong. By learning and understanding how
      dogs communicate can assist you in identifying whether a dog is friendly,
      even from a distance. You can also do this by observing the body language
      of other dogs interacting and how they respond to each other. This will
      enable you to calmly keep your dog safe (at a good distance or by removing
      them from a potentially dangerous situation before it is too late. Please
      see our chapter “Dog Behaviour and Body Language” and check the
      bibliography for relevant reading.
    </Text>
    <Text style={styles.subtitleBold}>Working with Your Trainer</Text>
    <Text style={styles.text}>
      Always work with your trainer to ensure you are doing the right thing.
      Jumping up, chasing and many of the aforementioned behaviours in this
      chapter may need some extra help. It is important that things do not
      become a habit and/or out of hand. Prevention is always better than a
      cure!
    </Text>
    <Text style={styles.subtitleBold} id="attacked">
      What To Do If Your Dog is Attacked
    </Text>
    <Text style={styles.text}>
      Even with all our best intentions sometimes we find ourselves in difficult
      situations. Understanding dog body language and being able to assess other
      dogs will be the most important thing you can do to help keep your dog
      safe. It will also help you to understand the difference between play
      behaviour and aggressive behaviour. Following the above steps on Park
      Etiquette will also help prevent possible negative experiences for your
      pup.
    </Text>
    <Text style={styles.text}>
      We hope that you never have to experience something so traumatic as an
      attack. Should you ever end up in such a situation that your dog is being
      attacked please be aware that any intervention during an attack could
      result in serious injury to yourself! It is at your own discretion if you
      feel safe enough and confident enough to step in. Ideally shout to the
      owner of the aggressive dog to remove their dog.{' '}
    </Text>
    <Text style={styles.text}>
      The first thing you are likely to do is to check your dog for any injury,
      bites can be hidden by thick fur so a visit to the vet may be required.
      However, before the owner of the aggressive dog walks away try to get
      their contact details such as name, address and phone number. You may need
      to do this before checking your dog! Most responsible dog owners would be
      mortified if their dog behaved in such a manner and would be more than
      happy to give you their details and offer to pay for any vet bills. Sadly,
      this is not the case for many and more often than not an aggressive dog
      that is allowed off lead is likely to have an owner that will not be so
      forthcoming. If this is the case try to take a photograph of the dog and
      owner before they disappear so it can be sent to the dog warden. It is
      likely that this dog has a history of attacking and needs to be reported.
      It is also worth looking around to see if there were any witnesses to the
      attack and ask if they would be willing to be a witness for insurance
      purposes. Lastly please refer to our Dog Law chapter for further
      information.
    </Text>

    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

Development.propTypes = {
  styles: PropTypes.object,
}

export default Development

/* eslint-disable react/forbid-component-props */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Image, StyleSheet, View, Page } from '@react-pdf/renderer'

const ListItem = ({ children }) => {
  return (
    <View style={bulletPointStyles.row}>
      <View style={bulletPointStyles.bullet}>
        <Text style={bulletPointStyles.bulletText}>{'\u2022' + ' '}</Text>
      </View>
      <Text style={bulletPointStyles.text}>{children}</Text>
    </View>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
}

const bulletPointStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  bulletText: {
    margin: 5,
    marginTop: -3,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    marginRight: 20,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
})

const PublicAccessGuidelines = ({ styles }) => (
  <Page style={styles.body}>
    <Text id="intro" style={styles.title}>
      Introduction
    </Text>
    <Text style={styles.text}>
      Welcome to Psychiatric Assistance Dogs Foundation In Training Guide. Our
      aim is to provide you and your trainer with all the necessary information
      required to give your dog the best probability of becoming your future
      Assistance Dog.
    </Text>
    <Text style={styles.text}>
      Training your dog up to International Public Access Standards takes time,
      consistency and patience. It is a requirement that all In Training Members
      have a minimum of one training session per month with a professional
      trainer. This means that you will be continuing the work your trainer
      shows you in your session until your following session. You and your
      trainer will work through our guide to ensure that all aspects of the
      dog’s training is covered. Training can take up to 2 years depending on
      the dog’s age and current training level as well as the time given to
      training. The time allocated and consistency of your training will greatly
      affect the speed at which your dog progresses through all the necessary
      behaviours needed to become a well adjusted, calm and disciplined
      Assistance Dog.
    </Text>
    <Text style={styles.text}>
      In this guide we will cover basic training theory in order to help you
      best understand the principles of training. We will also provide
      recommended reading lists for those that want to have a greater
      understanding of putting training theory into practice. The more you know
      and understand the easier it will be for you and your dog to progress.
      Once you get to grips with basic training theory the learning process will
      become fun for both you and your dog.
    </Text>
    <Text style={styles.subtitleBold} id="process">
      PADs In Training Process
    </Text>
    <Text style={styles.text}>
      In order for your dog to be legally recognised as an Assistance Dog in the
      UK your dog needs to be able to perform all public access behaviours to
      International Public Access Standards and to be able to perform three task
      related behaviours that mitigate your disability.
    </Text>
    <Text style={styles.text}>
      Below is a list of steps that you will work through with your trainer.
      Although these are written out as separate sections of training there will
      be some overlap between each as you progressively work through them. For
      example, as you are coming towards the end of puppy development you may
      have already started on Kennel Club Good Citizens awards (or equivalent).
      As long as all aspects of each training module are completed and
      consistent towards the end of your dog’s training, elements from later
      steps can be started sooner.All behaviours and training will be directed
      under the guidance of your qualified trainer. We will not accept members
      self-qualifying their dogs. All training sheets will be sent to us by your
      trainer, we will provide your trainer with a behaviour check-list to help
      them gauge how your dog is progressing. We will follow your trainers
      advice on suitability and dog’s readiness for Testing.{' '}
    </Text>
    <Text style={styles.subtitleBold}>Vet check & Husbandry behaviour</Text>
    <Text style={styles.text}>
      Husbandry / Cooperative Care is an often overlooked part of dog training.
      An assistance dog will be required to have regular health checks and will
      need to be comfortable being handled by vet staff at the veterinary
      clinic. As part of your dog’s training you will work on your dog being
      comfortable with many aspects of care from being bathed to having nails
      clipped and teeth and ears checked at the clinic. You can start working
      through the Cooperative Care training sheets with your trainer from day
      one. These can be trained alongside the puppy development and incorporated
      into the desensitisation training.
    </Text>
    <Text style={styles.subtitleBold}>Step ONE </Text>
    <Text style={styles.subtitleBold}>Puppy Development</Text>
    <Text style={styles.text}>
      If you have a puppy you will work with your trainer through the stages of
      puppy development, ensuring that your dog is exposed to a variety of
      stimuli at the right time. The first year of a young dog’s life is
      extremely important in determining its likelihood of becoming a well
      adjusted calm, confident future Assistance Dog. Regardless of the age of
      your dog we recommend that you work through the habituation /
      desensitisation and puppy development section with your trainer to ensure
      that your dog has the appropriate skills before progressing to the next
      steps. If you have joined the programme with an older dog that has already
      passed the Bronze & Silver KC these can be signed off by your trainer with
      evidence (KC certifications).
    </Text>
    <Text style={styles.subtitleBold}>Step TWO </Text>
    <Text style={styles.subtitleBold}>Foundation Behaviours</Text>
    <Text style={styles.text}>
      Once your dog has successfully worked through its puppy development stages
      we recommend that Foundation behaviours such as Kennel Club Good Citizen
      Awards Bronze & Silver (or equivalent) are started.You do not need to work
      with a KC trainer, you will be able to work through our Bronze and Silver
      training sheets with your own qualified registered trainer. These
      foundation behaviours will help your dog with obedience and general good
      manners. These will be important for your dog to master prior to embarking
      on some of the more involved public access behaviours. You would not want
      to try to teach your dog how to navigate around a busy supermarket if it
      doesn’t already have a good level of obedience training in advance.
      Remember you do not have public access rights while the dog is In Training
      and if you are allowed entry to a shop to further your dogs training and
      it behaves badly then the shop owner will be unlikely to allow you entry
      in the future! When you take your dog into public spaces to train you not
      only represent PADs Foundation but you will also be representing ALL
      assistance dogs that are In Training! Having a poorly behaved In Training
      assistance dog will have a detrimental effect for all other assistance
      dogs in training too. This is why PADs Foundation will only provide an In
      Training Cover Letter to those that have successfully worked through both
      Puppy Development Stages and Foundation Behaviours. A PADs In Training
      Cover Letter will state that you and your dog are In Training Members and
      that your dog has passed Foundation obedience training.{' '}
    </Text>
    <Text style={styles.text}>
      *
      https://www.thekennelclub.org.uk/dog-training/good-citizen-dog-training-scheme/
    </Text>
    <Text style={styles.subtitleBold}>Step THREE </Text>
    <Text style={styles.subtitleBold}>Public Access Behaviours</Text>
    <Text style={styles.text}>
      At this point your dog should have worked through its puppy development
      stages as well as having completed their Bronze and Silver Good Citizens
      awards (minimum - or equivalent). As aforementioned, it is likely that
      there will be some overlap of each training step so it is possible that
      you would have already worked on some of the public access behaviours. For
      example, walking at heel, entering and exiting a vehicle and other public
      access behaviours that can be completed without the need to enter public
      buildings. Now that you and your dog are more confident you can work with
      your trainer on completing the public access behaviours. Your trainer can
      accompany you and your dog to shops and public buildings to work on some
      of the more complex behaviours. Please note, we recommend that you contact
      shops/supermarkets in advance to ensure that they will grant you access
      for training. Our In Training Cover letter will help with access while In
      Training. Please note that we will only provide an In Training Cover
      Letter to the dogs that have reached a minimum of 1 year of age AND have
      completed the Habituation / Desensitisation, Puppy Development, Bronze &
      Silver training with trainer sign off.
    </Text>
    <Text style={styles.subtitleBold}>Step FOUR </Text>
    <Text style={styles.subtitleBold}>Mitigating Behaviours</Text>
    <Text style={styles.text}>
      In order for your dog to be legally recognised as an Assistance Dog your
      dog will need to be able to perform a minimum of THREE task related
      behaviours that mitigate your disability. The behaviours that are trained
      will vary from person to person depending on their needs. A task related
      behaviour is a specifically trained behaviour that helps you (the handler)
      best mitigate your disability. For example, a dog may be trained to fetch
      your medication bag every morning and evening to help you to remember to
      take medication OR a dog may be trained to perform deep pressure therapy
      when the handler feels anxious. Each person will have different needs and
      requirements from their assistance dog. It is up to you to decide what
      behaviours best suit your needs. You can train multiple task related
      behaviours, but your dog will need to be able to perform a minimum of
      THREE. A dog walking at heel when you go out does NOT count as a
      mitigating behaviour. Although your dog will need to be able to walk at
      heel this is classed as part of the public access behaviours and is not a
      task related behaviour. If you are in a crowded environment your dog can
      be trained to body block (stand horizontally to you) and actively help
      create space around you. This would count as a task related behaviour.
    </Text>
    <Text style={{ ...styles.textBold, color: 'red' }}>IMPORTANT:</Text>
    <Text style={styles.text}>
      If you suffer with dissociative episodes one of the mitigating behaviours
      required will be around road safety. You will need to ensure that your dog
      is trained to actively prevent you from crossing a road if you fail to
      give your dog a ‘cross the road’ command. This is a highly complex
      behaviour and will need to be worked on with your trainer. Here at PADs we
      aim to provide zoom meetings for your trainer in order to create a
      specific training plan for any task related behaviours should your trainer
      want further guidance. The safety of you and your dog is paramount, this
      is why we recommend that anyone suffering from dissociative episodes
      ensures that they have someone to accompany them when training outdoors
      until this behaviour is completed. Please call to discuss.{' '}
    </Text>
    <Text style={styles.subtitleBold} id="members">
      Becoming Fully Trained Registered Members{' '}
    </Text>
    <Text style={styles.text}>
      Should you wish to become a Fully Trained Registered Member, PADs
      Foundation will ask to see evidence of your dog’s training. Your dog will
      have completed all of the above steps and your trainer will have signed
      your dog off as having successfully displayed all public access behaviours
      and task related behaviours. These will need to have been displayed on a
      repeated basis to criteria throughout the training process. and witnessed
      by your trainer. Trainers are usually able to start signing off behaviours
      towards the LAST 6 months of the Training process. Throughout the program
      your trainer will be able to assess your dog’s progression through a
      variety of Behaviour Checklists. Upon completion of all the training we
      will send your trainer an assessment pack. This will ensure your dog is
      tested and filmed by your trainer in a variety of locations over a period
      of time. The minimum age of the dog to take the Dog Team Qualification
      will be 18 months{' '}
    </Text>
    <Text style={styles.text}>
      Your dog will complete a veterinary check (similar to the initial check
      when registering) and behaviour check on an annual basis before your
      membership is renewed and ID card sent out. The behaviour checks will
      require that you meet up with your trainer on an annual basis.{' '}
    </Text>
    <Text style={styles.text}>
      PADs will issue a made to measure PADs harness for your dog as well as a
      Charity ID card. Currently PADs Foundation is unfunded, this means that
      you will need to cover the cost of the harness and ID card. We will do our
      best to keep costs to a minimum.{' '}
    </Text>
    <Text style={styles.text}>
      You do not need to show your ID card or dog harness to have public access
      rights but most people like to have these to hand should they wish to use
      them. Your ID card will be a photo ID of you and your dog, membership
      number, emergency contact details or any other medical information that
      may be needed by paramedics.{' '}
    </Text>
    <Text style={styles.text}>
      It is important that you maintain the dog’s behaviours even once the dog
      has become fully registered. There may be a lot of behaviours that you
      don’t use that often, it is important that you refresh the dogs training
      and ensure you make a point of checking that your dog can remember all it
      has learnt. This will make it easier to pass our annual checks.{' '}
    </Text>
    <Text style={styles.subtitleBold} id="how-to-guide">
      How to Use the Guide
    </Text>
    <Text style={styles.text}>
      In this guide you will find all the information you need to provide your
      dog with the best chance of becoming your future assistance dog. Please
      make sure you read through this guide thoroughly.{' '}
    </Text>
    <Text style={styles.text}>
      The information in this guide has been ordered into Chapters and is in a
      logical progression for you to work through with your trainer.{' '}
    </Text>
    <Text style={styles.underlineHeading}>
      As part of this guide you will find:
    </Text>
    <Text style={styles.subtitleBold}>Training Log</Text>
    <Text style={styles.text}>
      It is important to log all your sessions, this includes all training you
      do on a daily basis whether you are with your trainer or doing your
      training homework with your dog. Don’t worry, we know that you may not be
      able to do lots of training every day but you can log each session even if
      it only lasts 5 minutes as it all adds up! Consistency is key. Download
      our training log to keep track of your training on a daily basis. Don’t
      forget to transcribe your notes onto our online Training Log (this can be
      done weekly or monthly, as long as it’s logged).The online training log
      allows us to keep track of your ongoing training. You can log multiple
      sessions each day and also select which sessions were with your trainer.
      In the behaviour box you can type what you worked on and select the
      duration i.e. loose lead walking 15 minutes.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '500px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Ftraining-log.png?alt=media"
    />
    <Text style={styles.text}>
      Separate from your training log we recommend that you keep a training
      diary to take notes on how your dog is progressing in each behaviour, if
      you have any questions for your trainer, if you are having any issues and
      general notes to use as reminders.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Puppy Development behaviour progression sheets
      </Text>{' '}
      (for you and your trainer)
    </Text>
    <Text style={styles.text}>
      These will be used to ensure that you have worked on all areas of puppy
      development.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Foundation Behaviours progression sheets
      </Text>{' '}
      (for you and your trainer) Kennel Club Good Citizen Awards or equivalent
      of Bronze and Silver award behaviours.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Public Access behaviour progression sheets
      </Text>{' '}
      (for you and your trainer) These will be used to keep track of how your
      dog is performing on each of the public access behaviours.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>
        Vet check & Husbandry behaviour progression sheets
      </Text>{' '}
      (for you and your trainer) It is important that your dog is comfortable
      visiting the vets and having body checks by vet staff. This checklist will
      make sure you are working towards completing all necessary Vet check
      training. These behaviours will be worked through alongside all the other
      behaviours. Cooperative Care / Husbandry is a vital part of your dog’s
      welfare and it is imperative that your dog is calm and comfortable being
      handled and Vet checked at the Veterinary practice.
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Trainer Assessment</Text> (for trainer use
      only) These sheets will be used by your trainer to ensure your dog is
      meeting criteria consistently over a period of time and in a variety of
      situations. These will be used towards the end of your dog’s training.
      Please make sure you send these sheets to your trainer.
    </Text>
    <Text style={styles.italics}>
      *Please note we will be sending a separate Assessment PDF for your trainer
      to use towards the end of the training process and only when your dog is
      ready for Testing.{' '}
    </Text>
    <Text style={styles.textBold}>Recommended Reading List</Text>
    <Text style={styles.text}>
      The more you learn and understand about training theory and practise the
      easier it will be to understand and implement the tools given to you by
      your trainer. Please see our recommended reading list for books and
      scientific papers. We aim to keep up to date with modern humane methods of
      training and will continually update our recommended reading list in order
      to provide you with the most up to date information.
    </Text>
    <Text style={styles.textBold}>
      Training and Behaviour Help Sheets and Training Charts
    </Text>
    <Text style={styles.text}>
      Throughout this guide you will be directed to help sheets and training
      charts.{' '}
    </Text>
    <Text style={{ ...styles.italics, fontWeight: 'bold' }}>
      *All of the above information can be found in the Worksheets &
      Supplementary files section
    </Text>
    <Text style={styles.subtitleBold} id="other-dogs">
      Recognising Other Types of Assistance Dogs
    </Text>
    <Text style={styles.text}>
      <Text style={styles.textBold}>Guide Dogs</Text> – Probably the most
      recognised of all Assistance Dogs Charities. This charity was set up in
      1931 and trains dogs to help people who are partially sighted or blind.
      The dogs wear white harnesses with fluorescent strips, with their logo.
      Guide Dogs is the largest Assistance Dog Charity in the UK!
      www.guidedogs.org.uk
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(90).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Hearing Dogs for Deaf People</Text> – The
      charity was set up in 1982 and matches dogs with adults and children with
      hearing impairments. The dogs are trained to alert their handlers to
      noises that they would not be able to hear naturally such as the doorbell,
      telephone or any alarms that may go off. The dogs wear burgundy jackets
      for identification. https://www.hearingdogs.org.uk
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(91).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Dogs for Good</Text> – Previously known as
      Dogs for the Disabled was founded in 1988 until 2015 when it became Dogs
      for Good. The dogs are trained to help adults with physical disabilities
      or children with autism. These dogs wear green jackets for identification.
      https://www.dogsforgood.org
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(92).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Canine Partners</Text> – This charity was
      founded in 1990 and trains dogs to assist adults over 18 with physical
      disabilities. The dogs wear purple jackets for
      identification.https://caninepartners.org.uk
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(93).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Dog AID (Assistance in Disability)</Text> –
      This charity was set up in 1992 and helps physically disabled owners train
      their existing dogs to help with their day to day needs. The dogs wear red
      jackets.https://dogaid.org.uk
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(94).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Support Dogs</Text> – Founded in 1992, this
      charity helps physically disabled owners train their existing dogs to help
      with their day to day needs, assists children with autism remain calm in
      stressful situations and seizure alert dogs for people with epilepsy.
      These dogs wear blue jackets for identification.
      https://www.supportdogs.org.uk/autism-assistance
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(95).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Medical Detection Dogs</Text> – The charity
      was set up in 2008. They train dogs to detect the smell of diseases and
      changes in their handler’s odour prior to a medical event. These dogs wear
      red jackets.https://www.medicaldetectiondogs.org.uk
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(96).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Pathfinder</Text> – The charity was set up
      in 2010 and trains German Shepherds to provide guidance to partially
      sighted or the blind. Pathfinder dogs wear a purple harness with logo.
      https://www.pathfinderdogs.org
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '300px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(97).jpg?alt=media"
    />
    <Text style={styles.text}>
      <Text style={styles.textBold}>Dogs for the disabled (Ireland)</Text> –
      Established in 2007 Dogs for the Disabled have been breeding and training
      specially selected assistance dogs to carry out a range of practical tasks
      to assist profoundly disabled children and adults in order to achieve
      greater independence. Their harness is purple with fluorescent
      yellow.https://dogsfordisabled.ie
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '250px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(98).jpg?alt=media"
    />
    <Text style={styles.text}>
      PADs Foundation (Psychiatric Assistance Dogs) - PADs Foundation was
      initially registered in 2017 as a Charity to provide information for those
      needing to understand the training requirements for a legally recognised
      Assistance Dog and public access laws in the UK. In 2019 we decided to
      begin designing a registration process. Due to Data Protection Laws we
      recruited a designer and database developer to change our registration
      process to a data secure online registration platform and database. We aim
      to help people across the UK with our remote training guide that can be
      used with the handler’s own qualified trainer or one of our Volunteer
      Trainers. A fully trained PADs dog has an orange harness with PADs logo.
    </Text>
    <Image
      style={{
        margin: '0 auto',
        width: '250px',
      }}
      src="https://firebasestorage.googleapis.com/v0/b/pads-foundation.appspot.com/o/site-assets%2Fimage%20(99).jpg?alt=media"
    />
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
)

PublicAccessGuidelines.propTypes = {
  styles: PropTypes.object,
}

export default PublicAccessGuidelines
